import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';
import {useAuthStore} from "@/stores/authStore";

export const useEventsStore = defineStore('events', {
  state: () => ({
    item: null, // текущая вакансия
    items: [],
  }),
  actions: {
    getPublicEvents(year, month){
      const fetch = useFetch()
      const authStore = useAuthStore()
      return fetch.anonRequest('/events', {
        params: {
          year,
          month,
        }
      }).then((data) => {
        this.items = data
        return data
      })
    },
    getNears(n = 5){
      const fetch = useFetch()
      const authStore = useAuthStore()
      return fetch.anonRequest('/events/nears', {
        params: {
          n,
        }
      }).then((data) => {
        this.items = data
        return data
      })
    },

    getAccountEvents(status){
      const fetch = useFetch()
      if (status === 'PUBLISHED')
        return fetch.authRequest(`/account/events?status__in=PUBLISHED`).then((data) => {
          this.items = data.results
        })
      else if (status === 'NO_PUBLISHED')
        return fetch.authRequest(`/account/events?status__in=MODERATION,BANNED`).then((data) => {
          this.items = data.results
        })
    },

    getPublicEvent(id){
      const fetch = useFetch()
      return fetch.anonRequest(`/events/${id}`).then((data) => {
        this.item = data
        return data
      })
    },

    getAccountEvent(id){
      const fetch = useFetch()
      return fetch.authRequest(`/account/events/${id}`).then((data) => {
        this.item = data
        return data
      })
    },

    update(id, data){
      const fetch = useFetch()
      return fetch.authRequest(`/account/events/${id}`, {
        method: 'PATCH',
        body: data
      }).then((data) => {
        this.item = data
        return data
      })
    },

    create(data) {
      const fetch = useFetch()
      return fetch.authRequest(`/account/events`, {
        method: 'POST',
        body: data,
      }).then((data) => {
        this.item = data
        return data
      })
    }

  },
});