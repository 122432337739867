<template>
    <DefaultLayout>


        <div class="!bg-[#F8F8F8] pb-[1.81rem] flex flex-col items-center ">
            <UIContainer>
                <div v-if="status === 'MODERATION'"
                    class="mt-[10px] md:mt-8 inline-flex w-full gap-x-[30px] justify-between">
                    <ButtonNew class="!h-[34px] md:!h-[48px]" @click="setEventStatus(event.id, 'PUBLISHED')"
                        :class="[mobile.isMobile.value ? '!w-[149px]' : '']">
                        Принять
                    </ButtonNew>
                    <ButtonNew class="!h-[34px] md:!h-[48px]" type="stroke-red"
                        @click="popupsStore.openModerationBanPopup(eventId, 'event', $router)"
                        :class="[mobile.isMobile.value ? '!w-[44px]' : '']">
                        <!-- <UIIcon v-if="mobile.isMobile.value" name="x" size="32" color="red"></UIIcon> -->
                        <span>Заблокировать</span>
                    </ButtonNew>
                </div>
                <div v-else-if="status === 'BANNED'" class="mt-[10px] md:mt-8 inline-flex w-[45%]">
                    <ButtonNew class="!h-[34px] md:!h-[48px]" @click="setEventStatus(event.id, 'PUBLISHED')"
                        :class="[mobile.isMobile.value ? '!w-[149px]' : '']">
                        Разблокировать
                    </ButtonNew>
                </div>
                <div class="mt-[32px] ">
                    <Sheet v-if="!loading"
                        class="mb-[40px] bg-white shadow-[4px_4px_1.5rem_0_rgba(10,92,213,0.1)] rounded-[1.5rem] p-[24px] border-[transparent] border-[1px] !h-fit cursor-pointer">
                        <div class="text-[20px] leading-[25px] font-medium text-[#0A5CD5]">{{ event.name }}</div>
                        <div class="text-[16px] leading-[20px] font-medium">"{{ event.short_description }}"</div>




                        <div>
                            <img :src="`${fetch.baseURL}/files/${event.cover}/download`"
                                class="mt-[20px] w-full h-[346px] rounded-[12px] object-cover">
                        </div>

                        <div class="mt-[20px] whitespace-pre-line text-base leading-5 text-black font-normal" v-html="event.content">
                        </div>

                        <div v-if="status === 'BANNED'" class="flex flex-row flex-wrap gap-x-1 mt-5">
                            <div v-for="banReason in event.ban_reasons" :class="[
                                mobile.isMobile.value ?
                                    'text-[10px] leading-[12.2px] font-light p-1 px-2 bg-[#FF3B301A] rounded-md' : 'bg-red-100 p-2 px-3.5 text-[1rem] leading-[1.25rem] rounded-lg',
                            ]" class=" flex flex-row mr-2 mb-2 items-center text-black">
                                {{ humanizeBanReason(banReason) }}
                            </div>
                        </div>

                    </Sheet>
                </div>
            </UIContainer>


        </div>
    </DefaultLayout>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, computed } from 'vue';
import {
    humanizeBanReason
} from '@/composables/utils';
import { useFetch } from '@/composables/useFetch';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIContainer from "@/components/ui/UIContainer";
import UIPageHeader from "@/components/ui/UIPageHeader";
import CardsSlider from "@/components/ui/CardsSlider";
import VacancyCardNew from "@/components/cards/VacancyCardNew";
import { usePopupsStore } from "@/stores/popupsStore";
import LKLayout from '@/layouts/LKLayout.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import UIIcon from '@/components/ui/UIIcon.vue';
import { useMobile } from '@/composables/useMobile';
import Sheet from '@/components/cards/Sheet.vue';

const mobile = useMobile()

const authStore = useAuthStore();
const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const eventId = ref(route.params.id)

const event = ref({});
const loading = ref(true);
const error = ref(null);

const { anonRequest, authRequest, baseURL } = useFetch();

const popupsStore = usePopupsStore()

const status = computed(() => {
    return route.query.status
})

onMounted(() => {
    fetchEventData();
});

const fetchEventData = async () => {
    error.value = null; // Сбрасываем предыдущую ошибку

    try {

        let response
        response = await authRequest(`moderator/events/${eventId.value}/`, {
            method: 'GET',
        });

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            event.value = response; // Предполагаем, что API возвращает массив данных
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const setEventStatus = (id, status) => {
    authRequest(`moderator/events/${eventId.value}/`, {
        method: 'PATCH',
        body: { status: status }
    });
    router.push('/moderator/events')
}



const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

</script>

<style></style>