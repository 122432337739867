<template>
    <DefaultLayout>


        <div class="!bg-[#F8F8F8] pb-[1.81rem] flex flex-col items-center ">
            <UIContainer>
                <div v-if="status === 'MODERATION'" class="mt-[10px] md:mt-8 inline-flex w-full gap-x-[30px] justify-between">
                    <ButtonNew class="!h-[34px] md:!h-[48px]" @click="setVacancyStatus(vacancy.id, 'PUBLISHED')"
                        :class="[mobile.isMobile.value ? '!w-[149px]' : '']">
                        Принять
                    </ButtonNew>
                    <ButtonNew class="!h-[34px] md:!h-[48px]" type="stroke-red"
                        @click="popupsStore.openModerationBanPopup(vacancyId, 'vacancy', $router)" :class="[mobile.isMobile.value ? '!w-[44px]' : '']">
                        <!-- <UIIcon v-if="mobile.isMobile.value" name="x" size="32" color="red"></UIIcon> -->
                        <span >Заблокировать</span>
                    </ButtonNew>
                </div>
                <div v-else-if="status === 'BANNED'" class="mt-[10px] md:mt-8 inline-flex w-[45%]">
                    <ButtonNew class="!h-[34px] md:!h-[48px]" @click="setVacancyStatus(vacancy.id, 'PUBLISHED')"
                        :class="[mobile.isMobile.value ? '!w-[149px]' : '']">
                        Разблокировать
                    </ButtonNew>
                </div>
                <div class="mt-[32px] ">
                    <VacancyCardFull v-if="vacancy" :vacancy="vacancy">
                        <template #under-logo>
                            <div></div>
                        </template>
                        <template #down-right-card>
                            <div></div>
                        </template>
                    </VacancyCardFull>
                </div>
                <div class="flex flex-wrap mt-5">
                        <div v-for="banReason in vacancy.ban_reasons" :class="[
                            mobile.isMobile.value ?
                                'text-[10px] leading-[12.2px] font-light p-1 px-2 bg-[#FF3B301A] rounded-md' :
                                'bg-red-100 p-2 px-3.5 text-[1rem] leading-[1.25rem] rounded-lg',
                        ]" class="flex flex-row items-center text-black mr-2 mb-2">
                            {{ humanizeBanReason(banReason) }}
                        </div>
                    </div>
            </UIContainer>


        </div>
    </DefaultLayout>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, computed } from 'vue';
import { useFetch } from '@/composables/useFetch';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIContainer from "@/components/ui/UIContainer";
import UIPageHeader from "@/components/ui/UIPageHeader";
import CardsSlider from "@/components/ui/CardsSlider";
import VacancyCardNew from "@/components/cards/VacancyCardNew";
import { usePopupsStore } from "@/stores/popupsStore";
import VacancyCardFull from '@/components/cards/VacancyCardFull.vue';
import LKLayout from '@/layouts/LKLayout.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import UIIcon from '@/components/ui/UIIcon.vue';
import { useMobile } from '@/composables/useMobile';
import {
    humanizeBanReason
} from '@/composables/utils';

const mobile = useMobile()

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const vacancyId = ref(route.params.id)

const vacancy = ref({});
const loading = ref(false);
const error = ref(null);

const { anonRequest, authRequest, baseURL } = useFetch();

const popupsStore = usePopupsStore()

const status = computed(() => {
    return route.query.status
})

onMounted(() => {
    fetchVacancyData();
});

const fetchVacancyData = async () => {

    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку

    try {

        let response
        response = await authRequest(`moderator/vacancies/${vacancyId.value}/`, {
            method: 'GET',
        });

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            vacancy.value = response; // Предполагаем, что API возвращает массив данных
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const setVacancyStatus = (id, status) => {
    authRequest(`moderator/vacancies/${vacancyId.value}/`, {
            method: 'PATCH',
            body: {status: status}
    });
    router.push('/moderator/vacancies')
}



const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

</script>

<style></style>