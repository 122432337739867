<template>
  <popup @close="emit('update:modelValue', false)">
    <div class="flex flex-col">
      <div class="text-[1.25rem] mb-[40px]">Загрузка изображения </div>

      <div class="flex flex-col ">
        <input ref="fileInput" type="file" @change="onFileChange" class="hidden mb-4" accept="image/*" />
        <ButtonNew v-if="!image" @click="selectFile">Выбрать изображение...</ButtonNew>
<!--        <img v-if="image" ref="imageElement" :src="image" class="max-w-full" />-->
        <div v-if="image" class="w-full flex flex-col items-center">
          <cropper
            ref="cropperElement"
            class="bg-[#DDD] max-w-[80vw] md:max-w-[400px] max-h-[50vh] md:max-h-[400px]"
            :src="imageURL"
            :stencil-props="{ aspectRatio: 1/1 }"
            @change="onCropChange"
          />
          <ButtonNew class="mt-[40px]" @click="upload">Сохранить изображение</ButtonNew>
<!--          <img v-if="resultURL" :src="resultURL" class="h-[50px] w-[50px]">-->
        </div>

      </div>
    </div>
  </popup>
</template>

<script setup>
import { ref } from "vue";
import Popup from "@/components/ui/Popup";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ButtonNew from "@/components/ui/ButtonNew";
import {useFetch} from "@/composables/useFetch";
import {usePopupsStore} from "@/stores/popupsStore";

const fetch = useFetch();
const popupsStore =  usePopupsStore()

const imageURL = ref(null);
const image = ref(null);


const resultURL = ref(null)


const fileInput = ref(null)
const cropperElement = ref(null)

const selectFile = () => {
  fileInput.value.click()
}

const onFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      image.value = file;
      imageURL.value = e.target.result; // Установите выбранное изображение в качестве источника для кроппера.
    };
    reader.readAsDataURL(file);
  }
};

const onCropChange = ({ coordinates, canvas }) => {
  // Обрабатываем изменения (по желанию)
  console.log(coordinates, canvas);
  resultURL.value = canvas.toDataURL();
};

// const getCroppedImage = () => {
//   // Получаем обрезанное изображение. Здесь используется canvas, чтобы получить изображение в виде URL.
//   const croppedImage = cropperElement.value.getCroppedCanvas().toDataURL();
//
//   emit('update:modelValue', false); // Закрыть попап
//   emit('image-cropped', croppedImage); // Отправить обрезанное изображение в родительский компонент
// }

const dataUrlToBlob = (dataUrl) => {
  const byteString = atob(dataUrl.split(',')[1]);
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};


const upload = () => {
  if (!resultURL.value ) {
    return
  }

  const blobData = dataUrlToBlob(resultURL.value)

  const formData = new FormData();
  formData.append('file', blobData);
  formData.append('name', image.value.name);


  fetch.authRequest('/files/', {
    method: 'POST',
    body: formData,
  }).then((data) => {
    if (popupsStore.imageUploadCropper.callback) {
      popupsStore.imageUploadCropper.callback(data.data)
    }



  }).finally(() => {
    popupsStore.imageUploadCropper.visible = false
  });


}

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['update:modelValue', 'image-cropped']);
</script>

<style scoped>
/* Добавьте стиль, если необходимо */
/*.cropper {*/
/*  height: 600px;*/
/*  width: 600px;*/
/*  background: #DDD;*/
/*}*/
</style>