<template>
  <LKLayout>
    <Sheet v-if="$authStore.me" class="flex md:flex-row flex-col justify-between h-min px-0 py-2">
      <div class="flex flex-col gap-[15px]">
        <div v-if="mobile.isMobile.value" class="flex flex-row justify-center">
          <img v-if="$authStore.me.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.avatar}/download`"
            class="!w-[284px] !h-[200px] object-cover rounded-[12px]">
          <div v-else class="!w-[284px] !h-[200px] object-cover rounded-[12px] border-[1px]">

          </div>
        </div>
        <div class="flex flex-col gap-[20px] md:ml-0 ml-[5px]">
          <div
            class="font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] mt-3 md:mt-0 text-black">
            {{ $authStore.me.first_name + ' ' + $authStore.me.last_name }}
          </div>

          <div
            class="flex gap-[12px] items-center text-black md:text-base md:leading-5 leading-[17.2px] text-[14px] font-normal">
            <UIIcon size="16" name="geoAlt"></UIIcon>
            {{ $authStore.me.city_of_residence ? $authStore.me.city_of_residence : 'Не указано' }}
          </div>


          <div class="flex flex-col gap-[8px]">
            <div class="flex gap-[12px] items-center text-black text-base leading-5 font-normal">
              <UIIcon size="16" name="envelope" color="black"></UIIcon>
              {{ $authStore.me.email ? $authStore.me.email : 'Не указано' }}
            </div>
            <div class="flex gap-[12px] items-center text-black text-base leading-5 font-normal">
              <UIIcon size="16" name="telephone" color="black"></UIIcon>
              {{ $authStore.me.phone_number ? $authStore.me.phone_number : 'Не указано' }}
            </div>
          </div>


          <div class="flex flex-col gap-[8px]">
            <div class="flex gap-[12px] items-center text-[#7B7A7A] font-normal text-[1rem] leading-[1.25rem]">
              <UIIcon size="16" name="whatsApp"></UIIcon>
              {{ $authStore.me.whatsapp_contact ? $authStore.me.whatsapp_contact : 'Не указано' }}
            </div>
            <div class="flex gap-[12px] items-center text-[#7B7A7A] font-normal text-[1rem] leading-[1.25rem]">
              <UIIcon size="16" name="telegram"></UIIcon>
              {{ $authStore.me.telegram_contact ? $authStore.me.telegram_contact : 'Не указано' }}
            </div>
          </div>


          <div class="flex flex-col gap-[8px]">
            <div class="flex gap-[12px] items-center text-black text-base leading-5 font-normal">
              <UIIcon size="16" name="calendarFrame" color="black"></UIIcon>
              {{ $authStore.me.born_date ? $authStore.me.born_date : 'Не указано' }}
            </div>
            <div class="flex gap-[12px] items-center text-black text-base leading-5 font-normal">
              <UIIcon size="16" name="person" color="black"></UIIcon>
              РУС
            </div>
          </div>


        </div>
        <div v-if="!mobile.isMobile.value"
          class="text-[#0A5CD5] underline font-medium text-base leading-5 cursor-pointer"
          @click="$router.push('/account/settings')">Редактировать данные
        </div>
        <ButtonNew v-else type="stroke" class="!h-[40px] mt-2" @click="$router.push('/account/settings')">Редактировать
          данные</ButtonNew>
      </div>
      <div v-if="!mobile.isMobile.value">
        <img v-if="$authStore.me.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.avatar}/download`"
          class="w-[231px] !h-[308px] object-cover rounded-[12px]">
        <div v-else class="w-[231px] !h-[308px] object-cover rounded-[12px] border-[1px]">

        </div>
      </div>



    </Sheet>

    <!--    {{ form }}-->
    <UIAccordion class="mt-[16px]" title="Профессия">
      <template #header-expand>
        <div>
          <div class="flex flex-col gap-[8px] mb-2">
            <div
              class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
              Профессия
            </div>
          </div>
          <div class="flex md:gap-[12px] gap-[8px] items-center">

            <UIIcon size="10" name="asterisk"></UIIcon>
            <div class="text-[#7B7A7A] md:text-[0.75rem] md:leading-[0.9375rem] text-[10px] leading-[12px] font-light">
              Поля обязательные для заполнения
            </div>
          </div>
        </div>

      </template>
      <template #default>
        <div class="flex md:flex-row flex-col justify-between gap-[12px]">
          <div class="flex flex-col md:gap-[8px] gap-[12px]">
            <div class="flex gap-[8px] items-start">
              <div class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                Должность</div>
              <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <UIInput v-model="form.speciality_name" id="form-name" :rules="[isNotEmpty]"
              @valid="isValid => updateValidity('speciality_name', isValid)" placeholder="Введите название должности">
            </UIInput>
          </div>
          <div>
            <div class="flex flex-col gap-[8px] md:w-[290px]">
              <div class="flex gap-[8px] items-start">
                <div
                  class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                  Заработная плата</div>
              </div>
              <UIInput v-model.number="form.salary_expectations" id="form-name" placeholder="От" :rules="[]"></UIInput>
            </div>
          </div>
        </div>

        <div class="mt-[24px] flex md:flex-row flex-col md:gap-[12px] gap-[35px]">
          <div class="md:flex-grow">
            <div class="flex gap-[8px] items-start">
              <div class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                Тип занятости</div>
              <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <div v-if="!employmentTypeInvalid" class="text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">
              Выберите хотя бы один вариант
            </div>
            <div v-else class="text-[#FF3B30] text-[0.75rem] leading-[0.9375rem] font-light">Выберите хотя бы один
              вариант
            </div>
            <div class="mt-[29px] flex flex-col md:gap-[8px] gap-[16px]">
              <UICheckbox :model-value="form.employment_type.includes('full_day')"
                @update:modelValue="toggleList('full_day', form.employment_type)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Полная занятость</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_type.includes('not_full_day')"
                @update:modelValue="toggleList('not_full_day', form.employment_type)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Частичная занятость</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_type.includes('project')"
                @update:modelValue="toggleList('project', form.employment_type)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Проектная работа</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_type.includes('internship')"
                @update:modelValue="toggleList('internship', form.employment_type)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Стажировка</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_type.includes('volunteering')"
                @update:modelValue="toggleList('volunteering', form.employment_type)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Подработка</span>
              </UICheckbox>

              <UICheckbox v-if="!mobile.isMobile.value" class="mt-[32px]" :model-value="form.ready_to_move"
                @update:modelValue="form.ready_to_move = $event">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Готов к переезду</span>
              </UICheckbox>

            </div>
          </div>

          <div class="md:flex-grow">
            <div class="flex gap-[8px] items-start ">
              <div class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                График работы</div>
              <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <div v-if="!employmentScheduleInvalid" class="text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">
              Выберите хотя бы один вариант
            </div>
            <div v-else class="text-[#FF3B30] text-[0.75rem] leading-[0.9375rem] font-light">Выберите хотя бы один
              вариант
            </div>

            <div class="mt-[29px] flex flex-col md:gap-[8px] gap-[16px]">
              <UICheckbox :model-value="form.employment_schedule.includes('full_day')"
                @update:modelValue="toggleList('full_day', form.employment_schedule)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Полный день</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_schedule.includes('shift_work')"
                @update:modelValue="toggleList('shift_work', form.employment_schedule)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Сменный график</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_schedule.includes('remote')"
                @update:modelValue="toggleList('remote', form.employment_schedule)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Удаленная работа</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_schedule.includes('flexible')"
                @update:modelValue="toggleList('flexible', form.employment_schedule)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Гибкий график</span>
              </UICheckbox>
              <UICheckbox :model-value="form.employment_schedule.includes('rotation')"
                @update:modelValue="toggleList('rotation', form.employment_schedule)">
                <span class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[14.7px]">Вахтовый метод</span>
              </UICheckbox>

              <UICheckbox v-if="!mobile.isMobile.value" class="mt-[32px]" :model-value="!form.ready_to_move"
                @update:modelValue="form.ready_to_move = !$event">
                Не готов к переезду
              </UICheckbox>
              <div v-else class="mt-[18px]">
                <Switch trueLabel="Готов к переезду" falseLabel="Не готов к переезду" v-model=form.ready_to_move>
                </Switch>
              </div>

              <!-- <div class="relative border-[2px] border-[#0A5CD5] w-[28px] h-[18px] rounded-[60px]">
                <div class="absolute w-[14px] h-[14px] bg-[#0A5CD5] rounded-full"></div>
              </div> -->

            </div>
          </div>


        </div>


      </template>
    </UIAccordion>


    <UIAccordion class="mt-[16px]" title="Опыт работы" subtitle="Если у Вас нет опыта работы, пропустите это пункт">
      <template #header-expand>
        <div>
          <div class="flex flex-col gap-[8px]">
            <div
              class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
              Опыт работы
            </div>
          </div>
          <div class="flex gap-[12px] items-center">

            <UIIcon size="10" name="asterisk"></UIIcon>
            <div class="text-[#7B7A7A] md:text-[0.75rem] md:leading-[0.9375rem] text-[10px] leading-[12px] font-light">
              Поля обязательные для заполнения
            </div>
          </div>
        </div>

      </template>
      <template #default>
        <div class="flex flex-col gap-[32px]">
          <template v-for="experience in form.experiences">
            <div>


              <div class="flex flex-col gap-[8px]">
                <div class="flex gap-[8px] items-start">
                  <div v-if="!mobile.isMobile.value"
                    class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                    Ваша должность в компании</div>
                  <div v-else
                    class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                    Должность в компании</div>
                  <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="experience.position_name" id="form-name" placeholder="Введите название должности"
                  :rules="[isNotEmpty]">
                </UIInput>
              </div>

              <div class="mt-[24px] flex md:flex-row flex-col gap-[12px] justify-between">
                <div class="flex flex-col gap-[8px] flex-grow">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Название компании</div>
                  </div>
                  <UIInput v-model="experience.company_name" id="form-name" placeholder="Укажите название компании">
                  </UIInput>
                </div>

                <div class="flex flex-col gap-[8px] flex-grow">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Сайт компании</div>
                  </div>
                  <UIInput v-model="experience.site" id="form-name" placeholder="https://"></UIInput>
                </div>


              </div>

              <div class="mt-[24px] flex md:flex-row flex-col gap-[12px] ">
                <div class="md:flex-grow flex-grow-0">
                  <div class="flex flex-col gap-[8px]">
                    <div class="flex gap-[8px] items-start">
                      <div
                        class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                        Начало работы</div>
                      <UIIcon size="10" name="asterisk"></UIIcon>
                    </div>

                    <div class="flex gap-[8px]">
                      <UIMonthPicker
                        :model-value="experience.start_date ? new Date(experience.start_date).getMonth() + 1 : null"
                        trigger-class="md:w-[157px] w-[127px]"
                        @update:model-value="setDateMonthExperience(experience, $event, 'start_date')">

                      </UIMonthPicker>
                      <UIYearPicker
                        :model-value="experience.start_date ? new Date(experience.start_date).getFullYear() : null"
                        trigger-class="w-[127px]"
                        @update:model-value="setDateYearExperience(experience, $event, 'start_date')">
                      </UIYearPicker>
                    </div>
                    <div class="error_message">{{ experience.dateError }}</div>

                  </div>
                </div>
                <div class="flex-grow">
                  <div class="flex flex-col gap-[8px]">
                    <div class="flex gap-[8px] items-start">
                      <div
                        class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                        Окончание работы</div>
                      <UIIcon size="10" name="asterisk"></UIIcon>
                    </div>
                    <div class="flex gap-[8px]">
                      <UIMonthPicker
                        :model-value="experience.end_date ? new Date(experience.end_date).getMonth() + 1 : null"
                        :disabled="experience.by_now === true"
                        @update:model-value="setDateMonthExperience(experience, $event, 'end_date')"
                        trigger-class="md:w-[157px] w-[127px]">
                      </UIMonthPicker>
                      <!--                      <UIInput-->
                      <!--                        -->
                      <!--                        id="form-name"-->
                      <!--                        placeholder="Месяц"-->
                      <!--                      ></UIInput>-->
                      <UIYearPicker
                        :model-value="experience.end_date ? new Date(experience.end_date).getFullYear() : null"
                        :disabled="experience.by_now === true"
                        @update:model-value="setDateYearExperience(experience, $event, 'end_date')"
                        trigger-class="w-[127px]">
                      </UIYearPicker>
                      <!--                      <UIInput-->
                      <!--                        -->
                      <!--                        id="form-name"-->
                      <!--                        placeholder="Год"-->
                      <!--                      ></UIInput>-->
                    </div>
                  </div>


                  <div v-if="!mobile.isMobile.value" class="mt-[14px]">
                    <UICheckbox v-model="experience.by_now">
                      По настоящее время
                    </UICheckbox>
                  </div>
                  <div v-else class="mt-[12px]">
                    <Switch trueLabel="По настоящее время" falseLabel="По указанному времени окончания"
                      v-model=experience.by_now>
                    </Switch>
                  </div>
                </div>

              </div>


              <div class="mt-[24px]">
                <div class="flex flex-col gap-[8px] flex-grow">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Обязанности и достижения</div>
                  </div>
                  <UITextArea v-model="experience.position_description" id="form-name"
                    placeholder="Опишите, какие обязанности вы выполняли в этой компании"></UITextArea>
                </div>
              </div>


              <div v-if="!mobile.isMobile.value" class="mt-[40px] flex justify-end">
                <div class="cursor-pointer text-red-500 font-normal text-base leading-5 "
                  @click="form.experiences.splice(form.experiences.indexOf(experience), 1)">
                  Удалить
                </div>
              </div>
              <div v-else class="mt-[32px] mb-2 flex justify-end">
                <UIIcon name="trash" @click="form.experiences.splice(form.experiences.indexOf(experience), 1)" size="20"
                  color="red"></UIIcon>
              </div>


            </div>
          </template>
        </div>


        <ButtonNew type="stroke" class="md:!w-[237px] md:!h-[48px] !h-[40px]"
          @click="form.experiences.push({ position_name: null, end_date: new Date(), start_date: new Date(), by_now: false })">
          <div class="flex gap-[12px] items-center">
            <UIIcon size="12" name="plus" color="#0A5CD5"></UIIcon>
            <div>Добавить место работы</div>
          </div>

        </ButtonNew>
      </template>
    </UIAccordion>


    <UIAccordion class="mt-[16px]" title="Профессиональные навыки">
      <template #header-expand>
        <div>
          <div class="flex flex-col gap-[8px]">
            <div v-if="!mobile.isMobile.value"
              class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
              Профессиональные навыки
            </div>
            <div v-else
              class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
              Навыки
            </div>
          </div>

        </div>

      </template>
      <template #default>
        <div class="flex gap-[32px]">
          <div class="flex flex-col gap-[8px] flex-grow">
            <div class="flex gap-[8px] items-start">
              <div class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                Ваши навыки</div>
            </div>
            <UIInput v-model="skillsInputValue" @change="addSkill(skillsInputValue)" id="form-name" placeholder="">
            </UIInput>
          </div>
          <div v-if="!mobile.isMobile.value" class="flex-grow w-[calc(50%-8px)]"></div>
        </div>

        <div class="mt-[12px] flex gap-[12px] flex-wrap">
          <div v-for="skill in form.skills"
            class="p-[4px] flex items-start rounded-[4px] bg-[#0A5CD5] bg-opacity-10 w-fit">
            <div class="text-black md:text-base md:leading-5 text-[12px] leading-[14.7px] md:font-normal font-light">{{
              skill }}</div>
            <UIIcon size="16" name="x" color="black" class="cursor-pointer" @click="removeSkill(skill)"></UIIcon>
          </div>

        </div>

      </template>
    </UIAccordion>

    <UIAccordion class="mt-[16px]" title="Образование">
      <template #header-expand>
        <div>
          <div class="flex flex-col gap-[8px]">
            <div
              class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
              Образование
            </div>
          </div>
          <div class="flex gap-[12px] items-center">

            <UIIcon size="10" name="asterisk"></UIIcon>
            <div class="text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Поля обязательные для заполнения
            </div>
          </div>
        </div>

      </template>
      <template #default>
        <div class="flex flex-col gap-[32px]">
          <template v-for="education in form.education">
            <div>


              <div class="mt-[24px] flex md:flex-row flex-col gap-[12px]  justify-between">
                <div class="flex flex-col gap-[8px] w-[290px]">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Уровень образования</div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                  </div>
                  <UISelect v-model="education.education_type" trigger-class="md:w-[290px] w-[16.25rem]"
                    class="md:!w-[290px] !w-[16.25rem]" :options="[
                      { title: 'Среднее', value: 'secondary' },
                      { title: 'Средне специальное', value: 'specialized_secondary' },
                      { title: 'Неоконченное высшее', value: 'unfinished_higher' },
                      { title: 'Бакалавр', value: 'bachelor' },
                      { title: 'Магистр', value: 'master' },
                      { title: 'Кандидат наук', value: 'candidate_of_sciences' },
                      { title: 'Доктор наук', value: 'doctor_of_sciences' },
                    ]"></UISelect>

                </div>

                <div class="flex flex-col gap-[8px] flex-grow">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black whitespace-nowrap">
                      Название
                      учебного заведения
                    </div>
                  </div>
                  <UIInput v-model="education.institution_name" id="form-name" placeholder="Введите название">
                  </UIInput>
                </div>
              </div>


              <div class="md:mt-[24px] mt-[16px] flex md:flex-row flex-col gap-[12px] justify-between">
                <div class="flex flex-col gap-[8px] flex-grow md:w-[290px] w-[16.25rem]">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Специальность</div>
                  </div>
                  <UIInput v-model="education.speciality_name" id="form-name" placeholder="" class=""></UIInput>
                </div>

                <div class="flex flex-col gap-[8px] flex-grow">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Год окончания</div>
                  </div>
                  <UIYearPicker v-model="education.graduation_year"></UIYearPicker>
                </div>
              </div>


              <div v-if="!mobile.isMobile.value" class="mt-[40px] flex justify-end">
                <div class="cursor-pointer text-red-500 font-normal text-base leading-5"
                  @click="form.education.splice(form.education.indexOf(education), 1)">
                  Удалить
                </div>
              </div>
              <div v-else class="mt-[32px] mb-2 flex justify-end">
                <UIIcon name="trash" @click="form.education.splice(form.education.indexOf(education), 1)" size="20"
                  color="red"></UIIcon>
              </div>

            </div>
          </template>
        </div>


        <ButtonNew type="stroke" class="md:!w-[237px] md:!h-[48px] !h-[40px]"
          @click="form.education.push({ education_type: 'secondary' })">
          <div class="flex gap-[12px] items-center">
            <UIIcon size="12" name="plus" color="#0A5CD5"></UIIcon>
            <div>Добавить</div>
          </div>

        </ButtonNew>
      </template>
    </UIAccordion>


    <UIAccordion class="mt-[16px]" title="Дополнительное образование, курсы">
      <template #header-expand>
        <div>
          <div class="flex flex-col gap-[8px]">
            <div
              class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
              Дополнительное образование, курсы
            </div>
          </div>
          <div class="flex gap-[12px] items-center">

            <UIIcon size="10" name="asterisk"></UIIcon>
            <div class="text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Поля обязательные для заполнения
            </div>
          </div>
        </div>

      </template>
      <template #default>
        <div class="flex flex-col gap-[32px]">
          <template v-for="education in form.addition_education">
            <div class="flex flex-col gap-[24px]">
              <div class="flex flex-col gap-[8px] md:w-[calc(50%-8px)]">
                <div class="flex gap-[8px] items-start">
                  <div
                    class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                    Название курса</div>
                  <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="education.course_name" id="form-name" :rules="[isNotEmpty]"
                  placeholder="Введите название"></UIInput>
              </div>

              <div class="flex flex-col gap-[8px] md:w-[calc(50%-8px)]">
                <div class="flex gap-[8px] items-start">
                  <div
                    class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                    Ссылка на электронный сертификат
                  </div>
                </div>
                <UIInput v-model="education.link" id="form-name" placeholder="Введите ссылку"></UIInput>
              </div>


              <div class="flex gap-[24px] justify-between">
                <div class="flex flex-col gap-[8px] w-[calc(50%-8px)]">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Организация</div>
                  </div>
                  <UIInput v-model="education.organization" id="form-name" placeholder=""></UIInput>
                </div>

                <div class="flex flex-col gap-[8px] w-[calc(50%-8px)]">
                  <div class="flex gap-[8px] items-start">
                    <div
                      class="md:font-medium md:text-[1.25rem] md:leading-[1.5rem] text-[14px] leading-[17.2px] text-black">
                      Год окончания</div>
                  </div>
                  <UIYearPicker v-model="education.graduation_year"></UIYearPicker>
                  <!--                  <UIInput-->
                  <!--                    v-model="education.graduation_year"-->
                  <!--                    id="form-name"-->
                  <!--                    placeholder=""-->
                  <!--                  ></UIInput>-->
                </div>

              </div>

              <div v-if="!mobile.isMobile.value" class="mt-[40px] flex justify-end">
                <div class="cursor-pointer text-red-500 font-normal text-base leading-5"
                  @click="form.addition_education.splice(form.addition_education.indexOf(education), 1)">
                  Удалить
                </div>
              </div>
              <div v-else class="mt-[32px] mb-2 flex justify-end">
                <UIIcon name="trash"
                  @click="form.addition_education.splice(form.addition_education.indexOf(education), 1)" size="20"
                  color="red"></UIIcon>
              </div>
            </div>
          </template>
        </div>


        <ButtonNew type="stroke" class="md:!w-[237px] mt-[24px] md:!h-[48px] !h-[40px]"
          @click="form.addition_education.push({ course_name: null })">
          <div class="flex gap-[12px] items-center">
            <UIIcon size="12" name="plus" color="#0A5CD5"></UIIcon>
            <div>Добавить образование</div>
          </div>

        </ButtonNew>
      </template>
    </UIAccordion>


    <!-- <div class="md:mt-[32px] mt-[40px] flex justify-end">
      <div
        class="cursor-pointer underline text-[#7B7A7A] md:font-medium md:text-base md:leading-5 font-normal text-[14px] leading-[17.2px]">
        Предпросмотр
      </div>

    </div> -->


    <div class="md:mt-[56px] mt-[32px] flex md:flex-row flex-col justify-end md:gap-[24px] gap-[16px]">
      <ButtonNew type="stroke" class="md:!w-[237px] md:!h-[48px] !h-[40px]" @click="createOrSave('DRAFT')">Сохранить
      </ButtonNew>
      <ButtonNew class="md:!w-[237px] md:!h-[48px] !h-[40px]" @click="createOrSave('PUBLISHED')">Опубликовать
      </ButtonNew>

    </div>

    <!-- <div v-else class="flex flex-col">
      <div class="text-[18px] leading-[22px] mb-2">Общая информация</div>
      <div class="flex flex-row mb-[20px]">
        <UIIcon size="10" name="asterisk"></UIIcon>
        <div class="text-[10px] leading-[12.2px] font-light text-[#7B7A7A] ml-2">Поля обязательные для заполнения</div>
      </div>
      <div class="flex flex-col gap-y-[16px]">

      </div>
    </div> -->



  </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import { onMounted, reactive, ref, computed, watch, onBeforeMount, toRaw } from "vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import { useFetch } from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import UILoader from "@/components/ui/UILoader";
import { useToast } from "vue-toast-notification";
import UIFileUploader from "@/components/ui/UIFileUploader";
import Sheet from "@/components/cards/Sheet";
import UIIcon from "@/components/ui/UIIcon";
import UIAccordion from "@/components/ui/UIAccordion";
import UICheckbox from "@/components/ui/UICheckbox";
import ButtonNew from "@/components/ui/ButtonNew";
import UIMonthPicker from "@/components/ui/UIMonthPicker";
import UIYearPicker from "@/components/ui/UIYearPicker";
import { useAuthStore } from "@/stores/authStore";
import { useMobile } from "@/composables/useMobile";
import Switch from "@/components/ui/Switch.vue";
import { useValidateFuncs } from "@/composables/useValidateFuncs";
import { useFormValidator } from "@/composables/useFormValidator";
import { usePopupsStore } from "@/stores/popupsStore";
const popupsStore = usePopupsStore();
const validatorFuncs = useValidateFuncs();

const mobile = useMobile()

const authStore = useAuthStore()
const route = useRoute();
const router = useRouter();

const test = reactive({
  checkbox: false,
})

const resumeId = ref(route.params.id);
const fetched = ref(false)


function validateForm() {

  if (form.employment_type.length === 0) {

    return false
  }


  if (form.employment_schedule.length === 0) {

    return false
  }

  if (isNotEmpty(form.speciality_name) !== null) {

    return false
  }

  // if (isValidNumber(form.salary_expectations) !== null) {
  //
  //   return false
  // }


  for (const experience of form.experiences) {
    if (isNotEmpty(experience.position_name) !== null) {

      return false
    }

    if (experience.end_date !== null) {
      if (experience.end_date < experience.start_date)
        return false
    }
  }

  for (const additionEducation of form.addition_education) {
    if (isNotEmpty(additionEducation.course_name) !== null) {

      return false
    }
  }

  return true;
}

const setDateMonthExperience = (experience, month, field = 'start_date') => {

  const dt = new Date(experience[field])
  dt.setMonth(month - 1)
  experience[field] = dt
}

const setDateYearExperience = (experience, year, field = 'start_date') => {

  const dt = new Date(experience[field])
  dt.setFullYear(year)
  experience[field] = dt
}

const fetch = useFetch()
const stage = ref(0)
const testDate = ref(null)
const form = reactive({

  avatar: null,
  speciality_name: null,
  sex: null,
  city_of_residence: authStore.me.city_of_residence,
  citizenship: null,
  education: [],
  addition_education: [],
  experiences: [],
  status: null,
  salary_expectations: null,
  currency: 1,
  employment_type: [],
  employment_schedule: [],
  ready_to_move: true,
  about_me: null,
  visible: true,
  // education_type: 'secondary',
  skills: [],

})

const formValidator = useFormValidator(
  form,
  {
    'speciality_name': [validatorFuncs.isNotEmpty],
    'employment_type': [validatorFuncs.isNotEmpty],
    'employment_schedule': [validatorFuncs.isNotEmpty],
  },
  true
)

let cloneForm = JSON.parse(JSON.stringify(form));

watch(() => form, (newValue, oldValue) => {
  console.log('watch in form!!!')
  const rawNewValue = toRaw(newValue);
  const rawOldValue = toRaw(cloneForm); // Сравниваем с клоном

  if (!rawOldValue || !rawNewValue) return;

  // Проходимся по каждому experience
  for (let i = 0; i < rawNewValue.experiences.length; i++) {
    const currentExperience = rawNewValue.experiences[i];
    const previousExperience = rawOldValue.experiences[i];

    // Проверяем, изменилось ли значение by_now с false на true
    if (
      previousExperience &&
      previousExperience.by_now === false &&
      currentExperience.by_now === true
    ) {
      // Устанавливаем end_date в null
      currentExperience.end_date = null;
      console.log('change by now in experience!!!')
    }
    else if (
      previousExperience &&
      previousExperience.by_now === true &&
      currentExperience.by_now === false
    ) {
      // Устанавливаем end_date в null
      currentExperience.end_date = new Date();
    }
    if (currentExperience.end_date !== null) {
      if (currentExperience.end_date < currentExperience.start_date)
        currentExperience.dateError = 'Дата окончания не может быть раньше даты начала'
      else
        currentExperience.dateError = null
    }

  }

  // Обновляем клон
  cloneForm = JSON.parse(JSON.stringify(rawNewValue));
}, { deep: true });

const skillsInputValue = ref(null)

const addSkill = (skill) => {
  if (form.skills.includes(skill)) {
    return
  }
  if (!skill) {
    return
  }
  form.skills.push(skill)
  skillsInputValue.value = null
}

const removeSkill = (skill) => {
  if (form.skills.includes(skill)) {
    form.skills.splice(form.skills.indexOf(skill), 1)
  }

}

// const untilNow = ref([])


const toggleList = (item, list) => {
  if (list.includes(item)) {
    list.splice(list.indexOf(item), 1)
  } else {
    list.push(item)
  }
  return list
}

const inputs = ref({
  'speciality_name': false,
});

const updateValidity = (index, isValid) => {
  inputs.value[index] = isValid;
}


const hasWorkExpirience = ref(false)

const cities = ref([])

function calculateAge(birthdate) {
  if (!birthdate) {
    return '-'
  }
  // Преобразуем строку даты в объект Date
  const birthDate = new Date(birthdate);
  const today = new Date(); // Текущая дата

  // Вычисляем разницу в годах
  let age = today.getFullYear() - birthDate.getFullYear();

  // Проверяем, успел ли день рождения в этом году
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Если день рождения ещё не состоялся в этом году, уменьшаем на 1
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

const isNotEmpty = (string) => {
  let s = string
  if (s === null) {
    return 'Поле не должно быть пустым'
  } else {
    s = String(s)
  }
  if (s.length > 0) {

    return null
  } else {
    return 'Поле не должно быть пустым'
  }
}


onBeforeMount(() => {
  authStore.getMe(true).then(() => {
    if (authStore.me.moderation_resumes_ban_at !== null) {
      router.push('/')
      toast.error('Вы заблокированны на создание резюме')
    }
  })
  const citiesRequest = fetch.anonRequest('/references/cities').then((data) => {
    cities.value = data.results.map(item => ({
      title: item,
      value: item,
    }))
  })


  let resumeRequest;

  if (mode.value === 'update') {
    resumeRequest = fetch.authRequest(`account/resumes/${resumeId.value}/`).then((data) => {
      // form.avatar = data.avatar
      //
      form.speciality_name = data.speciality_name
      // form.sex = data.sex
      // form.city_of_residence = data.city_of_residence
      // form.citizenship = data.citizenship
      form.addition_education = data.addition_education
      form.education = data.education
      form.status = data.status
      // form.experiences = data.experience.map(experience => ({
      //   ...experience,
      //   start_date: new Date(experience.start_date),
      //   end_date: new Date(experience.end_date),
      //   by_now: false
      // }));
      //
      form.experiences = data.experience

      for (let i = 0; i < form.experiences.length; i++) {
        if (form.experiences[i].end_date == null) {
          form.experiences[i].by_now = true
        } else {
          form.experiences[i].by_now = false
        }
      }
      form.salary_expectations = data.salary_expectations
      // form.currency = data.currency
      form.employment_type = data.employment_type
      form.employment_schedule = data.employment_schedule
      form.ready_to_move = data.ready_to_move
      form.skills = data.skills
      // form.about_me = data.about_me
      // form.visible = data.visible
      // form.education_type = data.education_type
    })
  }
  Promise.all([citiesRequest, resumeRequest].filter(Boolean)).then(() => {

    if (mode.value === 'update') {
      hasWorkExpirience.value = true
    }
    fetched.value = true;
  });
})

const addNewEducationUnit = () => {
  form.education.push({
    graduation_year: null,
    institution_name: null,
    degree_name: null,
  })

  const lenEdu = form.education.length - 1
  // const institution_name_key = `educatuons${lenEdu}institution_name`
  // const degree_name_key = `educatuons${lenEdu}degree_name`
  // const graduation_year_key = `educatuons${lenEdu}graduation_year`

  // inputs.value[institution_name_key] = false
  // inputs.value[degree_name_key] = false
  // inputs.value[graduation_year_key] = false
}

const allTrue = computed(() => {
  return Object.values(inputs.value).every(value => value === true);
});

const mode = computed(() => {
  if (resumeId.value) {
    return 'update'
  } else {
    return 'create'
  }
});

const toast = useToast()

const employmentScheduleInvalid = ref(true)
const employmentTypeInvalid = ref(true)

watch(() => form.employment_schedule, (newValue) => {

  if (newValue.length !== 0) {
    employmentScheduleInvalid.value = false
  } else {
    employmentScheduleInvalid.value = true
  }
}, { deep: true });

watch(() => form.employment_type, (newValue) => {

  if (newValue.length !== 0) {
    employmentTypeInvalid.value = false
  } else {
    employmentTypeInvalid.value = true
  }
}, { deep: true });


const createOrSave = async (type) => {
  if (type === 'PUBLISHED' && (mode.value === 'create' || form.status !== 'PUBLISHED') && authStore.me.moderationResumesCount > 0) {
    toast.warning('У вас уже есть резюме на модерации. Вы сможете публиковать резюме после завершения модерации предыдущего')
    return
  }
  let result = true;
  if (type === 'PUBLISHED' && (mode.value === 'create' || form.status !== 'PUBLISHED') && authStore.me.publishedResumesCount >= 10) {
    result = false;
    await new Promise((resolve) => {
      popupsStore.openToManyResumesPopup()


      // Подписываемся на изменение значения переменной, которую ты меняешь в зависимости от нажатой кнопки
      const unsubscribe = watch(
        () => popupsStore.toManyResumes.doPublish,
        (newValue, oldValue) => {
          // console.log('aaa', popupsStore.toManyResumes.doPublish)
          result = popupsStore.toManyResumes.doPublish
          resolve(result)
          popupsStore.toManyResumes.doPublish = null
          unsubscribe()
        })
    })


  }
  console.log('bbb', result)
  if (!result) {
    toast.warning('Отменена публикация резюме, изменения не сохранены!')
    return
  }

  const oldStatus = form.status

  form.salary_expectations = form.salary_expectations ? form.salary_expectations : null
  form.status = type
  for (let experience of form.experiences) {
    if (experience.start_date instanceof Date) {
      experience.start_date = experience.start_date.toISOString().split('T')[0]
    }

    if (experience.end_date)
      if (experience.end_date instanceof Date)
        experience.end_date = experience.end_date.toISOString().split('T')[0]
  }

  if (!validateForm()) {
    // scrollToTop()
    toast.error('Заполните форму корректно',
      { duration: 6000 }
    )

    return
  }
  if (mode.value === 'update') {
    fetch.authRequest(`account/resumes/${resumeId.value}/`, {
      method: 'PATCH',
      body: {
        ...form
      }
    }).then(response => {
      if (oldStatus === 'PUBLISHED' && type === 'DRAFT')
        toast.success('Перенесено в неопубликованные')
      else
        toast.success('Сохранено')

    })
  } else {
    fetch.authRequest('account/resumes/', {
      method: 'POST',
      body: {
        ...form
      }
    }).then(response => {
      if (response.status === 403) {
        toast.error(response.data)
        router.push(`/`)
        return
      }
      toast.success('Создано')

      authStore.getMe(true)
      router.push('/employee/lk/resumes')
    })
  }

}

</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}


.title {
  /* Создание вакансии */


  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}

.nav-item {


  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #888888;
  cursor: pointer;
}

.nav-item[active] {


  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #2894FF;
}

.form-input[disabled] {
  opacity: 0.5;
}


.form-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;
}


.form-label {


  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  margin-bottom: 20px;
}


.form-sublabel {


  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;

  margin-bottom: 20px;


}

.error_message {


  font-style: normal;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #DB4545;
}
</style>