<template>
  <Sheet class="!p-[24px] w-full min-h-[480px] ">
    <div class="bg-white">
      <!-- Верхняя панель навигации -->
      <div class="flex justify-between items-center mb-4">
        <!-- Левая часть навигации: Стрелка и название предыдущего месяца -->
        <div class="flex items-center">
          <UIIcon size="12" name="chevronLeft" @click="prevMonth" class="cursor-pointer"></UIIcon>
          <span class="ml-2 text-sm text-gray-700">{{ prevMonthLabel }}</span>
        </div>

<!--        &lt;!&ndash; Название текущего месяца и года &ndash;&gt;-->
<!--        <h2 class="text-xl font-semibold">-->
<!--          {{ currentMonthYear }}-->
<!--        </h2>-->

        <!-- Правая часть навигации: Название следующего месяца и стрелка -->
        <div class="flex items-center">
          <span class="mr-2 text-sm text-gray-700">{{ nextMonthLabel }}</span>
          <UIIcon size="12" name="chevronRight" @click="nextMonth" class="cursor-pointer"></UIIcon>
        </div>
      </div>

      <!-- Названия дней недели -->
      <div class="grid grid-cols-7">
        <div
          v-for="(day, index) in weekdays"
          :key="index"
          class="text-white bg-[#0A5CD5] font-light text-[0.625rem] leading-[0.75rem] flex items-center justify-center"
        >
          {{ day }}
        </div>
      </div>

      <!-- Сетка календаря -->
      <div class="grid grid-cols-7">
        <div
          v-for="(day, index) in calendarDays"
          :key="index"

          :class="[
            'border p-[6px] h-[75px] relative hover:border-[#0A5CD5]',
            !day.currentMonth ? 'bg-transparent text-transparent' : '',
            day.event ? 'bg-[#0A5CD5] bg-opacity-[0.05] cursor-pointer' : '',
          ]"
        >
          <span
            :class="[
              'absolute top-1 left-1 font-bold text-[0.75rem] leading-[0.9375rem]',
              day.event ? 'text-blue-600 font-semibold' : '',
            ]"
          >
            {{ day.date.getDate() }}
          </span>
          <!-- Событие -->
          <div v-if="day.event" class="absolute left-1 top-[23px] w-[90%] h-[60%] " @click="openEvent(day.event.id) & sendMetrick('EDUCATION-PAGE-EVENT')" @click.stop>
            <div class="text-[#1F1E1E] font-medium text-[0.75rem] leading-[0.9375rem] break-words absolute w-full h-full overflow-hidden  ">
              {{ day.event.name }}
            </div>

            <div
              v-if="openedEvents.includes(day.event.id)"
              class="absolute w-[210px] top-[90%] left-[90%] z-10 box-border flex flex-col justify-center  p-4 gap-3 bg-white border border-blue-600 rounded-[0_1.5rem_1.5rem_1.5rem]"

            >
              <div class="font-bold text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">
                {{day.event.name}}
              </div>
              <div class="mt-[8px]gap-6 font-normal text-xs leading-3 text-black">
                {{day.event.short_description}}
              </div>

              <div @click="router.push(`/education/events/${day.event.id}`)" class="w-full mt-[12px] flex justify-end text-[#0A5CD5] text-[1rem] leading-[1.25rem] font-normal hover:underline">
                Подробнее
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Sheet>
</template>

<script setup>
import {ref, computed, watch, onMounted, onUnmounted} from 'vue'
import Sheet from "@/components/cards/Sheet";
import UIIcon from "@/components/ui/UIIcon";
import {useEventsStore} from "@/stores/eventsStore";
import router from '@/router';
import { sendMetrick } from '@/composables/utils';



const emit = defineEmits([
  'update:current'
])

// Определение пропса `events`
const props = defineProps({
  events: {
    type: Array,
    default: () => [],
    validator: (value) => {
      return value.every(event =>
        typeof event.date === 'string' &&
        !isNaN(Date.parse(event.date)) &&
        typeof event.name === 'string'
      )
    }
  },
  current: {
    type: Date,
    default: new Date()
  }
})

// Текущая дата
const today = new Date()

// Реактивное состояние для текущего отображаемого месяца
// const current = ref(new Date(today.getFullYear(), today.getMonth(), 1))

// watch(() => current.value, () => {
//   emit('update:current', current.value)
// })

// Массив названий дней недели на русском, начиная с понедельника
const weekdays = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
]

const openedEvents = ref([])


const openEvent = (id) => {
  if (!openedEvents.value.includes(id)) {
    openedEvents.value = [id]
  }
}


// Форматирование текущего месяца и года
const currentMonthYear = computed(() => {
  return props.current.toLocaleString('ru-RU', {
    month: 'long',
    year: 'numeric',
  })
})

const clickOnWindow = () => {
    openedEvents.value = []
}

onMounted(() => {
  // current.value = new Date(today.getFullYear(), today.getMonth(), 1)
  window.addEventListener('click', clickOnWindow)
})

onUnmounted(() => {
  window.removeEventListener('click', clickOnWindow)
})

// Форматирование предыдущего месяца и года
const prevMonthLabel = computed(() => {
  const prev = new Date(props.current.getFullYear(), props.current.getMonth() - 1, 1)
  return prev.toLocaleString('ru-RU', {
    month: 'long',
    year: 'numeric',
  })
})

// Форматирование следующего месяца и года
const nextMonthLabel = computed(() => {
  const next = new Date(props.current.getFullYear(), props.current.getMonth() + 1, 1)
  return next.toLocaleString('ru-RU', {
    month: 'long',
    year: 'numeric',
  })
})

// Функции для навигации
const prevMonth = () => {
  sendMetrick('EDUCATION-PAGE-EVENTS-CALENDAR')
  const newCurrent = new Date(
    props.current.getFullYear(),
    props.current.getMonth() - 1,
    1
  )
  emit('update:current', newCurrent)
}

const nextMonth = () => {
  sendMetrick('EDUCATION-PAGE-EVENTS-CALENDAR')
  const newCurrent = new Date(
    props.current.getFullYear(),
    props.current.getMonth() + 1,
    1
  )
  emit('update:current', newCurrent)
}

// Создание карты событий для быстрого доступа
const eventsMap = computed(() => {
  const map = new Map()
  props.events.forEach(event => {
    let eventDate = new Date(event.date)
    eventDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate())
    const key = eventDate.toISOString().split('T')[0] // Формат YYYY-MM-DD
    if (!map.has(key)) {
      map.set(key, event)
    }
    // Если уже существует событие, оставляем первое (самое раннее)
  })
  return map
})

// Генерация дней календаря
const calendarDays = computed(() => {
  const year = props.current.getFullYear()
  const month = props.current.getMonth()

  // Первый день месяца
  const firstDay = new Date(year, month, 1)
  // Последний день месяца
  const lastDay = new Date(year, month + 1, 0)

  // Определяем, с какого дня недели начинается месяц (1 = понедельник, 7 = воскресенье)
  let startDay = firstDay.getDay()
  if (startDay === 0) startDay = 7 // Воскресенье

  // Общее количество клеток в календаре (6 недель)
  const totalCells = 42

  const days = []

  for (let i = 0; i < totalCells; i++) {
    const dayNumber = i - (startDay - 1) + 1
    let date, currentMonthFlag

    if (dayNumber < 1) {
      // День из предыдущего месяца
      date = new Date(year, month, dayNumber)
      currentMonthFlag = false
    } else if (dayNumber > lastDay.getDate()) {
      // День из следующего месяца
      date = new Date(year, month + 1, dayNumber - lastDay.getDate())
      currentMonthFlag = false
    } else {
      // День текущего месяца
      date = new Date(year, month, dayNumber)
      currentMonthFlag = true
    }

    // Формирование ключа для карты событий
    const key = date.toISOString().split('T')[0]

    // Получение события из карты
    const event = currentMonthFlag ? eventsMap.value.get(key) : null

    days.push({
      date,
      currentMonth: currentMonthFlag,
      event,
    })
  }

  return days
})

// Функция для определения текущего дня (не используется в шаблоне, но можно оставить)
const isToday = (date) => {
  const todayDate = new Date()
  return (
    date.getDate() === todayDate.getDate() &&
    date.getMonth() === todayDate.getMonth() &&
    date.getFullYear() === todayDate.getFullYear()
  )
}
</script>

<style scoped>
/* Дополнительные стили при необходимости */
/* Пример выделения текущего дня */
.border-blue-500 {
  border-color: #3b82f6 !important;
}
</style>