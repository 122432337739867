<template>
    <div class="flex flex-col items-center md:w-[316px] md:mt-[32px] md:mb-[100px] w-[16.4rem] mt-[2.5rem] mb-[2.5rem]">
        <div class="md:h-[44px] flex flex-row justify-center items-center cursor-pointer" @click="hidden = !hidden">
            <div class="text-[16px] md:mr-[15px] mr-[0.8rem] !text-[#0A5CD5] font-medium underline">Связь с тех.
                поддержкой
            </div>
            <Icon v-if="hidden" name="chevron-down"></Icon>
            <Icon v-else name="chevron-up"></Icon>
        </div>
        <div class="w-full flex flex-col items-center" v-if="!hidden">
            <div class="text-[16px] leading-[20px] !text-[#1F1E1E] md:mt-[16px] mt-[1.5rem] whitespace-nowrap">Выберите
                удобный способ связи</div>
            <div class="md:w-[200px] w-[12.6rem] md:mt-[16px] mt-[1.3rem] flex flex-col items-center">
                <div class="md:w-[170px] w-[11.6rem] flex flex-row justify-between">

                    <a :href="'https://t.me/' + staticStore.support.telegram" target="_blank">
                        <Icon name="telegram-big-colored"></Icon>
                    </a>
                    <a :href="'https://wa.me/' + staticStore.support.whatsapp" target="_blank">
                        <Icon name="whatsapp-big-colored"></Icon>
                    </a>
                    
                </div>
                <div
                    class="flex flex-row w-full md:pl-[12px] md:pr-[22px] pl-[1.1rem] pr-[1.5rem] gap-x-[14px] items-center h-[40px] mt-[16px]">
                    <Icon name="mail-big-blue"></Icon>
                    <div class="md:text-[16px] text-[14px] leading-[20px] !text-[#1F1E1E] break-normal">
                        {{ staticStore.support.email }}</div>
                </div>
                <div
                    class="flex flex-row w-full md:px-[12px] px-[1.1rem] gap-x-[14px] items-center h-[40px] md:mt-[16px]">
                    <Icon name="phone-big-blue"></Icon>
                    <div class="md:text-[16px] text-[14px] leading-[20px] !text-[#1F1E1E] break-normal">
                        {{ staticStore.support.phone }}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import Icon from '../ui/Icon.vue';
import { ref, defineProps } from 'vue';

import { useStaticStore } from '@/stores/staticStore';

const staticStore = useStaticStore();

const hidden = ref(true)

</script>