<template>
  <!-- Тут высота инпута 42px + высота уведомления 22px + "отступ" - решил сделать 20px -->
  <div>
    <input
      :disabled="props.disabled"
      :value="modelValue"
      @input="inputHandler"
      :style="{ '--outline-color': outlineColor }"
      :valid="(error === null)"
      :id="props.id"
      :class="computedInputClass"
      :placeholder="placeholder"
      :type="type"
      @change="emit('change', $event.target.value)"
    />
    <div v-if="error || errorText" class="error_message">{{ errorText || error  }}</div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, computed, onMounted, watch} from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  id: {
    type: String,
    default: ''
  },
  inputClass: {
    type: String,
    default: ''
  },
  outlineColor: {
    type: String,
    default: '#1F1E1E', // Цвет по умолчанию
  },
  rules: {
    type: Array,
    default: () => []
  },
  placeholder: {
    default: null,
  },
  errorText: {
    default: null
  },
  disabled: {
    default: false
  },
  type: {
    default: 'text',
  }
})

onMounted(() => {
  validate(props.modelValue);
})

watch(() => props.modelValue, (value) => {
  validate(value);
})

const emit = defineEmits(['update:modelValue', 'valid', 'change'])

const error = ref(props.errorText ? true : null)

watch(() => props.errorText, (v) => {
  if (v) {
    error.value = true
  } else {
    error.value = null
  }
})

const standardInputClass = "form-input w-full ";
const computedInputClass = computed(() => {
  return standardInputClass + props.inputClass;
});

const inputHandler = (event) => {
  emit('update:modelValue', event.target.value)
  validate(event.target.value);
}

const validate = (val) => {
  let isValid = true;
  for (let i = 0; i < props.rules.length; i++) {

    const res = props.rules[i](val)
    if (res !== null) {
      error.value = res
      isValid = false;
      break;
    } else {
      error.value = null
    }
  }

  emit('valid', isValid);
}

onMounted(() => {

  validate(props.modelValue)
})
</script>

<style scoped>
.form-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;
}


.form-input[valid="false"] {
  outline: 1px solid #DB4545;
}

.form-input[valid="true"] {
  outline: 1px solid #ECECEC
}

.form-input:focus {
  outline: 1px solid var(--outline-color);
}


.error_message {


  font-style: normal;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 0px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #DB4545;
}
</style>