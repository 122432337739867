<template>
    <DefaultLayout>
        <div v-if="!mobile.isMobile.value" class="w-full flex flex-row justify-center">
            <div class="md:w-[992px] w-full">
                <div class="w-full">
                    <div class="text-[36px] leading-[44.03px] font-medium text-[#0A5CD5] mt-[24px]">Студентам</div>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-row justify-between items-center h-[58px] px-[0.7rem]">
            <UIIcon @click="router.back()" name="chevronLeft" size="24px" color="#0A5CD5"></UIIcon>
            <div class="text-[18px] font-medium text-[#0A5CD5]">Студентам</div>
            <div></div>
        </div>
<!--        <CardsSlider class="pl-[10px] md:pl-[-50px] md:ml-[-20px]">-->
      <UIMarkuee>

      <CardsSlider class="pl-[10px] md:pl-[-50px] md:ml-[-20px] overflow-visible">

          <template v-for="i in [1, 2, 3, 4, 5, 6]">
            <div class=" md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/students1.webp"
                    class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                    <span class="text-[#0A5CD5]">Агрегатор студентческих</span>
                    <span class="text-[#0A5CD5]">возможностей</span>
                </div>
            </div>
            <div class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/students2.webp"
                    class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                    <span class="text-[#0A5CD5]">Начни свой карьерный путь</span>
                    <span class="text-[#0A5CD5]">уже сейчас</span>
                </div>
            </div>
            <div class=" md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/students3.webp"
                    class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                    <span class="text-[#0A5CD5]">Место начала карьерного</span>
                    <span class="text-[#0A5CD5]">пути</span>
                </div>
            </div>
          </template>
        </CardsSlider>
      </UIMarkuee>

      <div class="w-full flex flex-row justify-center">
            <div class="md:w-[992px] w-full md:mt-[24px] md:px-0 px-[1rem]">
                <div class="md:w-full w-[80%]">
                    <div
                        class="md:text-[24px] md:leading-[29.35px] text-[#0A5CD5] md:mb-[32px] md:font-normal font-medium text-[1.12rem] leading-[1.37rem]">
                        Данный раздел поможет вам если
                        вы:</div>
                </div>
                <div class="flex md:flex-row flex-col gap-x-[30px] w-full">
                    <div class="flex flex-col md:gap-y-[20px] gap-y-[0.75rem] w-full md:mt-0 mt-[1rem]">
                        <div class="feature">
                            <UIIcon size="28" name="award"></UIIcon>
                            Хотите получить опыт работы уже сейчас
                        </div>
                        <div class="feature">
                            <UIIcon size="28" name="hatEducation"></UIIcon>
                            Студент, желающий проявить себя
                        </div>
                        <div class="feature">
                            <UIIcon size="28" name="trophy"></UIIcon>
                            Хотите получить работу по окончанию учебы
                        </div>
                    </div>
                    <div class="flex flex-col justify-center w-full md:mt-0 mt-[32px]">
                        <ButtonNew @click="router.push('/signup')" type="stroke" class="md:!text-[24px] md:!h-[77px] md:!leading-[29.35px] !rounded-[100px] md:!font-normal
                                   !font-medium !text-[18px] !leading-[22.01px] !h-[46px]">Присоединяйся к нам уже
                            сейчас!
                        </ButtonNew>
                    </div>
                </div>
            </div>


        </div>

        <!-- <div class="flex flex-row">
            <div v-for="student in students" class="ml-10">
                <StudentCard :user="student"></StudentCard>
            </div>
        </div> -->
        <CardsSlider v-if="students.length > 0"
            class="pl-[16px] md:pl-[-50px] md:ml-[-20px] md:mt-[44px] mt-[1rem] md:h-[295px] h-[198px]">
            <div v-for="(student, index) in students" class="md:!w-[446px] w-[17.875rem]" :class="[
                index === 0 ? 'md:ml-[calc((100vw-992px)/2.0)]' : '',
                index === students.length - 1 ? 'md:mr-[calc((100vw-992px)/2.0)]' : '',
            ]">
                <StudentCard :user="student"></StudentCard>
            </div>
        </CardsSlider>


        <div class="w-full flex flex-row justify-center mt-[16px]">
            <div class="md:w-[992px] w-full md:px-0 px-[1rem]">
                <div
                    class="bg-white md:text-[36px] md:leading-[44.03px] text-[18px] leading-[22px] text-[#0A5CD5] mb-[32px] font-medium">
                    Компании,
                    предлагающие стажировку</div>
                <div
                    class="flex flex-row flex-wrap items-center md:justify-start justify-center cursor-default md:gap-x-[60px] gap-x-[1rem] md:gap-y-[20px] gap-y-[1rem]">
                    <div v-for="employer in internshipEmployers">
<!--                        <img v-if="employer.avatar" :src="`${baseURL}/files/${employer.avatar}/download`"-->
<!--                            class="md:max-h-24 max-h-[44px] object-contain">-->
                        <Avatar v-if="employer.avatar" :image-id="employer.avatar" :size="mobile.isMobile.value ? 44 : 88"></Avatar>
                        <div v-else
                            class="border border-black text-[14px] md:text-[36px] md:h-[90px] h-[38px] font-medium flex items-center p-2 rounded-[12px]">
                            {{ employer.company_name }}</div>
                    </div>
                </div>
                <div
                    class="md:text-[36px] text-[#0A5CD5] md:leading-[44.03px] text-[18px] leading-[22px] md:mt-[60px] mt-[40px] md:mb-[32px] mb-[16px] font-medium">
                    Университеты
                    участвующие в программах стажировки</div>
                <div class="flex md:flex-row flex-col items-center md:h-[175px] gap-x-[40px]">
                    <img src="./../assets/images/bauman.webp" class="md:w-[487px] md:h-[124px] w-[21.25rem] h-[5.4rem]">
                    <img src="./../assets/images/mgu.webp" class="md:w-[411px] md:h-[175px] w-[17.87rem] h-[7.62rem]">
                </div>
                <div
                    class="md:text-[36px] md:leading-[44.03px] text-[18px] leading-[22px] text-[#0A5CD5] md:mt-[60px] mt-[32px] mb-[12px] font-medium">
                    Покажи
                    свою
                    экспертность перед лидерами рынка</div>
                <div class="text-[14px] leading-[17.12px] mb-[20px]">Предлагаем вам написать статьи о ваших проектах
                    и
                    дать возможность зарекомендовать себя перед компаниями</div>

                <div
                    class="md:text-[24px] md:leading-[29.35px] md:font-normal text-[18px] leading-[22px] font-medium text-[#0A5CD5] md:mb-0 mb-[16px]">
                    Статьи</div>
            </div>

        </div>
        <CardsSlider v-if="!mobile.isMobile.value" class="pl-[10px] md:pl-[-50px] md:ml-[-20px]">
            <div v-for="(article, index) in articles.items" class="md:!w-[486px]" :class="[
                index === 0 ? 'md:ml-[calc((100vw-992px)/2.0)]' : '',
                index === articles.items.length - 1 ? 'md:mr-[calc((100vw-992px)/2.0)]' : '',
            ]">
                <ArticleIndexCard :article="article"></ArticleIndexCard>
            </div>
        </CardsSlider>
        <div v-else class="w-full px-[1rem]">
            <div v-for="article in articles.items.slice(0, 2)" class="flex flex-col mb-[1rem]">
                <ArticleIndexCard :article="article"></ArticleIndexCard>
            </div>
            <div class="w-full flex flex-row justify-center">
                <div class="text-[14px] leading-[17.12px] text-[#0A5CD5] hover:underline" @click="router.push('/education/articles')">Посмотреть все</div>
            </div>

        </div>
        <div class="w-full flex flex-row justify-center mt-[16px] md:px-0 px-[1rem] mt-[1rem] md:mt-0">
            <div class="md:w-[992px] w-full">
                <div class="w-full">
                    <div class="md:text-[24px] md:leading-[29.35px] md:font-normal text-[18px] leading-[22px] font-medium text-[#0A5CD5] md:mt-[16px] mt-0 md:mb-0 mb-[16px]">Мероприятия</div>
                </div>
            </div>
        </div>
        <CardsSlider v-if="!mobile.isMobile.value" class="pl-[10px] md:pl-[-50px] md:ml-[-20px] mb-[98px]">
            <div v-for="(event, index) in events.items" class="!w-[486px]" :class="[
                index === 0 ? 'md:ml-[calc((100vw-992px)/2.0)]' : '',
                index === events.items.length - 1 ? 'md:mr-[calc((100vw-992px)/2.0)]' : '',
            ]">
                <EventIndexCard :event="event"></EventIndexCard>
            </div>
        </CardsSlider>
        <div v-else class="w-full px-[1rem]">
            <div v-for="event in events.items.slice(0, 2)" class="flex flex-col mb-[1rem]">
                <EventIndexCard :event="event"></EventIndexCard>
            </div>
            <div class="w-full flex flex-row justify-center">
                <div class="text-[14px] leading-[17.12px] text-[#0A5CD5] hover:underline mb-[32px]" @click="router.push('/education/events')">Посмотреть все</div>
            </div>

        </div>


    </DefaultLayout>
</template>

<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import CardsSlider from '@/components/ui/CardsSlider.vue';
import UIIcon from '@/components/ui/UIIcon.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import ArticleIndexCard from '@/components/cards/ArticleIndexCard.vue';
import router from '@/router';
import { useFetch } from '@/composables/useFetch';
import { onMounted, ref } from 'vue';
import { useArticlesStore } from '@/stores/articlesStore';
import { useEventsStore } from '@/stores/eventsStore';
import EventIndexCard from '@/components/cards/EventIndexCard.vue';
import StudentCard from '@/components/cards/StudentCard.vue';
import { useMobile } from '@/composables/useMobile';
import Avatar from "@/components/ui/Avatar";
import UIMarkuee from "@/components/ui/UIMarkuee";

const mobile = useMobile()
const { anonRequest, authRequest, baseURL } = useFetch();

const internshipEmployers = ref([])
const students = ref([])

// const f = ref(false)

const articles = useArticlesStore()
const events = useEventsStore()

const getInternshipEmployers = () => {
    anonRequest('employers-internship').then((data) => {
        internshipEmployers.value = data
    })
}

const getStudents = () => {
    anonRequest('students/').then((data) => {
        students.value = data.results
    })
}

onMounted(() => {
    getInternshipEmployers();
    getStudents();
    articles.getPublicArticles();
    events.getNears()
});


</script>

<style scoped>
/* @media (max-width: 768px) {
  .feature {
    box-shadow: 8px 8px 24px 0 rgba(10, 92, 213, 0.29);
    @apply w-full bg-[#0A5CD5] rounded-[12px] flex flex-row gap-x-[12px] items-center justify-center text-white text-[14px] leading-[17.2px] h-[52px]
  }
} */

.shadow {
    box-shadow: 8px 8px 24px 0px #0A5CD529;
    ;
}

.feature {
    @apply shadow;
    @apply w-full bg-[#0A5CD5] rounded-[12px] flex flex-row gap-x-[12px] items-center justify-start text-white text-[14px] leading-[17.2px] h-[60px] pl-[28px]
}
</style>