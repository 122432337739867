<template>
  <div class="flex flex-col  ml-[-1rem] md:ml-0 w-screen md:w-auto md:mt-[40px]">
    <div class="md:relative flex flex-row items-center justify-between w-full min-h-[56px] px-[16px] md:px-0 bg-white">
      <slot name="prepend">
        <Icon class="md:hidden" @click="router.back()" v-if="isMobile" name="chevron-left-back-mobile"></Icon>
      </slot>

      <div class="flex flex-row justify-center w-full">
        <div class="md:hidden registration-header">
          <slot>
            {{ title }}
          </slot>
        </div>
        <slot name="search">



          <div class="hidden md:block  w-full h-full ">
            <div class=" text-[#0A5CD5] text-[2.25rem] leading-[2.75rem] font-bold">
              <slot>
                {{ title }}
              </slot>
            </div>
            <div v-if="!isMobile" class="flex flex-row md:mt-[20px] ">
              <Input :placeholder="searchPlaceholder" v-model="searchLocalValue" @keydown.enter="emit('search', searchLocalValue)" inputClass="!h-[40px] md:!p-[16px_16px] md:!h-[48px] "></Input>
              <ButtonNew @click="emit('search', searchLocalValue)" class="md:w-[150px] md:ml-[20px] flex-shrink-0">
                Найти
              </ButtonNew>
            </div>




            <slot name="back">
              <div class="mt-[52px] md:mt-[20px] flex items-center gap-[12px] cursor-pointer" @click="$router.back()">
                <img src="/icons/arrow-left-20-black.svg">
                Вернуться к полному списку
              </div>
            </slot>

          </div>
        </slot>
      </div>

      <div class="md:hidden">
        <slot name="append">

        </slot>
      </div>


    </div>

    <div class="md:hidden mx-[16px] mt-[16px]">
      <slot name="search">
        <Input type="lupa" placeholder="Профессия, должность или компания" v-model="searchLocalValue" @keydown.enter="emit('search', searchValue)" outlineColor="#0A5CD5" inputClass="!h-[40px]"></Input>
      </slot>

    </div>
  </div>

</template>

<script setup>

import {useRouter} from "vue-router";
import {ref, defineProps, defineEmits, onMounted, watch} from "vue";
import Icon from "@/components/ui/Icon";
import ButtonNew from "@/components/ui/ButtonNew";
import Input from "@/components/ui/Input";

const router = useRouter()

const isMobile = ref(window.innerWidth <= 768);

const props = defineProps({
  to: {
    default: null,
  },
  searchPlaceholder: {
    default: null,
  },
  title: {
    default: 'Заголовок'
  },

  searchValue: {
    default: null
  },
  noSearchFirst: {
    default: false
  }
  
})

const searchLocalValue = ref(props.searchValue)

watch(() => props.searchValue, () => {
  searchLocalValue.value = props.searchValue
} )

watch(() => searchLocalValue.value, (newValue, oldValue) => {
  if (newValue==oldValue) {
    return
  }
  emit('update:searchValue', searchLocalValue.value)
})

const emit = defineEmits([
  'update:searchValue',
  'search'
])

onMounted(() => {

    // props.preValue !== ''
    // searchValue.value = props.preValue
    // emit('search', searchValue.value)



});

</script>

<style scoped>

</style>