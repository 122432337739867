<template>
    <div class="relative w-full">
        <video autoplay muted loop playsinline
            class="w-screen md:h-[704px] h-[276px] object-cover md:mt-0 pointer-events-none">
            <source
                src="https://s3.timeweb.cloud/b8d0beb1-755b3062-4ede-4c36-b213-f8c6c94df1e2/waterjob/Видео без знака.mp4"
                type="video/mp4">
        </video>
        <div class="absolute md:top-[60px] top-[50px] left-1/2 transform -translate-x-1/2 flex flex-col md:gap-y-[32px] gap-y-[20px] md:max-w-[610px] max-w-[21.5rem] w-full">
            <div class="flex flex-col mt-[1rem] md:mt-0" :class="labelClass">
                <span
                    class="md:text-[36px] md:leading-[44px] md:font-normal font-bold text-[1.25rem] leading-[1.53rem]  text-white">Агрегатор</span>
                <div class="flex flex-row">
                    <span
                        class="md:text-[36px] md:leading-[44px] md:font-normal font-bold text-[1.25rem] leading-[1.53rem]  text-white">карьерных&nbsp</span>
                    <span
                        class="md:text-[36px] md:leading-[44px] text-[1.25rem] leading-[1.53rem] text-white font-bold">возможностей</span>
                </div>
            </div>

            <div v-if="!mobile.isMobile.value" class="flex flex-row gap-x-[24px] items-center h-[431px]">
                <Sheet :class="employeeWelcomeClass" @mouseenter="isHoveredEmployee = true"
                    @mouseleave="isHoveredEmployee = false">
                    <img src="./../../assets/images/employee_welcome.webp" :class="employeeWelcomeImageClass"
                        class="transition-height-width" />
                    <ButtonNew type="stroke" class="!text-[14px] !h-[41px] !rounded-[32px] flex-none"
                        @click="setMode('employee')">Я ищу работу</ButtonNew>

                    <div v-if="isEmployeeActive" class="advantages">
                        <OpacityAnimation delay="0s">
                            <div class="advantage">
                                <div class="advantage-circle"></div>
                                Полностью бесплатно
                            </div>
                        </OpacityAnimation>
                        <OpacityAnimation delay="0.25s">
                            <div class="advantage">
                                <div class="advantage-circle"></div>
                                Легкая коммуникация
                            </div>
                        </OpacityAnimation>
                        <OpacityAnimation delay="0.5s">
                            <div class="advantage">
                                <div class="advantage-circle"></div>
                                Способ заявить о себе
                            </div>
                        </OpacityAnimation>


                    </div>


                </Sheet>
                <Sheet :class="employerWelcomeClass" @mouseenter="isHoveredEmployer = true"
                    @mouseleave="isHoveredEmployer = false">
                    <img src="./../../assets/images/employer_welcome.webp" :class="employerWelcomeImageClass"
                        class="transition-height-width" />
                    <ButtonNew type="stroke" class="!text-[14px] !h-[41px] !rounded-[32px] flex-none"
                        @click="setMode('employer')">Я нанимаю на работу</ButtonNew>
                    <div v-if="isEmployerActive" class="advantages">
                        <OpacityAnimation delay="0s">
                            <div class="advantage">
                                <div class="advantage-circle"></div>
                                От 5000 рублей в месяц
                            </div>
                        </OpacityAnimation>
                        <OpacityAnimation delay="0.25s">
                            <div class="advantage">
                                <div class="advantage-circle"></div>
                                Легкость использования
                            </div>
                        </OpacityAnimation>
                        <OpacityAnimation delay="0.5s">
                            <div class="advantage">
                                <div class="advantage-circle"></div>
                                Способ заявить о компании
                            </div>
                        </OpacityAnimation>



                    </div>
                </Sheet>
            </div>
            <div v-else class="flex flex-row gap-x-[9px]">
                <ButtonNew :type="mobileEmployeeButtonType" class="!rounded-[32px] !h-[41px]"
                    @click="setMode('employee')">Я
                    ищу работу</ButtonNew>

                <ButtonNew :type="mobileEmployerButtonType" class=" !rounded-[32px] !h-[41px]"
                    @click="setMode('employer')">
                    Я нанимаю</ButtonNew>
            </div>
            <div v-if="mobile.isMobile.value" class="flex flex-row w-full" :class="advantagesMobileClass">
                <div v-if="isEmployeeActive" class="advantages-mobile">
                    <div class="advantage-mobile">
                        <div class="advantage-circle"></div>
                        Полностью бесплатно
                    </div>
                    <div class="advantage-mobile">
                        <div class="advantage-circle"></div>
                        Легкая коммуникация
                    </div>
                    <div class="advantage-mobile">
                        <div class="advantage-circle"></div>
                        Способ заявить о себе
                    </div>
                </div>
                <div v-else class="advantages-mobile">
                    <div class="advantage-mobile">
                        <div class="advantage-circle"></div>
                        От 5000 рублей в месяц
                    </div>
                    <div class="advantage-mobile">
                        <div class="advantage-circle"></div>
                        Легкость использования
                    </div>
                    <div class="advantage-mobile">
                        <div class="advantage-circle"></div>
                        Способ заявить о себе
                    </div>
                </div>
            </div>

        </div>
    </div>




</template>
<script setup>
import Sheet from './Sheet.vue';
import { ref, computed, defineProps, defineEmits, watch } from 'vue'
import ButtonNew from '../ui/ButtonNew.vue';
import { useMobile } from '@/composables/useMobile';
const mobile = useMobile()
import OpacityAnimation from '../animations/OpacityAnimation.vue';

const isHoveredEmployee = ref(false)
const isHoveredEmployer = ref(false)

let standardImageClass = 'w-full object-cover rounded-[1rem] '

const advantages = ref([
    { id: 1, text: 'Полностью бесплатно' },
    { id: 2, text: 'Легкая коммуникация' },
    { id: 3, text: 'Способ заявить о себе' },
]);


const isEmployeeActive = computed(() => {
    if (isHoveredEmployee.value === true)
        return true
    else
        if (mode.value === 'employee' & isHoveredEmployer.value === false)
            return true
    return false
})

const isEmployerActive = computed(() => {
    if (isHoveredEmployer.value === true)
        return true
    else
        if (mode.value === 'employer' & isHoveredEmployee.value === false)
            return true
    return false
})

const labelClass = computed(() => {
    if (mode.value === 'employee')
        return 'items-start'
    else
        return 'items-end'
})

const advantagesMobileClass = computed(() => {
    if (mode.value === 'employee')
        return 'justify-start'
    else
        return 'justify-end'
})

const employeeWelcomeImageClass = computed(() => {
    if (isEmployeeActive.value === true)
        return standardImageClass + 'h-[219px]'
    else
        return standardImageClass + 'h-[276px]'
})

const employerWelcomeImageClass = computed(() => {
    if (isEmployerActive.value === true)
        return standardImageClass + 'h-[219px]'
    else
        return standardImageClass + 'h-[276px]'
})

const mobileEmployeeButtonType = computed(() => {
    if (isEmployeeActive.value === true)
        return 'blue'
    else
        return 'stroke'
})

const mobileEmployerButtonType = computed(() => {
    if (isEmployerActive.value === true)
        return 'blue'
    else
        return 'stroke'
})

const employeeWelcomeClass = computed(() => {
    if (isEmployeeActive.value === true)
        return 'welcome-block-card-active'
    else
        return 'welcome-block-card-noactive'
})

const employerWelcomeClass = computed(() => {
    if (isEmployerActive.value === true)
        return 'welcome-block-card-active'
    else
        return 'welcome-block-card-noactive'
})

const props = defineProps({
    modelValue: {
        default: 'employee',
    }
})

const emit = defineEmits([
    'update:modelValue'
])

const mode = ref(props.modelValue)

watch(() => props.modelValue, (newValue) => {
    mode.value = newValue
})
watch(() => mode.value, (newValue, oldValue) => {
    if (newValue === props.mode) {
        return
    }
    emit('update:modelValue', mode.value)
})

const setMode = (newMode) => {
    mode.value = newMode
}

</script>

<style scoped>
.transition-height-width {
    transition: height 0.5s ease, width 0.5s ease;
}

.welcome-block-card {
    @apply flex flex-col items-center;
}

.welcome-block-card-active {
    @apply welcome-block-card;
    @apply transition-height-width;
    @apply px-[16px] py-[26.5px] gap-[24px] h-[430px] w-[340px];
}

.welcome-block-card-noactive {
    @apply welcome-block-card;
    @apply transition-height-width;
    @apply p-[16px] gap-[16px] h-[365px] w-[246px];
}

/* .welcome-block-card {
    height: 365px;
    width: 256px;
    transition: height 0.5s ease, width 0.5s ease;
    padding: 0;
    padding-left: 16px;       
    padding-right: 16px;          
    padding-top: 16px;            
    padding-bottom: 16px;         
    display: flex;                 
    flex-direction: column;        
    align-items: center;           
    gap: 16px;
} */

/* .welcome-block-card:hover {
    height: 430px;
    width: 340px;
    padding-top: 26px;            
    padding-bottom: 26px; 
    gap: 24px;
} */



.advantages {
    @apply flex flex-col gap-y-3;
}

.shadow {
    box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
}

.advantages-mobile {
    @apply shadow;
    @apply flex flex-col gap-y-[8px] p-[0.75rem] bg-white rounded-[12px] w-[10.43rem] h-[76px];
}

.advantage {
    @apply text-[12px] leading-[14.7px] font-light flex flex-row items-center gap-x-1;
}

.advantage-mobile {
    @apply text-[10px] leading-[12.2px] font-light flex flex-row items-center gap-x-[0.5rem];
}

.advantage-circle {
    @apply w-2.5 h-2.5 rounded-full bg-[#01B00A];
}
</style>
