<template>
    <div class="flex flex-row shadow md:w-[446px] md:h-[235px] w-[17.875rem] h-[9.75rem] bg-white rounded-[24px]">
        <div class="md:w-[26px] w-[24px] h-full bg-[#01B00A] rounded-l-[24px] flex items-center justify-center">
            <div class="vertical-text text-white text-[14px] leading-[17.12px]">Студент</div>
        </div>
        <div class="flex flex-col w-full md:p-[16px] p-[16px] pl-[8px]">
            <div class="flex flex-row md:gap-x-[20px] gap-x-[8px]">
                <div v-if="user.avatar && !mobile.isMobile.value" class="">
                    <img :src="`${baseURL}/files/${user.avatar}/download`"
                        class="md:!w-[80px] md:!h-[80px] !h-[2.75rem] !w-[2.75rem] object-cover rounded-full">
                </div>

                <div v-if="!user.avatar && !mobile.isMobile.value">
                    <UIIcon size="80" name="personCircled"></UIIcon>
                </div>


                <div class="md:text-[20px] md:leading-[24.46px] text-[14px] leading-[17.12px] font-medium flex-1">
                    Инженер технологического университета
                </div>
            </div>

            <div class="flex flex-row justify-between md:mt-[8px] mt-[4px]">
                <div class="font-medium md:text-[16px] md:leading-[19.2px] text-[12px] leading-[14.68px]">ВУЗ</div>
                <div class="font-medium md:text-[16px] md:leading-[19.2px] text-[12px] leading-[14.68px]">УГНТУ</div>
            </div>
            <div class="flex flex-row justify-between mt-[4px]">
                <div class="md:text-[14px] md:leading-[17.12px] md:font-normal font-light text-[10px] leading-[12.23px]">Период обучения</div>
                <div class="md:text-[14px] md:leading-[17.12px] md:font-normal font-light text-[10px] leading-[12.23px]">до 2027</div>
            </div>
            <div class="md:text-[14px] md:leading-[17.12px] font-medium md:h-[17.12px]  md:mt-[8px] mt-[4px] h-[14.68px] text-[12px] leading-[14.68px]">{{ user.city_of_residence }}</div>
            <div class="flex flex-row h-full justify-between items-end">
                <div class="md:text-[12px] md:leading-[13px] text-[10px] leading-[12.22px] font-light text-[#7B7A7A]">сегодня в 11:31</div>
                <ButtonNew class="md:!text-[14px] text-[12px] !rounded-[12px] md:!h-[40px] h-[32px] !w-[150px]">Пригласить</ButtonNew>
            </div>
        </div>

    </div>
</template>
<script setup>
import { defineProps } from "vue"
import UIIcon from "../ui/UIIcon.vue";
import { useFetch } from "@/composables/useFetch";
import { useMobile } from "@/composables/useMobile";
import ButtonNew from "../ui/ButtonNew.vue";

const mobile = useMobile()
const { baseURL } = useFetch();

const props = defineProps({
    user: {
        default: null
    },
});
</script>
<style scoped>
.shadow {
    box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
}

.vertical-text {
    writing-mode: vertical-rl;
    /* Вертикальная ориентация текста */
    transform: rotate(180deg);
    /* Поворачиваем текст для начала снизу */
    text-align: center;
    /* Выравнивание текста по центру */
    margin: 0;
    /* Отмена отступов по умолчанию */
}
</style>