<template>
    <div class="flex flex-row items-center gap-x-[8px] cursor-pointer">
        <div @click="isChecked = !isChecked"
            class="relative border-[2px] border-[#0A5CD5] w-[28px] h-[18px] rounded-[60px]">
            <div v-if="modelValue" class="absolute w-[14px] h-[14px] bg-[#0A5CD5] rounded-full"></div>
            <div v-else class="absolute right-0 w-[14px] h-[14px] bg-[#0A5CD5] rounded-full"></div>
        </div>
        <span v-if="modelValue"  class="text-[12px] leading-[14.7px] font-light">{{ trueLabel }}</span>
        <span v-else  class="text-[12px] leading-[14.7px] font-light">{{ falseLabel }}</span>
        <!-- {{ modelValue }} -->
    </div>

</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    trueLabel: {
        default: 'true'
    },
    falseLabel: {
        default: 'false'
    },
    trueColor: {
        default: '#0A5CD5'
    },
    falseColor: {
        default: '#0A5CD5'
    }
})

const emit = defineEmits(['update:modelValue'])

const isChecked = ref(props.modelValue)


watch(() => props.modelValue, (newValue) => {
    isChecked.value = newValue
    // skip = true
})
watch(() => isChecked.value, (newValue, oldValue) => {
    // if (skip) {
    //   skip = false
    //   return
    // }
    if (newValue === props.modelValue) {
        return
    }
    emit('update:modelValue', newValue)
})
</script>