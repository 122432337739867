<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.4723 2.34963C12.8064 1.21679 11.1982 1.21679 10.5324 2.34963L0.246939 19.8496C-0.43861 21.0161 0.384418 22.5 1.71689 22.5H22.2877C23.6202 22.5 24.4432 21.0161 23.7577 19.8496L13.4723 2.34963ZM12 7.5C12.8031 7.5 13.4307 8.19343 13.3507 8.99256L12.8246 14.2537C12.7823 14.6774 12.4258 15 12 15C11.5742 15 11.2177 14.6774 11.1754 14.2537L10.6493 8.99256C10.5693 8.19343 11.1969 7.5 12 7.5ZM12.0023 16.5C12.8307 16.5 13.5023 17.1716 13.5023 18C13.5023 18.8284 12.8307 19.5 12.0023 19.5C11.1739 19.5 10.5023 18.8284 10.5023 18C10.5023 17.1716 11.1739 16.5 12.0023 16.5Z"
            fill="#FF3B30" />
    </svg>

</template>

<script setup>

</script>

<style scoped></style>