<template>
  <Card class="md:min-w-[486px] bg-white cursor-pointer p-[12px] md:p-[24px]" @click="router.push(`/employer/resumes/${resume.id}/`)">
    <template #header>
      <div class="flex flex-col md:mb-[16px] w-full">
        <div class="flex flex-row justify-between">
          <div class="flex flex-col">
            <div class="md:text-[1.25rem] md:leading-[1.53rem] text-[1rem] leading-[1.23rem] mb-[4px]
                    font-medium w-full
                    md:h-[48px]
                    h-[40[px]
                    !overflow-ellipsis">{{ resume.speciality_name }}</div>
            <div v-if="resume.salary_expectations" class="text-[14px] md:text-[16px]">от {{ formatPrice(resume.salary_expectations) }} рублей</div>
            <div v-else class="text-[14px] md:text-[16px]">Договорная</div>
          </div>
<!--          <div v-if="resume.employee_avatar" :class="avatarClass">-->
<!--            <img :src="`${baseURL}/files/${resume.employee_avatar}/download`" class="md:w-[80px] md:h-[80px] h-[2.75rem] w-[2.75rem] object-fill rounded-full">-->
<!--          </div>-->
          <Avatar v-if="resume.employee_avatar" :image-id="resume.employee_avatar" size="80"></Avatar>
          
          <div v-else-if="!mobile.isMobile.value"><UIIcon size="80" name="personCircled"></UIIcon></div>
          <div v-else-if="mobile.isMobile.value"><UIIcon size="44" name="personCircled"></UIIcon></div>
        </div>


        <div class="mt-[16px]">
          <div class="flex justify-between items-center w-full">
            <div class="text-[#1F1E1E] md:font-bold font-medium md:text-[16px] md:leading-[19.57px] text-[14px] leading-[17.12px]">Опыт работы</div>
            <div v-if="resume.work_experience" class="text-green-500">{{ formatAge(resume.work_experience) }}</div>
            <div v-else class="not-set">не указан</div>
          </div>
          <div class="flex flex-row justify-between items-center w-full">
            <div class="text-[14px] leading-[17.12px] md:text-[16px] md:leading-[17.57px]">Последнее место работы</div>
            <div class="text-[14px] leading-[17.12px] md:text-[16px] md:leading-[17.57px]" v-if="resume.last_job_info"> {{ resume.last_job_info.company_name }}</div>
            <div v-else class="not-set">не указано</div>
          </div>
        </div>

        <div class="mt-[12px] text-[14px] leading-[17.2px] font-medium mb-[4px]">{{ resume.user_city }}</div>
        <div v-if="resume.full_years">{{ formatAge(resume.full_years) }}</div>
        <div v-else class="not-set">Возраст скрыт</div>
        <!--        <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)"-->
        <!--             class="header-price">От {{ formatPrice(priceFrom)-->
        <!--          }} {{ currencyName }} до {{ formatPrice(priceTo) }} {{ currencyName }}</div>-->
        <!--        <div v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)"-->
        <!--             class="header-price">{{ formatPrice(priceFrom) }}-->
        <!--          {{ currencyName }}</div>-->
        <!--        <div v-else-if="(priceFrom)" class="header-price">От {{-->
        <!--            formatPrice(priceFrom) }} {{ currencyName }}</div>-->
        <!--        <div v-else-if="(priceTo)" class="header-price">От {{-->
        <!--            formatPrice(priceTo) }} {{ currencyName }}</div>-->
        <!--        <div v-else class="md:mb-[20px] mb-[1.25rem]"></div>-->
      </div>

    </template>
    <template #sub-action>
      <!-- <div>
        <BoolIcon name="bookmark-gold" :checked="checked" @click.stop="checked = !checked"></BoolIcon>
      </div> -->
    </template>

    <template #body></template>
    <!--    <template #body>-->
    <!--      <div class="flex flex-col md:mb-[16px] mb-[0.75rem]">-->
    <!--        <div v-if="city" class="md:text-[16px] md:leading-[19.6px] md:mb-[4px] text-[1rem] leading-[1.2rem] font-bold">{{ city }}</div>-->
    <!--        <div v-else class="md:mb-[23.6px]"></div>-->
    <!--        <div v-if="experience" class="md:text-[16px] md:leading-[19.6px] md:font-normal font-light text-[0.75rem] leading-[0.86rem] text-[#01B00A]">{{ formatexperienceForVacancy(experience) }}</div>-->
    <!--        <div v-else class="md:mb-[19.6px]"></div>-->
    <!--      </div>-->
    <!--    </template>-->
    <template #footer>
      <div></div>
      <!--      <div class="font-light text-[#7B7A7A] md:text-[12px] md:leading-[14.7px] text-[0.75rem] leading-[0.86rem]">{{ formatDateTime(publicationTime) }}</div>-->
    </template>
    <template #action>
      <div class="flex justify-between items-end w-full">
        <div class="text-[0.75rem] leading-[0.9375rem]  text-[#7B7A7A]">
          {{ formatDateTime(resume.updated_at) }}
        </div>
        <div class="md:w-[149px] w-[9.3rem]">

          <ButtonNew v-if="!authStore.isAuthenticated || authStore.role === 'employer'" @click.stop="handleInvintationClick()" class="!h-[40px]">Пригласить</ButtonNew>
        </div>
      </div>

    </template>
  </Card>
</template>

<script setup>

import { defineProps, ref, computed } from "vue";
import Card from "@/components/cards/Card";
import BoolIcon from "@/components/ui/BoolIcon";
import { formatDateTime, formatPrice, formatAge } from "@/composables/utils";
import ButtonNew from "@/components/ui/ButtonNew";
import { usePopupsStore } from "@/stores/popupsStore";
import { useRouter, useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useFetch } from "@/composables/useFetch";
import UIIcon from "../ui/UIIcon.vue";
const { baseURL } = useFetch();

const route = useRoute();

const mobile = useMobile();

const avatarClass = computed(()=> {
  if (!authStore.isAuthenticated)
    return 'blur-[4px] mt-[5px] mr-[5px]'
  else return ''
})


const checked = ref(false)

const popupsStore = usePopupsStore()

const handleInvintationClick = () => {
  if (route.path === '/employe/resumes') {
    sendMetrick('EMPLOYER-PAGE-RESUME-INVINTATION')
  }
  popupsStore.openInvitationPopup(props.resume.id, router)
}

const props = defineProps({
  resume: {
    type: Object,
    default: null,
  },

});

const router = useRouter()
const authStore = useAuthStore()
const fetch = useFetch()
import { useToast } from 'vue-toast-notification';
import { useMobile } from "@/composables/useMobile";
import Avatar from "@/components/ui/Avatar";

const toast = useToast()


</script>

<style scoped>
.not-set {
  @apply text-[0.875rem] leading-[1.07rem] text-[#7B7A7A]
}
</style>