<template>
  <div :class="computedClasses">
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';

const standardClass = "bg-[#FFFFFF] rounded-[0.85rem] p-[1.7rem] md:p-[24px] md:rounded-[12px] w-full h-full sheet ";
const props = defineProps({
  class: {
    type: String,
    default: ''
  },
});

const computedClasses = computed(() => {
  return standardClass + ' ' + props.class;
});
</script>

<style scoped>
/* Дополнительные стили, если нужны */
.sheet {
  box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
  border: 1px solid transparent; /* Начальная рамка прозрачная */
}

.sheet:hover {
  border: 1px solid #0A5CD5; /* Начальная рамка прозрачная */
}
</style>