<template>
    <popup @close="back()" :class="[
        mobile.isMobile.value ? '' : '!w-[600px]'
    ]">
        <div>

            <div
                class="mt-[32px] md:mt-0 mb-[12px] font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] text-center md:text-left md:leading-[1.8125rem] text-black">
                У вас уже опубликовано резюме
            </div>
            <div class="text-center text-[#1F1E1E] text-[1rem] leading-[1.25rem] font-normal max-w-[500px]">
                Если вы опубликуете это резюме, ваше самое старое резюме будет автоматически перенесено в Не
                опубликованные
            </div>

            <div class="flex flex-row gap-x-[12px]">
                <ButtonNew type="stroke" class="mt-[40px] whitespace-nowrap p-[14px_16px] !w-[50%]" @click="back()">
                    назад</ButtonNew>
                <ButtonNew class="mt-[40px] whitespace-nowrap p-[14px_16px] !w-[50%]" @click="okay()">
                    Опубликовать</ButtonNew>
                




            </div>
        </div>
    </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import { defineProps, defineEmits } from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import { usePopupsStore } from "@/stores/popupsStore";
import { useAuthStore } from "@/stores/authStore";
import { useMobile } from "@/composables/useMobile";

const authStore = useAuthStore()

const mobile = useMobile()
const popupsStore = usePopupsStore()

const props = defineProps({
    modelValue: {
        default: false,
    }
})

const emit = defineEmits([
    'update:modelValue'
])
const okay = async () => {

  popupsStore.toManyResumes.doPublish = true
  emit('update:modelValue', false)
};

const back = async () => {

  popupsStore.toManyResumes.doPublish = false
  emit('update:modelValue', false)
};
</script>

<style scoped></style>