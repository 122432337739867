import { defineStore } from 'pinia';

export const useStaticStore = defineStore('static', {
  state: () => ({
    support: {
        phone: '+7 968 920 94 08 ',
        email: 'info@waterjob.ru',
        telegram: 'BVVteh',
        whatsapp: '+79689209408',
    },
    adress: 'г. Москва, ул. Рябиновая 26/1, БЦ West Plaza, 9 этаж '
  }),
});