<template>
  <popup @close="emit('update:modelValue', false)" v-if="fetched">
    <div>

      <div
        class="mt-[32px] md:mt-0 mb-[20px] md:mb-[24px] font-medium text-[0.87rem] md:text-[1rem] leading-[1rem] text-center md:leading-[1.25rem] text-black w-full">
        Сопроводительное письмо выделит вас среди соискателей</div>
      <UITextArea maxCharacters="2000" v-model="popupsStore.response.message" :errorText="errorText"
        placeholder="Напишите работодателю, почему вы хотите работать у них"
        textAreaClass="font-light text-[12px] leading-4 text-gray-500 md:min-h-[110px] min-h-[70px] md:max-h-[55vh] max-h-[40vh]"></UITextArea>
      <div class="flex justify-end">
        <div class="flex flex-col md:flex-row gap-[16px] mt-[40px] w-full">
          <UISelectNew :options="resumes" placeholder="Выберите резюме" triggerClass="md:h-[48px] h-[40px]"
            v-model="resume" ></UISelectNew>
          <!-- <ButtonNew type="stroke" class="w-fit whitespace-nowrap p-[14px_16px]" @click="send()">Откликнуться без письма</ButtonNew> -->
          <ButtonNew v-if="!errorText" class="w-fit p-[14px_16px] md:h-[48px] h-[40px]" @click="doResponse()">Откликнуться </ButtonNew>
          <ButtonNew v-else type="stroke-disabled" class="w-fit p-[14px_16px] md:h-[48px] h-[40px]">Откликнуться</ButtonNew>
          <!-- {{ resume }} -->
        </div>


      </div>
    </div>
  </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import { defineProps, defineEmits, ref, onBeforeMount, watch } from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import { usePopupsStore } from "@/stores/popupsStore";
import { useAuthStore } from "@/stores/authStore";
import UISelect from "../ui/UISelect.vue";
import UISelectNew from "../ui/UISelectNew.vue";
import { useFetch } from "@/composables/useFetch";
const fetch = useFetch();
const authStore = useAuthStore()

const popupsStore = usePopupsStore()



const errorText = ref(null)

watch(() => popupsStore.response.message, (value) => {
  if (value.length > 2000) {
    errorText.value = 'Превышено допустимое количество символов'
  } else errorText.value = null

})

// watch(popupsStore.response.message, (newValue) => {
//   console.log('watch', newValue.length)
//   if (newValue.length > 20)
//     console.log('afasfsafasfasfasfsafasfasfasfsafasfasfas')
//     errorText.value = 'Превышено допустимое количество символов'
// }, {deep: true})

const resumes = ref(null)
const fetched = ref(false)
const resume = ref(null)

onBeforeMount(() => {
  fetch.authRequest('/account/resumes/', {
    method: 'GET',
    params: {
      status__in: 'PUBLISHED',
    }
  }).then((data) => {
    resumes.value = data.results.map(obj => ({ title: obj.speciality_name, value: obj.id }))

    // console.log('reassdfadf', resumes.value.length)
    if (resumes.value.length === 1) {

      resume.value = resumes.value[0].value
      // console.log('reassdfad123123123412', resume.value)
    }
    resumes.value.push({ title: 'Не прикреплять резюме', value: null })
    fetched.value = true

  })
})



const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue'
])

const doResponse = () => {
  if (popupsStore.response.callback) {
    popupsStore.response.resume = resume.value
    popupsStore.response.callback()
    popupsStore.response.visible = false
  }
}
</script>

<style scoped></style>