<template>
  <ActionSheet @close="emit('update:modelValue', false)">
    <div>
      <div class="font-bold text-[1.25rem] leading-[1.5rem] text-black">Фильтр</div>

      <div class="flex flex-col">
        <div class="filter-menu-item">Регион</div>
        <div class="mb-[20px]">
          <Input placeholder="Введите название города" inputClass=" !h-[41px]" v-model="inputRegion"
            outlineColor="#0A5CD5"></Input>
        </div>

        <UICheckboxList :options="filteredRegions" v-model="actionSheets.filters.filterValues.regions">

        </UICheckboxList>

        <div class="filter-menu-item">Тип занятости</div>
        <CheckboxesList :items=jobTypes v-model="actionSheets.filters.filterValues.jobTypes">
        </CheckboxesList>

        <div class="filter-menu-item">График работы</div>
        <CheckboxesList :items=jobShedules v-model="actionSheets.filters.filterValues.jobShedules">
        </CheckboxesList>

        <div class="filter-menu-item">Заработная плата</div>
        <div class="flex flex-col gap-[12px] whitespace-nowrap">
          <Input type="number" inputClass=" !h-[41px]" placeholder="от"
            v-model="actionSheets.filters.filterValues.priceFrom" outlineColor="#0A5CD5"></Input>
          <Input type="number" inputClass=" !h-[41px]" placeholder="до"
            v-model="actionSheets.filters.filterValues.priceTo" outlineColor="#0A5CD5"></Input>
        </div>

        <div class="filter-menu-item">Образование</div>
        <CheckboxesList :items=educations v-model="actionSheets.filters.filterValues.educations">
        </CheckboxesList>

        <div class="filter-menu-item">Опыт работы</div>
        <CheckboxesList :items=jobExperiences v-model="actionSheets.filters.filterValues.jobExperiences">
        </CheckboxesList>
      </div>


      <div class="mt-[40px] flex justify-end">
        <div>
          <ButtonNew type="stroke" class="w-fit p-[14px_16px]" @click="actionSheets.filters.confirmCallback">Готово
          </ButtonNew>

        </div>
      </div>
    </div>
  </ActionSheet>

</template>

<script setup>

import ActionSheet from "@/components/ui/ActionSheet";
import { defineEmits, defineProps, ref, watch, onMounted } from "vue";
import ButtonNew from "@/components/ui/ButtonNew";
import { useActionSheetsStore } from "@/stores/actionSheets";
import CheckboxesList from './../../components/ui/CheckboxesList.vue';
import Input from "../ui/Input.vue";
import { useFetch } from '@/composables/useFetch';
import UICheckboxList from "../ui/UICheckboxList.vue";

const { authRequest, anonRequest } = useFetch();

const inputRegion = ref(null)

const jobTypes = ref(['Полная занятость', 'Частичная занятость', 'Проектная работа', 'Стажировка'])
const jobShedules = ref(['Полный день', 'Сменный график', 'Удаленная работа', 'Гибкий график'])
const jobExperiences = ref(['Нет опыта', 'От 1 года до 3 лет', 'От 3 до 6 лет', 'Более 6 лет'])
const educations = ref(['Высшее', 'Среднее', 'Неполное среднее', 'Нет образования', 'Не указано'])
const jobTypesModel = ref([])
const regionsModel = ref([])
const jobShedulesModel = ref([])
const jobExperiencesModel = ref([])
const educationsModel = ref([])
const filtersModel = ref({
  'jobTypes': [],
  'cities': [],
  'jobShedules': [],
  'jobExperiences': [],
  'educations': [],
  'priceFrom': null,
  'priceTo': null,
})

const regions = ref([])
const filteredRegions = ref([])
const regionsWithCities = ref({})

const inputPrice = ref(null)

const actionSheets = useActionSheetsStore()

const props = defineProps({
  modelValue: {
    default: false,
  }
})


const error = ref(null)

onMounted(() => {
  // regionsModel.value = actionSheets.filters.filterValues.cities
  fetchRegionsData();
});

const fetchRegionsData = async () => {
  error.value = null; // Сбрасываем предыдущую ошибку

  try {
    const url = `references/regions-cities/`;

    const response = await anonRequest(url, {
      method: 'GET',
    });

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      regions.value = Object.entries(response.results).map(([title, cities]) => ({
        title,
        value: title,
        cities
      }));
      filteredRegions.value = regions.value
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
  }
};

const emit = defineEmits(['update:modelValue', 'clearFilters']);


watch(inputRegion, (newValue) => {
  filteredRegions.value = regions.value.filter(region =>
    region.cities.some(city =>
      city.toLowerCase().includes(newValue.toLowerCase())
    )
  );

})


// watch(filtersModel, (newValue) => {
//   actionSheets.filters.filterValues = filtersModel.value
// }, { deep: true })

// watch(jobTypesModel, (newValue) => {
//   filtersModel.value.jobTypes = newValue
// }, { deep: true })

// watch(regionsModel, (newValue) => {
//   let regionsArray = []
//   newValue.forEach((element, index) => {
//     if (element === true) {
//       regionsArray.push(filteredRegions.value[index])
//     }
//   })
//   filtersModel.value.cities = regionsArray
// }, { deep: true })

// watch(jobShedulesModel, (newValue) => {
//   filtersModel.value.jobShedules = newValue
// }, { deep: true })

// watch(jobExperiencesModel, (newValue) => {
//   filtersModel.value.jobExperiences = newValue
// }, { deep: true })

// watch(inputPrice, (newValue) => {
//   filtersModel.value.price = newValue
// }, { deep: true })

// watch(educationsModel, (newValue) => {
//   filtersModel.value.educations = newValue
// }, { deep: true })
</script>

<style scoped>
.filter-menu-item {
  @apply font-bold mt-[30px] mb-[20px]
}
</style>