<template>
  <div class="pagination h-[44px] flex flex-row gap-[24px] text-base leading-5 text-[#7B7A7A] font-normal">
    <button @click="onPrevPage" :disabled="isFirstPage">Назад</button>
    <span
      v-for="page in pages"
      :key="page"
      :class="{'active': isActivePage(page)}"
      @click="onPageClick(page)"
    >
      {{ page }}
    </span>
    <button @click="onNextPage" :disabled="isLastPage">Далее</button>
  </div>
</template>

<script setup>
import { sendMetrick } from '@/composables/utils';
import router from '@/router';
import {ref, watch, defineEmits, defineProps, computed} from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute()

// const route = useRoute();

const props = defineProps({
  totalPages: {
    type: Number,
    required: true
  },
  currentPage: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['page-changed']);

const pages = ref([]);
const maxVisiblePages = 7;

const generatePages = () => {
  if (props.totalPages <= maxVisiblePages) {
    pages.value = Array.from({ length: props.totalPages }, (_, i) => i + 1);
  } else {
    const startPage = Math.max(1, props.currentPage - 3);
    const endPage = Math.min(props.totalPages, startPage + maxVisiblePages - 1);
    pages.value = [];

    for (let i = startPage; i <= endPage; i++) {
      pages.value.push(i);
    }

    if (startPage > 1) {
      pages.value.unshift('...');
    }

    if (endPage < props.totalPages) {
      pages.value.push('...');
    }

    if (startPage === 1 && endPage < props.totalPages) {
      pages.value.push(props.totalPages);
    }
  }
};

const isActivePage = (page) => {
  return page === props.currentPage;
};

const onPageClick = (page) => {
  if (typeof page === 'number' && page !== props.currentPage) {
    emit('page-changed', page);
  }
};

const isFirstPage = computed(() => {
  return props.currentPage === 1
});
const isLastPage = computed(() => {
  return props.currentPage === props.totalPages
});

const onPrevPage = () => {
  if (!isFirstPage.value) {
    emit('page-changed', Math.max(props.currentPage - 1, 1));
  }
};

const onNextPage = () => {
  if (route.path === '/employee/vacancies') {
    sendMetrick('EMPLOYEE-PAGE-PAGINATION')
  }
  else if (route.path === '/employer/resumes') {
    sendMetrick('EMPLOYER-PAGE-PAGINATION')
  }


  if (!isLastPage.value) {
    emit('page-changed', Math.min(props.currentPage + 1, props.totalPages));
  }
};

watch(() => [props.totalPages, props.currentPage], generatePages);

generatePages();
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination button {
  cursor: pointer;
}

.pagination span {
  cursor: pointer;
  color: #7B7A7A;
}

.pagination span.active {
  color: #0A5CD5;
  font-weight: bold;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
