<template>
    <!--    <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>-->
    <DefaultLayout>

        <authTemplateCard>
            <!--        <div class="flex flex-row w-full">-->
            <!--            <div class="flex flex-row items-center relative w-full md:mb-[32px] mb-[2.9rem]">-->
            <!--                <div class="absolute left-0">-->
            <!--                    <Icon @click="router.back()" v-if="isMobile" name="chevron-left-back-mobile"></Icon>-->
            <!--                </div>-->
            <!--                <div class="flex flex-row justify-center w-full">-->
            <!--                    <div v-if="isMobile" class="registration-header">Я соискатель</div>-->
            <!--                    <div v-else class="registration-header">Регистрация</div>-->
            <!--                </div>-->

            <!--            </div>-->
            <!--        </div>-->
            <UIPageHeader class="md:hidden mb-[32px]" title="Я соискатель">
                <template #search>
                    <div></div>
                </template>
            </UIPageHeader>
            <div class="hidden md:block mb-[32px] registration-header ">Регистрация</div>

            <Input inputClass=" md:!p-[16px_16px] p-[1rem_1rem]" class="mb-[12px]" type="text"
                :errorText="formValidator.errors.first_name" v-model="registrationForm.first_name"
                placeholder="Имя"></Input>
            <Input inputClass=" md:!p-[16px_16px] p-[1rem_1rem]" class="mb-[12px]" type="text"
                :errorText="formValidator.errors.last_name" v-model="registrationForm.last_name"
                placeholder="Фамилия"></Input>

            <Input inputClass=" md:!p-[16px_16px] p-[1rem_1rem]" class="mb-[12px]" type="email" placeholder="Электронная почта"
                :errorText="formValidator.errors.email" v-model="registrationForm.email"></Input>
            <Input inputClass=" md:!p-[16px_16px] p-[1rem_1rem]" class="mb-[12px]" type="tel" placeholder="Телефон"
                :errorText="formValidator.errors.phone_number" v-model="registrationForm.phone_number"></Input>
            <Input inputClass=" md:!p-[16px_16px] p-[1rem_1rem]" class="mb-[12px]" type="password" placeholder="Пароль"
                :errorText="formValidator.errors.password" v-model="registrationForm.password"></Input>
            <Input inputClass=" md:!p-[16px_16px] p-[1rem_1rem]" class="mb-[12px]" type="password" placeholder="Повторите пароль"
                :errorText="formValidator.errors.repeat_password" v-model="registrationForm.repeat_password"></Input>
            <ButtonNew type="blue-medium" @click="formValidator.handleSubmit(registration)" class="md:mt-[32px] mt-[1.7rem]">Зарегистрироваться
            </ButtonNew>
            <div
                class="flex flex-row justify-between items-center w-[14.37rem] md:w-[260px] md:h-[44px] md:mt-[32px] h-[2.7rem] mt-[2rem]">
                <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem]">
                    У меня есть аккаунт.
                </div>
                <div @click="router.push('/login')"
                    class="md:text-[16px] md:leading-[19.6px] text-[1rem] font-bold text-[#0A5CD5] cursor-pointer">
                    Войти
                </div>
            </div>

            <SupportCardNew></SupportCardNew>

        </authTemplateCard>
    </DefaultLayout>

    <!--  <FooterNew></FooterNew>-->

</template>

<script setup>
import ButtonNew from '@/components/ui/ButtonNew.vue';
import authTemplateCard from '@/components/cards/authTemplateCard.vue';
import Icon from '@/components/ui/Icon.vue';
import { ref, reactive, watch, onUnmounted, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderNew from '@/components/ui/HeaderNew.vue';
import FooterNew from '@/components/ui/FooterNew.vue';
import Input from '@/components/ui/Input.vue';
import router from '@/router';
import { useFetch } from '@/composables/useFetch';
import SupportCardNew from '@/components/cards/SupportCardNew.vue';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIPageHeader from "@/components/ui/UIPageHeader";
import { usePopupsStore } from '@/stores/popupsStore';
import { useFormValidator } from "@/composables/useFormValidator";
import { useValidateFuncs } from "@/composables/useValidateFuncs";
import { sendMetrick } from '@/composables/utils';
import { useToast } from 'vue-toast-notification';
const toast = useToast();

const validatorFuncs = useValidateFuncs();

const { anonRequest, authRequest } = useFetch();

const popups = usePopupsStore()

const registrationForm = reactive({
    type: 'employee',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    repeat_password: '',
})

const formValidator = useFormValidator(
    registrationForm,
    {
        'first_name': [validatorFuncs.isNotEmpty, ],
        'last_name': [validatorFuncs.isNotEmpty],
        'email': [validatorFuncs.isNotEmpty, validatorFuncs.isValidEmail, validatorFuncs.isEmailAvailable],
        'phone_number': [validatorFuncs.isNotEmpty, validatorFuncs.isValidPhoneNumber],
        'password': [validatorFuncs.isNotEmpty, (value) => validatorFuncs.isMinimalLen(value, 6), (value) => validatorFuncs.isEqual(value, registrationForm.repeat_password)],
        'repeat_password': [validatorFuncs.isNotEmpty, (value) => validatorFuncs.isMinimalLen(value, 6), (value) => validatorFuncs.isEqual(value, registrationForm.password)]
    }
)

const fetchSignup = async () => {

    const url = `/signup/`;
    const response = await anonRequest(url, {
        method: 'POST',
        body: registrationForm
    });
    return response
};

const registration = () => {
    fetchSignup().then(result => {
        if (!result.status) {
            sendMetrick('REGISTER')
            router.push('/login')
            popups.openInfo(
                'Проверьте почту',
                'Подвердите адрес электронной почты. Для этого пройдите по ссылке, указанной в письме.',
            )
        } else {
            toast.error('Что то пошло не так')
        }
    })

}

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});


const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style></style>