<template>
  <!-- Контейнер для анимации.
       Слот используется для передачи содержимого, к которому применяется анимация.
       Элемент получает ссылку ref="animatedElement", что позволяет наблюдать за ним -->
  <div class="opacity-animation" ref="animatedElement">
    <slot>
      <!-- Если слот не заполнен, по умолчанию выводится этот текст -->
      тут будет какая-то анимация
    </slot>
  </div>
</template>

<script setup>
// Импортируем необходимые функции из Vue
import {onMounted, ref} from "vue";

// Определяем входные свойства компонента
const props = defineProps({
  duration: {
    type: String,
    default: '1s', // Продолжительность анимации по умолчанию: 1 секунда
  },
  delay: {
    type: String,
    default: '0s', // Задержка перед началом анимации по умолчанию: 0 секунд
  },
});

// Создаем реактивную ссылку на элемент, который будет анимироваться
const animatedElement = ref(null);

// Создаем наблюдатель за пересечением (Intersection Observer)
// Он будет отслеживать, когда элемент появляется в области видимости
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    // Если элемент виден, добавляем к нему класс fade-in для начала анимации
    if (entry.isIntersecting) {
      entry.target.classList.add('fade-in');
      // После срабатывания анимации прекращаем наблюдение за этим элементом
      observer.unobserve(entry.target);
    }
  });
});

// Запускаем наблюдение за элементом после монтирования компонента
onMounted(() => {
  if (animatedElement.value) {
    observer.observe(animatedElement.value);
  }
});
</script>

<style scoped>
/* Исходное состояние элемента:
   - opacity: 0 (скрыто)
   - transition: плавный переход для свойств opacity и transform,
     с параметрами duration и delay, которые передаются через props (см. v-bind) */
.opacity-animation {
  opacity: 0;
  /* Пример исходного смещения (закомментирован):
     transform: translateY(20px); */
  transition: opacity v-bind(duration) ease, transform v-bind(duration) ease;
  transform: translateY(20px);
  transition-delay: v-bind(delay);
}

/* Класс, добавляемый при появлении элемента в зоне видимости.
   Анимация делает элемент полностью видимым и возвращает его в исходное положение */
.fade-in {
  opacity: 1;
  transform: translateY(0);
}
</style>