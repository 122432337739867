<template>
  <UISelect
    :options="options"
    :disabled="props.disabled"
  >
    <template #prepend-dropdown>
      <span></span>
    </template>
  </UISelect>
</template>

<script setup>

import UISelect from "@/components/ui/UISelect";

const props = defineProps({
  disabled: {
    default: false
  }
})

const options = []
for (let year = 2025; year >= 1970; year--) {
  options.push({
    title: year,
    value: year,
  })
}
</script>

<style>

</style>