<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.9219 1.51252L14.0017 0L11.0815 1.51252L7.82997 2.00532L6.35651 4.94543L4.01562 7.25532L4.55172 10.5L4.01562 13.7447L6.35651 16.0546L7.82997 18.9947L11.0815 19.4875L14.0017 21L16.9219 19.4875L20.1735 18.9947L21.6469 16.0546L23.9878 13.7447L23.4517 10.5L23.9878 7.25532L21.6469 4.94543L20.1735 2.00532L16.9219 1.51252ZM19.0151 3.59974L20.212 5.988L22.1135 7.86433L21.678 10.5L22.1135 13.1357L20.212 15.012L19.0151 17.4003L16.3738 17.8006L14.0017 19.0292L11.6296 17.8006L8.98838 17.4003L7.79148 15.012L5.88997 13.1357L6.32544 10.5L5.88997 7.86433L7.79148 5.988L8.98838 3.59974L11.6296 3.19943L14.0017 1.97081L16.3738 3.19943L19.0151 3.59974Z"
            fill="white" />
        <path
            d="M7.00172 20.6392V28L14.0017 26.25L21.0017 28V20.6392L17.47 21.1744L14.0017 22.9708L10.5334 21.1744L7.00172 20.6392Z"
            fill="white" />
    </svg>

</template>