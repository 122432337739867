<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#0B54BF" />
        <path
            d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z"
            fill="#0B54BF" />
        <path
            d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z"
            fill="#0B54BF" />
    </svg>

</template>