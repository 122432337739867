<template>
  <DefaultLayout>
    <div class="relative flex flex-row justify-center">
      <!-- <div class="relative md:w-[1700px]"> -->
      <div>
        <div v-if="globalBlur" @click="handleGlobalBlur"
          class="absolute inset-0 bg-opacity-20 bg-[#3D7CD1] overflow-hidden z-20">
        </div>

        <!-- <img v-if="!mobile.isMobile.value" src="./../assets/images/molecula.webp"
          class="absolute object-cover top-[-45px] z-0 rotate-[135deg] w-[797px] h-[750px]" />
        <img v-else src="./../assets/images/molecula.webp"
          class="absolute object-cover left-[-5.5rem] top-[39px] z-0 rotate-[180deg] w-[12.18rem] h-[11.5rem]" />
        <img v-if="!mobile.isMobile.value" src="./../assets/images/molecula.webp"
          class="absolute object-cover top-[-45px] z-0 right-[-25px] top-[-140px] w-[698px] h-[658px]" />
        <img v-else src="./../assets/images/molecula.webp"
          class="absolute object-cover right-[-5.2rem] top-[-41px] z-0 rotate-[180deg] w-[12.18rem] h-[11.5rem]" />
        <img v-if="!mobile.isMobile.value" src="./../assets/images/molecula.webp"
          class="absolute object-cover bottom-[-780px] right-[50px] z-[0] rotate-[180deg] w-[608px] h-[646px]" /> -->

        <div class="flex flex-col justify-center items-center">
          <div class="w-full z-9 md:mt-[-60px] mt-[-56px]">
            <WelcomeBlock v-model="pageMode"></WelcomeBlock>
          </div>


          <!-- <div class="block !mb-[0px]">
            <Input style="z-index: 10;" :disabled="globalBlur" v-if="isMobile" type="lupa" v-model="profession"
              @keyup.enter="handleEnter" placeholder="Поиск"></Input>
          </div> -->


          <div class="w-full flex flex-row justify-center mt-[17px] md:mt-[-30px] z-10">
            <div class="md:w-[992px] w-full h-full px-4 md:px-0 md:mb-[20px]">
              <div class="flex md:flex-row flex-col items-center gap-y-[12px] gap-x-[20px]">
                <div class="feature">
                  <UIIcon v-if="mobile.isMobile.value" size="28" name="cubes"></UIIcon>
                  <UIIcon v-else size="28" name="cubes" color="#0A5CD5"></UIIcon>
                  Отраслевая специализация
                </div>
                <div class="feature">
                  <UIIcon v-if="mobile.isMobile.value" size="28" name="hatEducation"></UIIcon>
                  <UIIcon v-else size="28" name="hatEducation" color="#0A5CD5"></UIIcon>
                  Профессиональное развитие
                </div>
                <div class="feature">
                  <UIIcon v-if="mobile.isMobile.value" size="28" name="briliant"></UIIcon>
                  <UIIcon v-else size="28" name="briliant" color="#0A5CD5"></UIIcon>
                  Уникальные предложения
                </div>
              </div>

              <div
                class="flex flex-row text-[#0A5CD5] md:text-[36px] md:leading-[44.03px] mb-[20px] md:mt-[52px] md:font-normal mt-[32px] font-medium text-[18px] leading-[22px]"
                :class="labelClass">
                {{ label }}
              </div>
              <Input outlineColor="#0A5CD5" inputClass="!h-[40px] pl-10" type="lupa" v-model="profession"
                placeholder="Введите название профессии, должности или компании" @keyup.enter="handleEnter"></Input>
            </div>
          </div>


          <!-- <div v-if="noAuthPopup" class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
           style="z-index: 20;">
        <NotAuthPopup @resetGlobalBlur="handleNoAuthPopup"></NotAuthPopup>
      </div> -->

          <div class="md:w-[992px] px-4 md:px-0 mt-[1rem] md:mt-0">
            <div class="flex md:flex-wrap md:flex-row flex-col md:justify-between w-full md:gap-y-[20px] gap-y-[1rem]">
              <a v-if="pageMode === 'employee'" class="md:w-[49%] w-full" v-for="(item, index) in vacancies"
                :key="index">
                <VacancyCardNew @globalBlur="handleNoAuthPopup" :vacancyId=item.id :vacancyName=item.name
                  vacancyNameLength=70 :employerName=item.employer_name :tax=item.price_tax
                  :employerAvatar="item.employer_avatar" :priceFrom=item.price_from :priceTo=item.price_to
                  :currencyName=item.currency_display_name :city=item.city :publicationTime=item.created_at
                  :experience=item.experience>
                </VacancyCardNew>
              </a>
              <a v-else class="md:w-[49%] w-full" v-for="(item, i) in resumes" :key="i">
                <WJResumeCard :resume="item"></WJResumeCard>
              </a>
            </div>
          </div>

          <div class="flex flex-row justify-center mt-[20px]">
            <div v-if="!authStore.isAuthenticated & !mobile.isMobile.value">
              <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                @click="router.push('/signup')">Зарегистрируйтесь</span><span>, чтобы увидеть больше
                возможностей</span>
            </div>

            <span v-else class="text-[#0A5CD5] font-medium  hover:underline cursor-pointer" @click="seeAll">Посмотреть
              все</span>
          </div>

          <div v-if="!mobile.isMobile.value" class="flex flex-col gap-y-[32px] w-[992px] md:mt-[52px]">
            <div class="flex flex-row w-full justify-between text-[#0A5CD5] text-[36px] leading-[44.03px]">
              <div>Статьи</div>
              <div>Мероприятия</div>
            </div>
            <div v-if="!authStore.isAuthenticated">
              <div v-if="pageMode === 'employee'" class="flex flex-row w-full justify-between">
                <div class="w-[48%] text-[24px]">
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                    @click="router.push('/signup')">Зарегистрируйтесь</span><span> и публикуйте свои </span>
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                    @click="router.push('/education/articles')">статьи</span>
                  <span>, чтобы больше работодателей увидело ваше резюме</span>
                </div>
                <div class="w-[48%] text-right text-[24px]">
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer text-left"
                    @click="router.push('/signup')">Зарегистрируйтесь</span><span> и посещайте все </span>
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer "
                    @click="router.push('/education/events')">мероприятия</span>
                  <span> сервиса бесплатно</span>
                </div>
              </div>
              <div v-if="pageMode === 'employer'" class="flex flex-row w-full justify-between">
                <div class="w-[48%] text-[24px]">
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                    @click="router.push('/signup')">Зарегистрируйтесь</span><span>, изучайте и публикуйте </span>
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                    @click="router.push('/education/articles')">статьи</span>
                  <span>, рассказывайте о себе или находите квалифицированных сотрудников</span>
                </div>
                <div class="w-[48%] text-right text-[24px]">
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer text-left"
                    @click="router.push('/signup')">Зарегистрируйтесь</span><span> и публикуйте </span>
                  <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer "
                    @click="router.push('/education/events')">мероприятия</span>
                  <span>, которые привлекут больше профессионалов в вашу компанию</span>
                </div>
              </div>

            </div>
            <div class="flex flex-row w-full gap-x-[20px]">
              <div class="flex flex-col gap-y-[20px] w-[49%]">
                <ArticleIndexCard v-for="article in articles.items" :article="article"></ArticleIndexCard>
              </div>
              <div class="flex flex-col gap-y-[20px] w-[49%]">
                <EventIndexCard v-for="event in events.items" :event="event"></EventIndexCard>

              </div>
            </div>
            <div class="flex flex-row justify-center w-full">
              <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                @click="router.push('/education/articles')">Посмотреть все</span>
            </div>
            <div class="flex flex-row justify-center w-full">
              <div v-if="!authStore.isAuthenticated"
                class="w-[80%] text-center mt-[20px] text-[24px] leading-[29.4px] md:mt-[40px] md:mb-[46px]">
                <div v-if="pageMode === 'employee'">
                  <span class="text-[#0A5CD5] hover:underline cursor-pointer"
                    @click="router.push('/signup')">Зарегистрируйтесь</span><span> и используйте все возможности биржи,
                    чтобы найти лучший вариант</span>
                </div>
                <div v-else>
                  <span class="text-[#0A5CD5]  hover:underline cursor-pointer"
                    @click="router.push('/signup')">Зарегистрируйтесь</span><span> и используйте все возможности биржи,
                    чтобы стать лидером рынка</span>
                </div>


              </div>
            </div>


          </div>

          <div v-else class="flex flex-col p-[1rem] gap-y-[1rem]">
            <div v-if="!authStore.isAuthenticated">
              <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer text-[1.12rem]"
                @click="router.push('/signup')">Зарегистрируйтесь</span><span> и публикуйте свои статьи, что бы больше
                работодателей увидело ваше резюме</span>
            </div>
            <ArticleIndexCard v-for="article in articles.items" :article="article"></ArticleIndexCard>
            <div class="flex flex-row justify-center w-full">
              <span class="text-[#0A5CD5] font-medium text-[14px] cursor-pointer"
                @click="router.push('/education/articles')">Посмотреть все</span>
            </div>
            <div v-if="!authStore.isAuthenticated" class="text-center">
              <span class="text-[#0A5CD5] font-medium text-center cursor-pointer text-[1.12rem]"
                @click="router.push('/signup')">Зарегистрируйтесь</span><span> и публикуйте свои статьи, что бы больше
                работодателей увидело ваше резюме</span>
            </div>
            <EventIndexCard v-for="event in events.items" :event="event"></EventIndexCard>
            <div class="flex flex-row justify-center w-full">
              <span class="text-[#0A5CD5] font-medium text-[14px] cursor-pointer"
                @click="router.push('/education/articles')">Посмотреть все</span>
            </div>
            <div v-if="!authStore.isAuthenticated" class="text-center">
              <div v-if="pageMode === 'employee'">
                <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                  @click="router.push('/signup')">Зарегистрируйтесь</span><span> и используйте все возможности биржи,
                  чтобы найти лучший вариант</span>
              </div>
              <div v-else>
                <span class="text-[#0A5CD5] font-medium hover:underline cursor-pointer"
                  @click="router.push('/signup')">Зарегистрируйтесь</span><span> и используйте все возможности биржи,
                  чтобы стать лидером рынка</span>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>


    <!-- <div class="w-full md:mb-[550px] md:mt-[32px] relative">

      <div class="absolute w-full">
        <CardsSlider class="">
          <img src="./../assets/images/carousel1_1.webp" class="w-[500px] h-[390px] rounded-[12px]" />
          <img src="./../assets/images/carousel2_1.webp" class="w-[500px] h-[390px] rounded-[12px]" />
          <img src="./../assets/images/carousel3_1.webp" class="w-[500px] h-[390px] rounded-[12px]" />
        </CardsSlider>
      </div>

    </div> -->
    <div class="w-full mb-[270px] md:mb-[520px] relative md:mr-[-100px]">

      <div v-if="pageMode === 'employee'" class="absolute w-full">
        <UIMarkuee>
          <CardsSlider class="pl-[10px] md:pl-0  overflow-visible">
            <template v-for="i in [1, 2, 3, 4, 5, 6]">
              <!--          <div class="md:ml-[calc((100vw-992px)/2.0)] md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">-->
              <div class=" md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/carousel1_1.webp"
                  class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                  class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                  <span class="text-[#0A5CD5]">Заяви о себе</span>
                  <span class="text-[#0A5CD5]">как о специалисте</span>
                </div>
              </div>
              <div class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/carousel2_1.webp"
                  class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                  class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                  <span class="text-[#0A5CD5]">Будь в сообществе</span>
                  <span class="text-[#0A5CD5]">профессионалов</span>
                </div>
              </div>
              <!--          <div class="md:mr-[calc((100vw-992px)/2.0) md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">-->
              <div class=" md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/carousel3_1.webp"
                  class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                  class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                  <span class="text-[#0A5CD5]">Занимай должность</span>
                </div>
              </div>
            </template>
          </CardsSlider>
        </UIMarkuee>
      </div>

      <div v-else class="absolute w-full">
        <UIMarkuee>
          <CardsSlider class="pl-[10px] md:pl-0  overflow-visible">
            <template v-for="i in [1, 2, 3, 4, 5, 6]">
              <!--          <div class="md:ml-[calc((100vw-992px)/2.0)] md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">-->
              <div class=" md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/carousel4.webp"
                  class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                  class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[421px] md:h-[92px] w-[270px] h-[128px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                  <span class="text-[#0A5CD5]">Привлекайте лучших в своей отрасли</span>
                  <span class="text-[#0A5CD5]">и создавайте образ компании,</span>
                  <span class="text-[#0A5CD5]">как лидера мнений</span>
                </div>
              </div>
              <div class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/carousel5.webp"
                  class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                  class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                  <span class="text-[#0A5CD5]">Заявите о своих самых</span>
                  <span class="text-[#0A5CD5]">успешных проектах</span>
                </div>
              </div>
            </template>
          </CardsSlider>
        </UIMarkuee>
        <!-- <UIMarkuee>
          <CardsSlider class="pl-[10px] md:pl-0 overflow-visible">
            <template v-for="i in [1, 2, 3, 4, 5, 6]">
              <div class="md:ml-[calc((100vw-992px)/2.0)] md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/carousel4.webp"
                  class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                  class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[421px] md:h-[92px] w-[270px] h-[128px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                  <span class="text-[#0A5CD5]">Привлекайте лучших в своей отрасли</span>
                  <span class="text-[#0A5CD5]">и создавайте образ компании,</span>
                  <span class="text-[#0A5CD5]">как лидера мнений</span>
                </div>
              </div>
              <div class="md:mr-[calc((100vw-992px)/2.0) md:w-[500px] md:h-[390px] w-[289px] h-[182px] relative">
                <img src="./../assets/images/carousel5.webp"
                  class="md:w-[500px] md:h-[390px] w-[289px] h-[182px] rounded-[12px] shrink-0 object-cover"></img>
                <div
                  class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:w-[280px] w-full h-[72px] rounded-[12px] flex flex-col justify-center items-center px-[28px] text-center">
                  <span class="text-[#0A5CD5]">Заявите о своих самых</span>
                  <span class="text-[#0A5CD5]">успешных проектах</span>
                </div>
              </div>
            </template>

          </CardsSlider>
        </UIMarkuee> -->

      </div>
    </div>

    <div class="w-full flex flex-row justify-center" v-if="!authStore.isAuthenticated">
      <ButtonNew v-if="mobile.isMobile.value" type="blue" class="!rounded-[32px] !h-[41px] mb-[64px] !w-[92%]"
        @click="router.push('/signup')">
        Зарегистрироватья
      </ButtonNew>
      <ButtonNew v-else type="stroke" class="!rounded-[32px] !h-[44px] !w-[308px] mb-[108px]"
        @click="router.push('/signup')">
        Зарегистрироватья
      </ButtonNew>
    </div>


  </DefaultLayout>


</template>

<script setup>
import WelcomeCardNew from '@/components/cards/WelcomeCardNew.vue';
import VacancyCard from './../components/cards/VacancyCard.vue';
import PreSignupCard from './../components/cards/PreSignupCard.vue'
import Header from './../components/ui/Header.vue'
import { ref, watch, onUnmounted, onMounted, computed, onBeforeMount } from 'vue';
import CardsSlider from '@/components/ui/CardsSlider.vue';
import { useFetch, } from '@/composables/useFetch';
import { useAuthStore } from '@/stores/authStore';
import HeaderNew from '@/components/ui/HeaderNew.vue';
import FooterNew from '@/components/ui/FooterNew.vue';
import Input from '@/components/ui/Input.vue';
import VacancyCardNew from '@/components/cards/VacancyCardNew.vue';
// import welcomeImage from '@/assets/images/GES.png';
// import whyWeImage from '@/assets/images/points.png';
// import droplet from '@/assets/images/droplet.png'
// import mortarboard from '@/assets/images/mortarboard.png'
// import personCheck from '@/assets/images/person-check.png'
import picture1 from '@/assets/images/whyWe1.webp'
import picture2 from '@/assets/images/WhyWe2.webp'
import picture3 from '@/assets/images/WhyWe3.webp'
import dropletMobile from '@/assets/images/droplet-mobile.webp'
import mortarboardMobile from '@/assets/images/mortarboard-mobile.webp'
import personCheckMobile from '@/assets/images/person-check-mobile.webp'
import event1 from '@/assets/images/event1.png';
import event2 from '@/assets/images/event2.png';
import NotAuthPopup from '@/components/cards/NotAuthPopup.vue';
import WhyWeItem from '@/components/cards/WhyWeItem.vue';
import EventCard from '@/components/cards/EventCard.vue';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout";
import { useEventsStore } from "@/stores/eventsStore";
import { formatDateTime, humanizeDate } from "@/composables/utils";
import { sendMetrick } from '@/composables/utils';
import WelcomeBlock from '@/components/cards/WelcomeBlock.vue';
import UIIcon from '@/components/ui/UIIcon.vue';
import WJResumeCard from '@/components/cards/WJResumeCard.vue';
import Sheet from '@/components/cards/Sheet.vue';
import EventIndexCard from '@/components/cards/EventIndexCard.vue';
import ArticleIndexCard from '@/components/cards/ArticleIndexCard.vue';
import { useArticlesStore } from '@/stores/articlesStore';
import { useMobile } from '@/composables/useMobile';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import UIMarkuee from "@/components/ui/UIMarkuee";

const mobile = useMobile()

const pageMode = ref('employee')


const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();

const { authRequest, anonRequest, baseURL } = useFetch();

const carouselItems = ref([
  {
    image: './../assets/images/carousel1_1.webp',
    label: 'test'
  },
  {
    image: './../assets/images/carousel2_1.webp',
    label: 'test2'
  },
  {
    image: './../assets/images/carousel3_1.webp',
    label: 'test3'
  }
])


const vacancies = ref([]);
const resumes = ref([]);
// const vacanciesIdsWithFavorite = ref({})
// const vacanciesIdsWithFavoriteInitiate = ref(false)
const loading = ref(false);
const error = ref(null);

const globalBlur = ref(false)
const noAuthPopup = ref(false)

const handleGlobalBlur = () => {
  globalBlur.value = !globalBlur.value
  if (noAuthPopup.value) {
    noAuthPopup.value = false
  }
}

const handleNoAuthPopup = () => {
  noAuthPopup.value = !noAuthPopup.value
}


// const onHideMenu = () => {

//   if (!noAuthPopup.value) {
//     globalBlur.value = false
//   }
// }

// const onShowMenu = () => {
//   globalBlur.value = true
// }

const profession = ref('')

const events = useEventsStore()
const articles = useArticlesStore()

const seeAll = () => {
  if (pageMode.value === 'employee')
    router.push('/employee/vacancies')
  else if (pageMode.value === 'employer')
    router.push('/employer/resumes')
}

const handleEnter = () => {
  if (pageMode.value === 'employee')
    router.push(`/employee/vacancies?profession=${profession.value}`)
  else if (pageMode.value === 'employer')
    router.push(`/employer/resumes?profession=${profession.value}`)
};

const fetchVacanciesData = async (queryParams = {}) => {
  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку
  queryParams.status__in = 'PUBLISHED'
  queryParams.limit = 4

  try {
    // Создаем строку запроса вручную
    const queryArray = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      // Проверьте, является ли значение массивом
      if (Array.isArray(value)) {
        // Если массив, объедините значения через запятую
        queryArray.push(`${key}=${value.join(',')}`);
      } else {
        queryArray.push(`${key}=${value}`);
      }
    }
    const queryString = queryArray.join('&'); // Объединяем параметры
    const url = `/vacancies/${queryString ? '?' + queryString : ''}`;


    const response = await anonRequest(url, {
      method: 'GET',
    });

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      vacancies.value = response.results; // Предполагаем, что API возвращает массив данных
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const fetchResumesData = async (queryParams = {}) => {

  loading.value = true; // Устанавливаем статус загрузки
  resumes.value = []
  error.value = null; // Сбрасываем предыдущую ошибку
  queryParams.limit = 4

  try {
    // Создаем строку запроса вручную
    const queryArray = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      // Проверьте, является ли значение массивом
      if (Array.isArray(value)) {
        // Если массив, объедините значения через запятую
        queryArray.push(`${key}=${value.join(',')}`);
      } else {
        queryArray.push(`${key}=${value}`);
      }
    }
    let queryString = queryArray.join('&');
    const url = `/resumes/${queryString ? '?' + queryString : ''}`;


    const response = await anonRequest(url, {
      method: 'GET',
    });

    // Логируем конечный URL

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      resumes.value = response.results; // Предполагаем, что API возвращает массив данных
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

// const fetchMe = async () => {
//   const url = `/me/`;

//   const response = await authRequest(url, {
//     method: 'GET',
//   });

//   response.favorites_vacancies.forEach(number => {
//     vacanciesIdsWithFavorite.value[number] = true;
//   });

// }


// const fetchSetVacancies = async (vacanciesIdsToSet = []) => {
//   const url = `/vacancies/set_favorite/`;
//   const responseBody = {
//     "vacancies_ids": vacanciesIdsToSet
//   }
//   const response = await authRequest(url, {
//     method: 'POST',
//     body: responseBody
//   });

//   return response

// }


const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

// watch(vacanciesIdsWithFavorite, (newValue, oldValue) => {

//   let vacanciesIdsToSet = []
//   // if (vacanciesIdsWithFavoriteInitiate.value === true) {
//   Object.keys(newValue).forEach(key => {
//     if (newValue[key] === true) {
//       vacanciesIdsToSet.push(Number(key))
//     }
//   });
//   fetchSetVacancies(vacanciesIdsToSet);
//   // } else {
//   //   vacanciesIdsWithFavoriteInitiate.value = true
//   // }

// }, { deep: true });

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

watch(noAuthPopup, (newValue) => {
  if (newValue) {
    globalBlur.value = true;
  } else {
    globalBlur.value = false;
  }
});

watch(pageMode, (newValue) => {
  if (newValue === 'employee') {
    fetchVacanciesData({
      limit: '10'
    })
  } else if (pageMode.value === 'employer') {
    fetchResumesData({
      limit: '10'
    })
  }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

// onBeforeMount(() => {
//   events.getNears(5)
// })


onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
  if (pageMode.value === 'employee')
    fetchVacanciesData({
      limit: '10'
    })
  else if (pageMode.value === 'employer')
    fetchResumesData({
      limit: '10'
    })
  authStore.getMe(true)
  events.getNears(5)
  articles.getPublicArticles(5)
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});


const labelClass = computed(() => {
  if (pageMode.value === 'employee')
    return 'justify-start'
  else
    return 'justify-end'
})

const label = computed(() => {
  if (pageMode.value === 'employee')
    return 'Новые вакансии'
  else
    return 'Новые резюме'
})

</script>

<style>
@media (min-width: 769px) {
  .main {
    padding-right: 5.53rem;
    padding-left: 5.53rem;
  }
}

@media (max-width: 768px) {
  .main {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

.image-container {
  position: relative;
  display: inline-block;
  /* или flex, если нужно */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  /* Прозрачный фон, при желании */
  padding: 10px;
  /* Отступы */
  border-radius: 8px;
  /* Скругленные углы для overlay */
  text-align: center;
  /* Центрование текста */
  /* Добавьте другие стили по вашему желанию */
}

.centered-element {
  position: absolute;
  /* Абсолютное позиционирование */
  top: 50%;
  /* Сдвигаем элемент на 50% по вертикали */
  left: 50%;
  /* Сдвигаем элемент на 50% по горизонтали */
  transform: translate(-50%, -50%);
  /* Сдвигаем элемент назад на его половину */
  background-color: lightblue;
  /* Для наглядности */
  padding: 20px;
  /* Отступы внутри элемента */
  border-radius: 5px;
  /* Скругление углов */
}

.block {
  @apply md:w-[1280px] w-full h-full md:px-[144px] px-[1rem] pt-[2rem];
}

.picture {
  @apply md:w-[277px] md:h-[150px] w-[319px] !object-cover rounded-[0.8rem];
}

@media (max-width: 768px) {
  .feature {
    box-shadow: 8px 8px 24px 0 rgba(10, 92, 213, 0.29);
    @apply w-full bg-[#0A5CD5] rounded-[12px] flex flex-row gap-x-[12px] items-center justify-center text-white text-[14px] leading-[17.2px] h-[52px]
  }
}

@media (min-width: 769px) {
  .feature {
    @apply w-[317px] bg-white rounded-[12px] flex flex-row gap-x-[12px] items-center justify-center text-[#0A5CD5] text-[14px] leading-[17.2px] h-[60px] border border-[#0A5CD5]
  }
}
</style>
