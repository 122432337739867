import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';

export const useArticlesStore = defineStore('articlesStore', {
  state: () => ({
    item: null, // Текущая статья
    items: [], // Текущие статьи
  }),
  actions: {
    // getAll(vacancyId, status) {
    //   const fetch = useFetch()
    //   return fetch.authRequest(`/vacancies/${vacancyId}/`, {
    //     method: 'PUT',
    //     body: {
    //       status,
    //     }
    //   })
    // },

    getAccountArticles(status){
      const fetch = useFetch()
      if (status === 'PUBLISHED')
        return fetch.authRequest(`/account/articles?status__in=PUBLISHED`).then((data) => {
          this.items = data.results
        })
      else if (status === 'NO_PUBLISHED')
        return fetch.authRequest(`/account/articles?status__in=MODERATION,BANNED`).then((data) => {
          this.items = data.results
        })
    },

    getPublicArticles(limit = 10){
      const fetch = useFetch()
      return fetch.anonRequest(`/articles?offset=0&limit=${limit}`).then((data) => {
        this.items = data.results
        return data
      })
    },

    getPublicArticle(id){
      const fetch = useFetch()
      return fetch.anonRequest(`/articles/${id}`).then((data) => {
        this.item = data
        return data
      })
    },

    getAccountArticle(id){
      const fetch = useFetch()
      return fetch.authRequest(`/account/articles/${id}`).then((data) => {
        this.item = data
        return data
      })
    },

    update(id, data){
      const fetch = useFetch()
      return fetch.authRequest(`/account/articles/${id}`, {
        method: 'PATCH',
        body: data
      }).then((data) => {
        this.item = data
        return data
      })
    },

    create(data) {
      const fetch = useFetch()
      return fetch.authRequest(`/account/articles`, {
        method: 'POST',
        body: data,
      }).then((data) => {
        this.item = data
        return data
      })
    }

  },
});