<template>
    <Sheet class="flex flex-row justify-between gap-x-[20px] max-h-[220px]" @mouseenter="isHovered = true"
        @mouseleave="isHovered = false">
        <div class="w-full" v-if="!mobile.isMobile.value">
            <img v-if="event.cover" :src="`${baseURL}/files/${event.cover}/download`"
                class="w-[187px] h-[164px] object-cover rounded-[1rem]" />
            <div v-else class="flex items-center justify-center w-[187px] h-[164px]">
                <UIIcon size="160" name="camera" color="grey"></UIIcon>
            </div>
        </div>

        <div class="flex flex-col justify-between w-full gap-y-[12px]">
            <div class="font-bold text-[16px] leading-[19.57px]">
                {{ event.name.length > 32 ? event.name.substring(0, 32).trim() + '...' : event.name }}
            </div>
            <div class="text-[0.875rem] leading-[1.07rem]">{{ humanizeDate(event.date) }}</div>
            <div class="text-[0.875rem] leading-[1.07rem]">{{ event.content.substring(0, 75) }} {{ event.content.length
                > 75 ? '...' : '' }} </div>
            <div class="text-[0.875rem] leading-[1.07rem] text-[#0A5CD5] text-right cursor-pointer"
                :class="computedClass" @click="$router.push(`/education/events/${event.id}`)">Записаться</div>
        </div>

    </Sheet>
</template>
<script setup>
import { useFetch } from '@/composables/useFetch';
import { humanizeDate } from '@/composables/utils';
import { defineProps, computed, ref } from 'vue';
import Sheet from './Sheet.vue';
import router from '@/router';
import UIIcon from '../ui/UIIcon.vue';
import { useMobile } from '@/composables/useMobile';
const mobile = useMobile()


const { baseURL, anonRequest, authRequest } = useFetch();

const props = defineProps({
    event: {
        default: null
    },
});

const isHovered = ref(false)

const computedClass = computed(() => {
    if (isHovered.value === true)
        return 'underline'
    else
        return ''
})
</script>
