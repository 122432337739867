<template>
<!--  <Header @toggleScroll="handleToggleScroll"></Header>-->
  <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>
  <div id="page" class="flex flex-col h-full overflow-auto"  style="background-color: rgba(10, 92, 213, 0.03)">
    <div class="flex-grow md:mt-[64px] mdt-[56px]">
      <UIContainer>
        <div class="md:mt-[40px]">
          <div class="hidden md:block mb-[32px] font-medium text-[2.25rem] leading-[2.75rem] text-[#0A5CD5]">
            Обучающая платформа
          </div>
        </div>
        <div class="flex gap-[20px]">
          <Sheet class="!p-[32px] !w-[317px] flex flex-col gap-[24px]">
            <div
              class="text-[#1F1E1E] text-[1.25rem] leading-[1.5rem] font-normal hover:underline cursor-pointer"
              @click="$router.push(`/education/articles`) & sendMetrick('EDUCATION-PAGE-ARTICLES')"
            >
              Статьи
            </div>
            <div
              class="text-[#1F1E1E] text-[1.25rem] leading-[1.5rem] font-normal hover:underline cursor-pointer"
              @click="$router.push(`/education/events`) & sendMetrick('EDUCATION-PAGE-EVENTS')"
            >
              Мероприятия
            </div>
          </Sheet>
          <div class="pb-[100px] w-[652px]">
            <template v-if="loading">
              <UILoader :is-loading="true"></UILoader>
            </template>
            <template v-else>
              <slot name="default">
                <!--        Содержимое странички (дефаулт)-->
              </slot>
            </template>

          </div>
        </div>

      </UIContainer>

    </div>

<!--    <Footer class="box-border"></Footer>-->
    <FooterNew class="box-border"></FooterNew>
  </div>

</template>

<script setup>
import {ref} from "vue";
import Header from "@/components/ui/Header.vue";
import Footer from "@/components/ui/Footer.vue";
import FooterNew from "@/components/ui/FooterNew";
import HeaderNew from "@/components/ui/HeaderNew";
import UIContainer from "@/components/ui/UIContainer";
import Sheet from "@/components/cards/Sheet";
import UILoader from "@/components/ui/UILoader";
import { sendMetrick } from "@/composables/utils";

const disableScroll = ref(false);

const props = defineProps({
  loading: {
    default: false,
  }
})

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

</script>

<style >


</style>