<template>
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M55 30C55 38.2843 48.2843 45 40 45C31.7157 45 25 38.2843 25 30C25 21.7157 31.7157 15 40 15C48.2843 15 55 21.7157 55 30Z"
            fill="#B7B7B7" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40ZM40 5C20.67 5 5 20.67 5 40C5 48.2672 7.86628 55.8648 12.6593 61.8535C16.2146 56.1262 24.0232 50 40 50C55.9769 50 63.7854 56.1262 67.3407 61.8535C72.1337 55.8648 75 48.2671 75 40C75 20.67 59.33 5 40 5Z"
            fill="#B7B7B7" />
    </svg>

</template>