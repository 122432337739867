<template>
    <LKLayout>
        <div v-if="$authStore.me && fetched" class="flex flex-col gap-y-[32px]">
          <template v-if="!mobile.isMobile.value">
            <div class="inline-flex gap-x-[20px] h-[100px]">
              <Sheet class="analytics-card max-w-[125px]">
                <div class="analytics-data">{{ authStore.me.vacancies_count }}</div>
                <div class="analytics-label">Вакансии</div>
              </Sheet>
              <Sheet class="analytics-card max-w-[125px]">
                <div class="analytics-data">{{ responsesCount }}</div>
                <div class="analytics-label">Отклики</div>
              </Sheet>
              <Sheet class="analytics-card">
                <div v-if="activeTarif === null" class="analytics-data">Без тарифа</div>
                <div v-else-if="activeTarif" class="analytics-data">{{ activeTarif.name }}</div>
                <div class="analytics-label">Тариф</div>
              </Sheet>
            </div>
          </template>


            <Sheet class="flex  h-min  md:!rounded-[24px]"
              :class="[
                mobile.isMobile.value ? '!p-0 bg-transparent' : '!p-[32px]'
              ]"
            >
                <div class="flex flex-col md:flex-row gap-x-[20px] w-full">
                    <div class="flex flex-col flex-col gap-y-[32px] justify-between h-min">
                      <Avatar size="100" :image-id="employerProfile.avatar" ></Avatar>
<!--                        <div-->
<!--                            class="relative flex flex-row justify-center w-[242px] h-[91px] pt-[20px] border border-[#B7B7B7] rounded-[12px] overflow-hidden"-->
<!--                        >-->
<!--                            <img-->
<!--                              v-if="employerProfile.avatar" :src="`${fetch.baseURL}/files/${employerProfile.avatar}/download`"-->
<!--                              class="absolute w-full h-full top-0 left-0 object-cover"-->
<!--                            >-->
<!--                            <UIIcon v-else size="50" name="camera" color="#B7B7B7"></UIIcon>-->


<!--                        </div>-->
                        <div v-if="!mobile.isMobile.value" class="flex flex-col">
                            <div class="inline-flex h-[40px] items-center gap-x-[8px]">
                                <UIIcon name="envelope" size="24" color="#0A5CD5" />
                                <div>{{  utils.emptySC(employerProfile.email) }}</div>
                            </div>
                            <div class="inline-flex h-[40px] items-center gap-x-[8px]">
                                <UIIcon name="telephone" size="24" color="#0A5CD5" />
                                <div>{{ utils.emptySC(employerProfile.phone) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-col gap-y-[32px] justify-between w-full mt-[32px] md:mt-0">
                        <div class="flex flex-col gap-y-[8px]">
                            <div class="hidden md:block text-[24px] leading-[29.4px] font-medium">{{ utils.emptySC(employerProfile.company_name) }}
                            </div>
                            <div class="text-[20px] leading-[24.5px]"
                              :class="[
                                mobile.isMobile.value ? 'text-[#0A5CD5] font-medium text-[1.125rem] leading-[1.375rem]' : ''
                              ]"
                            >{{ utils.emptySC(employerProfile.contact_person_last_name) }} {{ utils.emptySC(employerProfile.contact_person_first_name) }}</div>
                            <div class="inline flex gap-x-[12px] items-center">
                                <UIIcon name="geoAlt" size="16" color="#1F1E1E" />
                                <div class="text-[16px] leading-[19.6px]">{{ utils.emptySC(employerProfile.city) }}</div>
                            </div>
                        </div>
                        <div class="flex flex-col">
                          <template v-if="mobile.isMobile.value">
                            <div class="inline-flex h-[40px] items-center gap-x-[8px]">
                            <UIIcon name="envelope" size="24" color="#0A5CD5" />
                            <div>{{  utils.emptySC(employerProfile.email) }}</div>
                        </div>
                      <div class="inline-flex h-[40px] items-center gap-x-[8px]">
                        <UIIcon name="telephone" size="24" color="#0A5CD5" />
                        <div>{{ utils.emptySC(employerProfile.phone) }}</div>
                      </div>
                          </template>
                            <div class="inline-flex h-[40px] items-center gap-x-[8px]">
                                <UIIcon name="telegram" size="24" />
                                <div class="text-[#7B7A7A]">{{ utils.emptySC(employerProfile.telegram_contact) }}</div>
                            </div>
                            <div class="inline-flex h-[40px] items-center gap-x-[8px]">
                                <UIIcon name="whatsApp" size="24" />
                                <div class="text-[#7B7A7A]">{{ utils.emptySC(employerProfile.whatsapp_contact) }}</div>
                            </div>
                        </div>
                        <div class="md:ml-[30px]">
                            <ButtonNew  type="stroke" @click="router.push('/employer/account/settings') & sendMetrick('EMPLOYER-LK-EDIT')">Редактировать данные</ButtonNew>
                        </div>

                    </div>

                </div>

            </Sheet>
            <div v-if="!mobile.isMobile.value" class="flex flex-row gap-x-[20px]">
                <Sheet v-for="tarif in avaliableTarifs"
                    class="flex flex-col justify-between items-center h-min !p-[24px] w-[211px]"
                    :class="tarifClass(tarif.id)">
                    <div class="font-light text-[12px] leading-[15px]">Тариф</div>
                    <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">{{ tarif.name }}</div>
                    <div class="mb-[12px]"></div>
                    <div class="text-[16px] mb-[7px]">{{ tarif.vacancies_for_publication }} вакансий</div>
                    <div class="flex flex-row items-center h-[24px] w-full">
                        <div class="ml-[-6px] flex flex-row items-center" v-if="!tarif.branding">
                            <UIIcon size="24" name="x" color="#FF3B30"></UIIcon>
                            <div class="text-[14px] mb-[2px] leading-[17px] text-[#7B7A7A]">Брендирование</div>
                        </div>
                        <div v-else class="flex flex-row gap-x-[4px] items-center">
                            <UIIcon size="14" name="check" color="#01B00A"></UIIcon>
                            <div class="text-[16px] mb-[2px] leading-[17px] text-[#1F1E1E]">Брендирование</div>
                        </div>
                    </div>
                    <div class="flex flex-row  items-center mb-[12px] h-[24px] w-full">
                        <div class="ml-[-6px] flex flex-row items-center" v-if="!tarif.resumes_base">
                            <UIIcon size="24" name="x" color="#FF3B30"></UIIcon>
                            <div class="text-[14px] mb-[2px] leading-[17px] text-[#7B7A7A]">База резюме</div>
                        </div>
                        <div v-else class="flex flex-row gap-x-[4px] items-center">
                            <UIIcon size="14" name="check" color="#01B00A"></UIIcon>
                            <div class="text-[16px] mb-[2px] leading-[17px] text-[#1F1E1E]">База резюме</div>
                        </div>
                    </div>
                    <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">{{ tarif.price_per_month }} ₽
                    </div>
                    <!-- <div class="h-[40px]">
                        
                    </div> -->
                    <ButtonNew v-if="activeTarif && activeTarif.id === tarif.id" class="md:!h-[40px] mt-[12px]"
                        type="white" @click="router.push(`/employer/lk/tarif?type=paid`)">
                        Сменить тариф</ButtonNew>
                    <ButtonNew v-else class="md:!h-[40px] mt-[12px]" type="stroke"
                        @click="router.push(`/employer/lk/tarif?type=paid`)">
                        Активировать</ButtonNew>

                </Sheet>
            </div>
        </div>

    </LKLayout>
</template>

<script setup>

import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";
import Sheet from "@/components/cards/Sheet";
import UIIcon from "@/components/ui/UIIcon";
import UIFileUploader from "@/components/ui/UIFileUploader";
import { ref, watch, onBeforeMount } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useBillingStore } from "@/stores/billingStore";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useFetch } from "@/composables/useFetch";
import {useUtils} from "@/composables/useUtils";
import {useMobile} from "@/composables/useMobile";
import { sendMetrick } from "@/composables/utils";
import Avatar from "@/components/ui/Avatar";

const utils = useUtils();

const fetch = useFetch();
const router = useRouter();
const authStore = useAuthStore()
const billingStore = useBillingStore();

const responsesCount = ref(0)

const activeTarif = ref(null)
const activeSubscribition = ref(null)
const avaliableTarifs = ref([])
const employerProfile = ref(null)

const fetched = ref(false)

const mobile = useMobile()

const tarifClass = (tarifId) => {
    if (activeTarif.value !== null) {
      if (tarifId === activeTarif.value.id) {

            return '!bg-[#FFDA0729]'
        }

        else
            return ''
    }
    return ''
}


// watch(() => authStore.me.avatar, (newValue, oldValue) => {
//     authStore.updateMe({
//         ...authStore.me,
//     })
// })
onBeforeMount(() => {
    const meRequest = authStore.getMe();
    const billingRequest = billingStore.getTariffs()
    const responsesRequest = fetch.authRequest('account/employer/responses/').then((data) => {
        responsesCount.value = data.count
    })
    Promise.all([meRequest, billingRequest, responsesRequest]).then(() => {

        avaliableTarifs.value = billingStore.plans.items
        if (authStore.me.user_subscription) {
            activeTarif.value = billingStore.plans.items.find(item => item.id === authStore.me.user_subscription.tariff);
            activeSubscribition.value = authStore.me.user_subscription
        }
        employerProfile.value = authStore.me.employer_profile
        fetched.value = true


    });

})


const trigger = (data) => {
    data.data.triggerFileInput()
    // data.triggerFileInput
}
</script>

<style scoped>
.analytics-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    /* Учитывая, что это стиль для md и выше */
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #0A5CD5;
}

.analytics-data {
    font-size: 44px;
    line-height: 54px;
    text-align: center;
    color: #FFFFFF;
}

.analytics-label {
    font-size: 16px;
    line-height: 19.57px;
    color: #FFFFFF;
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;
}
</style>