<template>
    <Sheet class="flex md:flex-row flex-col-reverse md:justify-between items-center gap-x-[20px] md:max-h-[220px] p-[12px] md:p-[24px]"
        @mouseenter="isHovered = true" @mouseleave="isHovered = false">
        <img v-if="article.cover" :src="`${baseURL}/files/${article.cover}/download`"
            class="md:w-[187px] w-full h-[164px] object-cover rounded-[1rem]" />
        <div v-else class="flex items-center justify-center w-[198px] h-[164px]">
            <UIIcon size="160" name="camera" color="grey"></UIIcon>
        </div>
        <div class="flex flex-col justify-between w-full">
            <div class="flex flex-col" v-if="mobile.isMobile.value">
                <div class="w-full flex flex-row-reverse text-[12px] font-light">
                    {{ formatDateMinimal(article.created_at) }}</div>
                <div class="flex flex-row gap-x-[0.5rem] mb-[1.25rem]">
                    <div v-if="article.user.employer_profile">
                        <img :src="`${baseURL}/files/${article.user.employer_profile.avatar}/download`"
                            class="w-[44px] h-[44px] rounded-[22px] object-contain">
                    </div>
                    <div v-else>
                        <img :src="`${baseURL}/files/${article.user.avatar}/download`"
                            class="w-[44px] h-[44px] rounded-[22px] object-contain">
                    </div>
                    <div class="flex flex-col justify-center">
                        <div class="font-medium md:text-[16px] md:leading-[19.57px] text-[14px] leading-[17.12px]"> {{
                            article.first_name }} {{ article.user.last_name }}</div>
                        <div v-if="article.user.employer_profile" class="text-[14px]"> {{
                            article.user.employer_profile.company_name }}</div>
                    </div>
                </div>
            </div>


            <!-- <div class="font-medium md:mb-0 mb-2">{{ article.name.substring(0, 35) }} {{ article.name.length
                > 35 ? '...' : '' }}</div> -->
            <div class="font-medium md:mb-0 mb-2">
                {{ article.name.length > 32 ? article.name.substring(0, 32).trim() + '...' : article.name }}
            </div>
            <div class="text-[0.875rem] leading-[1.07rem] md:mb-0 mb-4">{{ article.content.substring(0, 75) }} {{
                article.content.length > 75 ? '...' : '' }}
                <span v-if="mobile.isMobile.value" class="text-[#0A5CD5]"
                    @click="$router.push(`/education/articles/${article.id}`)">Читать далее</span>
            </div>
            <div v-if="!mobile.isMobile.value"
                class="text-[0.875rem] leading-[1.07rem] text-[#0A5CD5] text-right cursor-pointer"
                :class="computedClass" @click="$router.push(`/education/articles/${article.id}`)">Читать далее</div>
        </div>

    </Sheet>
</template>
<script setup>
import { useFetch } from '@/composables/useFetch';
import { formatDate, humanizeDate, formatDateMinimal } from '@/composables/utils';
import { defineProps, computed, ref } from 'vue';
import Sheet from './Sheet.vue';
import router from '@/router';
import UIIcon from '../ui/UIIcon.vue';
import { useMobile } from '@/composables/useMobile';
const mobile = useMobile()

const { baseURL, anonRequest, authRequest } = useFetch();

const props = defineProps({
    article: {
        default: null
    },
});

const isHovered = ref(false)

const computedClass = computed(() => {
    if (isHovered.value === true)
        return 'underline'
    else
        return ''
})
</script>