<template>
  <EducationLayout class="md:pb-[100px]">
    <div class="flex flex-col gap-[16px] mb-[120px]">
      <Sheet v-for="article in articlesStore.items"
        class="bg-white shadow-[4px_4px_1.5rem_0_rgba(10,92,213,0.1)] rounded-[1.5rem] p-[24px] border-[transparent] hover:border-[#0A5CD5] border-[1px] hover:cursor-pointer !h-fit"
        @click="$router.push(`/education/articles/${article.id}`) & sendMetrick('EDUCATION-PAGE-ARTICLE')">
        <div class="flex flex-col gap-[20px]">
          <div class="flex justify-between items-start">
            <div v-if="article.user" class="flex gap-[8px]">
              <div v-if="article.user.role === 'employer'">
                <Avatar
                  size="44"
                  :image-id="article.user.employer_profile.avatar"
                ></Avatar>
<!--                <img :src="`${fetch.baseURL}/files/${article.user.employer_profile.avatar}/download`"-->
<!--                  class="w-[44px] h-[44px] rounded-[22px] object-contain">-->
              </div>
              <div v-else-if="article.user.role === 'employee'">
<!--                <img :src="`${fetch.baseURL}/files/${article.user.avatar}/download`"-->
<!--                  class="w-[44px] h-[44px] rounded-[22px] object-contain">-->
                <Avatar
                  size="44"
                  :image-id="article.user.avatar"
                ></Avatar>
              </div>
              <div>
                <div class="font-medium text-[#1F1E1E] text-[1.125rem] leading-[1.375rem]">
                  <template v-if="article.user.role === 'employer'">
                    {{ article.user.employer_profile.contact_person_first_name }}
                    {{ article.user.employer_profile.contact_person_last_name }}
                  </template>
                </div>
                <div>
                  <template v-if="article.user === 'employer'">
                    {{ article.user.employer_profile.company_name }}
                  </template>
                </div>
              </div>
            </div>

            <div v-else>

            </div>


            <div class="flex gap-[19px]">
              <div class="font-light text-[0.75rem] leading-[0.9375rem] text-[#7B7A7A]">
                {{ formatDateTime(article.created_at) }}
              </div>
              <div>
                <UIIcon size="16" name="dots"></UIIcon>
              </div>
            </div>
          </div>
          <div class="font-medium text-[1.125rem] leading-[1.375rem] text-[#1F1E1E]">
            {{ article.name }}
          </div>
        </div>

        <div>

          {{ article.content.substring(0, 100) }} {{ article.content.length > 50 ? '...' : '' }}
          <span class="text-[#0A5CD5]">Читать далее</span>
        </div>


        <div class="flex flex-row justify-center">
          <img v-if="article.cover" :src="`${fetch.baseURL}/files/${article.cover}/download`"
            class="mt-[20px] w-full h-[346px] rounded-[12px] object-cover">
          <UIIcon v-else size="300" name="camera" color="#B7B7B7"></UIIcon>
        </div>

      </Sheet>
    </div>
  </EducationLayout>
</template>

<script setup>

import EducationLayout from "@/layouts/EducationLayout";
import UIContainer from "@/components/ui/UIContainer";
import Sheet from "@/components/cards/Sheet";
import { onBeforeMount } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";
import UIIcon from "@/components/ui/UIIcon";
import { useFetch } from "@/composables/useFetch";
import { formatDateTime, sendMetrick } from "@/composables/utils";
import Avatar from "@/components/ui/Avatar";

const fetch = useFetch()

const articlesStore = useArticlesStore()

onBeforeMount(() => {
  articlesStore.getPublicArticles()
})
</script>

<style scoped></style>