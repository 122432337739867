<template>
    <div class="card-container" ref="cardContainer" @click="processClick()">
        <slot name="default">

        </slot>
        <div v-if="showAlert" class="absolute-box" :class="alertComputedClass">
            <div class="flex flex-row items-start gap-x-[18px]" v-if="status === 'MODERATION'">
                <UIIcon size="21" name="warningLight"></UIIcon>
                <span class="card-text">
                    {{ you[0] }} {{ entity }} {{ you[1] }} на модерацию. После прохождения модерации, {{ entity }} автоматически опубликуется.
                    Модерация займет пару минут.
                </span>
            </div>
            <div class="flex flex-row items-start gap-x-[18px]" v-else-if="status === 'BANNED'">
                <UIIcon size="21" name="warning"></UIIcon>
                <span class="card-text">{{ you[0] }} {{ entity }} {{ you[2] }}. Причины блокировки:
                    <span v-for="(reason, index) in banReasons" :key="index">
                        <!-- {{ reason }} {{ index }} -->
                        {{ humanizeBanReason(reason) }}<span v-if="index < banReasons.length - 1">, </span>
                        <span v-if="index < banReasons - 1"> </span>
                    </span>.
                </span>
            </div>

        </div>
        <div v-if="status === 'MODERATION'" class="overlay-moderation"></div>
        <div v-else-if="status === 'BANNED'" class="overlay-ban"></div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed, onMounted, onBeforeUnmount } from 'vue'
import { humanizeBanReason } from '@/composables/utils'
import UIIcon from './UIIcon.vue'

const cardContainer = ref(null); // Создаем ref для контейнера

const processClick = () => {
    showAlert.value = !showAlert.value
    console.log('process click!!!')
}

const handleClickOutside = (event) => {
    if (cardContainer.value && !cardContainer.value.contains(event.target)) {
        console.log('Clicked outside!');
        // Здесь можно вызвать вашу логику
        showAlert.value = false; // например, скрыть alert
    }
};

const alertComputedClass = computed(() => {
    if (props.status === 'MODERATION')
        return 'border-[1px] border-[#0B54BF]'
    else if (props.status === 'BANNED')
        return 'border-[1px] border-[#FF3B30]'
    else
        return 'hidden'
})

const you = computed(() => {
    if (['статья', 'вакансия'].includes(props.entity)) {
        return ['Ваша', 'отправлена', 'заблокирована'];
    } else if (['резюме', 'мероприятие'].includes(props.entity)) {
        return ['Ваше', 'отправлено', 'заблокировано'];
    } else {
        return ['TEST', 'TEST', 'TEST'];
    }
});

const showAlert = ref(false)

const props = defineProps({
    status: {
        default: 'MODERATION'
    },
    entity: {
        default: 'Сущность'
    },
    banReasons: {
        default: []
    },
    modelValue: {
        type: Boolean,
        default: false
    },
})

onMounted(() => {
    document.addEventListener('mousedown', handleClickOutside);
});

// Удаление слушателя события при уничтожении компонента
onBeforeUnmount(() => {
    document.removeEventListener('mousedown', handleClickOutside);
});
</script>

<style scoped>
.overlay-moderation {
    position: absolute;
    /* Абсолютное позиционирование наложенного элемента */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    /* Полупрозрачный 10% серый цвет */
    opacity: 0.5;
    /* Полупрозрачность 50% для всего элемента */
    border-radius: 24px;
    /* Закругление рамки */
    z-index: 1;
    cursor: pointer;
    border: 1px solid transparent
        /* Слой ниже карточки */
}

.overlay-ban {
    position: absolute;
    /* Абсолютное позиционирование наложенного элемента */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    /* Полупрозрачный 10% серый цвет */
    opacity: 0.5;
    /* Полупрозрачность 50% для всего элемента */
    border-radius: 24px;
    /* Закругление рамки */
    z-index: 1;
    cursor: pointer;
    border: 1px solid transparent
        /* Слой ниже карточки */
}

.overlay-ban:hover {
    border: 1px solid #FF3B30;
    /* Увеличиваем прозрачность при наведении */
    /* Можно добавить дополнительные стили, если нужно */
}

.overlay-moderation:hover {
    border: 1px solid #F0CE0E;
    /* Увеличиваем прозрачность при наведении */
    /* Можно добавить дополнительные стили, если нужно */
}

.card-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* Relative для родительского контейнера */
    display: inline-block;
    /* Убедитесь, что карточка занимает нужное место */
    border-radius: 20px;
    /* Закругления */
}

.absolute-box {
    cursor: pointer;
    position: absolute;
    /* Абсолютное позиционирование */
    top: 50%;
    /* 50% от высоты родителя */
    left: 50%;
    /* 50% от ширины родителя */
    transform: translate(-50%, -50%);
    /* Центрирование элемента по середине */
    background-color: white;
    /* Цвет фона для наглядности */
    padding: 24px;
    /* Отступы внутри элемента */
    border-radius: 20px;
    z-index: 2;
    width: 80%;
}

@media (max-width: 768px) {
    .absolute-box {
        padding: 12px;
        width: 91%; /* Измените это значение по желанию */
    }
}

/* Для экранов шире 768px */
@media (min-width: 769px) {
    .absolute-box {
        padding: 24px;
        width: 80%; /* Значение после 768px */
    }
}

@media (max-width: 768px) {
    .card-text {
        font-size: 12px;
        line-height: 14.68px;
    }
}

/* Для экранов шире 768px */
@media (min-width: 769px) {
    .card-text  {
        font-size: 16px;
        line-height: 19.57px;
    }
}
</style>