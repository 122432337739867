<template>
    <LKLayout>
        <div class="flex flex-col w-full">
            <div class="text-[20px] leading-[24px] mb-3">Срок дейсвия бана пользователя</div>
            <div class="flex flex-col w-[50%] gap-y-8">
                <UISelectNew v-model="banDuration" :options="banDurations" placeholder="Выберите вариант">
                </UISelectNew>
                <Switch trueLabel="Включена автоматическая модерация"
                    falseLabel="Отключена автоматическая модерация" v-model=autoModeration>
                </Switch>
                <ButtonNew @click="save()">
                    Cохранить
                </ButtonNew>
            </div>


        </div>
    </LKLayout>

</template>

<script setup>
import { onMounted, reactive, ref, computed, watch, onBeforeMount } from "vue";
import { useFetch } from "@/composables/useFetch";

import {
    formatDateTime, formatexperienceForVacancy, formatexperienceForVacancyNew, humanizeBanReason
} from '@/composables/utils';
import Switch from "@/components/ui/Switch.vue";
import Select from '@/components/ui/Select.vue';
import UISelectNew from "@/components/ui/UISelectNew.vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import { useToast } from "vue-toast-notification";
import { useMobile } from "@/composables/useMobile";
import UIIcon from "@/components/ui/UIIcon.vue";
import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";

const fetch = useFetch()
const toast = useToast()

onMounted(() => {
    fetch.authRequest('/moderator/settings/').then((data) => {
        banDuration.value = data.moderation_user_ban_time
        autoModeration.value = data.auto_ai_moderation
    })
})

const save = () => {
    fetch.authRequest(('/moderator/settings/'), {
        method: 'PATCH',
        body: { 
            moderation_user_ban_time: banDuration.value,
            auto_ai_moderation: autoModeration.value
        }
    }).then((data) => {
        toast.success('Сохранено')
        banDuration.value = data.moderation_user_ban_time
    })

}

const banDuration = ref(null)
const autoModeration = ref(null)

const banDurations = ref([
    {
        title: "1 минута",
        value: 1
    },
    {
        title: "15 минут",
        value: 15
    },
    {
        title: "30 минут",
        value: 30
    },
    {
        title: "1 час",
        value: 60
    },
    {
        title: "2 часа",
        value: 120
    },
    {
        title: "4 часа",
        value: 240
    },
    {
        title: "12 часов",
        value: 720
    },
    {
        title: "1 день",
        value: 1440
    },
    {
        title: "1 неделя",
        value: 10080
    },
    {
        title: "1 месяц",
        value: 43840 // приближенное значение
    },
    {
        title: "Навсегда",
        value: 52056600
    }
]);

</script>

<style scoped></style>