<template>
    <DefaultLayout>


        <div class="!bg-[#F8F8F8] pb-[1.81rem] flex flex-col items-center ">
            <UIContainer>
                <div v-if="status === 'MODERATION'"
                    class="mt-[10px] md:mt-8 inline-flex w-full gap-x-[30px] justify-between">
                    <ButtonNew class="!h-[34px] md:!h-[48px]" @click="setArticleStatus(article.id, 'PUBLISHED')"
                        :class="[mobile.isMobile.value ? '!w-[149px]' : '']">
                        Принять
                    </ButtonNew>
                    <ButtonNew class="!h-[34px] md:!h-[48px]" type="stroke-red"
                        @click="popupsStore.openModerationBanPopup(article.id, 'article', $router)"
                        :class="[mobile.isMobile.value ? '!w-[44px]' : '']">
                        <!-- <UIIcon v-if="mobile.isMobile.value" name="x" size="32" color="red"></UIIcon> -->
                        <span>Заблокировать</span>
                    </ButtonNew>
                </div>
                <div v-else-if="status === 'BANNED'" class="mt-[10px] md:mt-8 inline-flex w-[45%]">
                    <ButtonNew class="!h-[34px] md:!h-[48px]" @click="setArticleStatus(article.id, 'PUBLISHED')"
                        :class="[mobile.isMobile.value ? '!w-[149px]' : '']">
                        Разблокировать
                    </ButtonNew>
                </div>
                <div class="mt-[32px]">
                    <Sheet v-if="!loading"
                        class="mb-[40px] bg-white shadow-[4px_4px_1.5rem_0_rgba(10,92,213,0.1)] rounded-[1.5rem] p-[24px] border-[transparent] border-[1px] !h-fit">
                        <div class="flex flex-col gap-[20px]">
                            <div class="flex justify-between items-start">
                                <div class="flex gap-[8px]">
                                    <div v-if="article.user.role === 'employer'">
                                        <img :src="`${fetch.baseURL}/files/${article.user.employer_profile.avatar}/download`"
                                            class="w-[44px] h-[44px] rounded-[22px] object-contain">
                                    </div>
                                    <div v-else-if="article.user.role === 'employee'">
                                        <img :src="`${fetch.baseURL}/files/${article.user.avatar}/download`"
                                            class="w-[44px] h-[44px] rounded-[22px] object-contain">
                                    </div>
                                    <div>
                                        <div class="font-medium text-[#1F1E1E] text-[1.125rem] leading-[1.375rem]">
                                            <template v-if="$authStore.me.role === 'employer'">
                                                {{ article.user.employer_profile.contact_person_first_name }}
                                                {{ article.user.employer_profile.contact_person_last_name }}
                                            </template>
                                        </div>
                                        <div>
                                            <template v-if="$authStore.me.role === 'employer'">
                                                {{ article.user.employer_profile.company_name }}
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex gap-[19px]">
                                    <div class="font-light text-[0.75rem] leading-[0.9375rem] text-[#7B7A7A]">
                                        {{ formatDateTime(article.created_at) }}
                                    </div>
                                    <div @click="copyUrl" class="cursor-pointer">
                                        <UIIcon size="16" name="dots"></UIIcon>
                                    </div>
                                </div>
                            </div>
                            <div class="font-medium text-[1.125rem] leading-[1.375rem] text-[#1F1E1E]">
                                {{ article.name }}
                            </div>
                        </div>




                        <div class="flex flex-row justify-center">
                            <img v-if="article.cover" :src="`${fetch.baseURL}/files/${article.cover}/download`"
                                class="mt-[20px] w-full h-[346px] rounded-[12px] object-cover">
                            <UIIcon v-else size="300" name="camera" color="#B7B7B7"></UIIcon>
                        </div>

                        <div class="mt-[20px] whitespace-pre-line text-base leading-5 text-black font-normal">

                            {{ article.content }}
                        </div>

                        <div class="h-[1px] w-full bg-[#7B7A7A] my-[20px]"></div>

                        <div v-if="article.user.role === 'employer'">
                            <div class="text-[#1F1E1E] font-medium text-[1.125rem] leading-[1.375rem]">
                                {{ article.user.employer_profile.contact_person_first_name }}
                                {{ article.user.employer_profile.contact_person_last_name }}
                            </div>
                            <div class="text-[#1F1E1E] text-base leading-5 font-normal">
                                {{ article.user.employer_profile.company_name }}
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div v-for="banReason in article.ban_reasons" :class="[
                                mobile.isMobile.value ?
                                    'text-[10px] leading-[12.2px] font-light p-1 px-2 bg-[#FF3B301A] rounded-md' :
                                    'bg-red-100 p-2 px-3.5 text-[1rem] leading-[1.25rem] rounded-lg',
                            ]" class="flex flex-row items-center text-black mr-2 mb-2">
                                {{ humanizeBanReason(banReason) }}
                            </div>
                        </div>

                    </Sheet>
                </div>
            </UIContainer>


        </div>
    </DefaultLayout>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, computed } from 'vue';
import {
    formatDateTime, formatexperienceForVacancy, formatexperienceForVacancyNew, humanizeBanReason
} from '@/composables/utils';
import { useFetch } from '@/composables/useFetch';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIContainer from "@/components/ui/UIContainer";
import UIPageHeader from "@/components/ui/UIPageHeader";
import CardsSlider from "@/components/ui/CardsSlider";
import VacancyCardNew from "@/components/cards/VacancyCardNew";
import { usePopupsStore } from "@/stores/popupsStore";
import LKLayout from '@/layouts/LKLayout.vue';
import Sheet from '@/components/cards/Sheet.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import UIIcon from '@/components/ui/UIIcon.vue';
import { useMobile } from '@/composables/useMobile';

const mobile = useMobile()

const authStore = useAuthStore();

const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const articleId = ref(route.params.id)

const article = ref({});
const loading = ref(true);
const error = ref(null);

const { anonRequest, authRequest, baseURL } = useFetch();

const popupsStore = usePopupsStore()

const status = computed(() => {
    return route.query.status
})

onMounted(() => {
    fetchArticleData();
});

const fetchArticleData = async () => {
    error.value = null; // Сбрасываем предыдущую ошибку

    try {

        let response
        response = await authRequest(`moderator/articles/${articleId.value}/`, {
            method: 'GET',
        });

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            article.value = response; // Предполагаем, что API возвращает массив данных
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const setArticleStatus = (id, status) => {
    authRequest(`moderator/articles/${articleId.value}/`, {
        method: 'PATCH',
        body: { status: status }
    });
    router.push('/moderator/articles')
}



const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

</script>

<style></style>