<template>
    <Card class="cursor-pointer p-[16px] md:p-[24px]" @click="handleCardClick()"
        :class="{ '!border !border-[1px] !border-[#0000000A]': mobile.isMobile.value, '': !mobile.isMobile.value }">
        <template #header>
            <div class="flex flex-row w-full justify-between">
                <div class="flex flex-col md:mb-[16px]">
                    <div class="
                    md:text-[20px]
                    md:leading-[24px]
                    md:mb-[8px]
                    font-medium
                    text-[1rem]
                    leading-[1.23rem]
                    w-full
                    md:h-[48px]
                    !overflow-ellipsis
                    ">{{ vacancyNameCutted }}</div>
                    <div
                        class="font-light text-[10px] leading-[12px] md:font-normal md:text-[12px] md:leading-[15px] mb-[8px]">
                        {{ employerName }}</div>
                    <div class="flex flex-row gap-x-[16px]">
                        <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)" class="header-price">{{
                            formatPrice(priceFrom) }} - {{ formatPrice(priceTo) }} рублей
                        </div>
                        <div v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)" class="header-price">{{
                            formatPrice(priceFrom) }}
                            рублей</div>
                        <div v-else-if="(priceFrom)" class="header-price">От {{
                            formatPrice(priceFrom) }} рублей</div>
                        <div v-else-if="(priceTo)" class="header-price">До {{
                            formatPrice(priceTo) }} рублей</div>
                        <div v-else class="md:mb-[24px]"></div>
                        <div v-if="(priceFrom || priceTo) && (tax === 'после налогообложения')"
                            class="h-[23px] w-[54px] bg-[#0A5CD51A] rounded-[4px] flex items-center justify-center"><span class="text-[12px]">на руки</span></div>
                    </div>


                    <div v-if="(priceFrom || priceTo) && (tax === 'до налогообложения' || mobile.isMobile.value)"
                        class="md:mt-[4px] mb-[12px] md:mb-0 text-[10px] font-light leading-[12px]">{{ tax }}</div>
                    <div v-else class="md:mb-[10px] mb-[4px]"></div>
                </div>
                <div class="flex-none ml-[8px]">
                    <div v-if="employerAvatar !== null">
<!--                        <img :src="`${baseURL}/files/${employerAvatar}/download`"-->
<!--                            class="md:w-[80px] md:h-[80px] h-[2.75rem] w-[2.75rem] object-fill">-->
                      <Avatar :image-id="employerAvatar" size="80"></Avatar>
                    </div>
                </div>
            </div>


        </template>
        <!-- <template #sub-action> Добавление в закладки
            <div><BoolIcon name="bookmark-gold" :checked="checked" @click.stop="checked = !checked"></BoolIcon></div>
        </template> -->
        <template #body>

        </template>
        <template #footer>
            <div class="flex flex-col md:justify-end md:gap-y-[4px]">
                <div v-if="city" class="md:text-[16px] md:leading-[19.6px] text-[14px] leading-[17px] md:font-medium">{{
                    city }}</div>
                <div v-else class="md:mb-[19.6px]"></div>
                <div v-if="experience"
                    class="md:text-[16px] md:leading-[19.6px] md:font-normal font-light text-[0.75rem] leading-[0.86rem] text-[#01B00A]">
                    Опыт: {{ formatexperienceForVacancy(experience) }}</div>
                <!-- <div v-else class="md:mb-[19.6px]"></div> -->
                <div
                    class="font-light text-[#7B7A7A] md:text-[12px] md:leading-[14.7px] text-[0.75rem] leading-[0.86rem] md:mt-[49px] mt-[29px]">
                    {{ formatDateTime(publicationTime) }}</div>
            </div>

        </template>
        <template #action>
            <div class="md:w-[149px] w-[9.3rem]">
                <ButtonNew v-if="!authStore.isAuthenticated || authStore.role === 'employee'" @click.stop="handleResponseClick()" class="md:!h-[48px] !h-[32px]">Откликнуться</ButtonNew>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Sheet from './Sheet.vue';
import BoolIcon from '../ui/BoolIcon.vue';
import Card from './Card.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import ButtonNew from '../ui/ButtonNew.vue';
import { defineProps, defineEmits, computed, onMounted, ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useFetch } from '@/composables/useFetch';
import { useMobile } from '@/composables/useMobile';


import {
    formatPrice,
    formatDateTime,
    formatDateTimeVacancyCreated,
    formatexperienceForVacancy,
    sendMetrick,
    formatEmpty
} from '@/composables/utils';
import { usePopupsStore } from "@/stores/popupsStore";
import Avatar from "@/components/ui/Avatar";
const { baseURL } = useFetch();

const route = useRoute();
const router = useRouter();
const emit = defineEmits(['update:modelValue', 'clickOnButton']);
const checked = ref(false)

const mobile = useMobile();

const authStore = useAuthStore();
const popupsStore = usePopupsStore()

const toVacancy = () => {
    emit('clickOnButton', props.vacancyId)

};

const handleResponseClick = () => {
    if (route.path === '/')
        sendMetrick('MAIN-PAGE-VACANCY-RESPONSE')
    else if (route.path === '/employee/vacancies') {
        sendMetrick('EMPLOYEE-PAGE-VACANCY-RESPONSE')
    }
    popupsStore.openResponsePopup(props.vacancyId, router)
}

const handleCardClick = () => {
    if (route.path === '/')
        sendMetrick('MAIN-PAGE-VACANCY-OPEN')
    router.push(`/employee/vacancies/${props.vacancyId}/`)
}

const props = defineProps({
    vacancyId: {
        type: Number,
        default: null,
    },
    tax: {
        type: String,
        default: null,
    },
    employerName: {
        type: String,
        default: null,
    },
    vacancyNameLength: {
        type: Number,
        default: 75
    },
    vacancyName: {
        type: String,
        default: 'Текст',
    },
    priceFrom: {
        type: Number,
        default: null,
    },
    priceTo: {
        type: Number,
        default: null,
    },
    currencyName: {
        type: String,
        default: 'рублей',
    },
    city: {
        type: String,
        default: null,
    },
    employerAvatar: {
        type: Number,
        default: null,
    },
    publicationTime: {
        type: String,
        default: 'Текст',
    },
    experience: {
        type: String,
        default: null,
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
});

// onMounted(() => { 
//   authStore.getMe()
// })


const vacancyNameCutted = computed(() => {

    if (props.vacancyName.length > props.vacancyNameLength) {
        return props.vacancyName.slice(0, props.vacancyNameLength) + '...';
    }

    return props.vacancyName;
});

</script>

<style scoped>
.header-price {
    @apply md:text-[20px] md:leading-[24px] text-[14px] leading-[17px];
}
</style>