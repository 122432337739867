<template>
  <DefaultLayout>
    <UIContainer>
      <UIPageHeader @search="handleSearch" :noSearchFirst="true">
        <template #default>
          Резюме
        </template>


        <template #append>
          <div v-if="resume" class="!w-[44px] !h-[44px] rounded-[45px] overflow-hidden bg-white">
            <img
              :src="resume.user_avatar_id ? `${baseURL}/files/${resume.user_avatar_id}/download` : '/images/UserEmpty.webp'"
              alt="Аватар" class="w-full h-full" />
          </div>

        </template>
      </UIPageHeader>
    </UIContainer>

    <div class="px-[16px] pb-[3.62rem] md:pb-[72px]">
      <!--        <div class="mb-[3.75rem] !bg-[#F8F8F8] md:mb-[80px]">-->
      <!--            <Header @toggleScroll="handleToggleScroll"></Header>-->
      <!--        </div>-->
      <div class="mb-[1.81rem]">

        <!--        <div v-if="!authStore.isAuthenticated" class="pt-[1.31rem]  flex flex-row justify-center">-->
        <!--          <ResumeNoLoginCard :experienceUnits=resume.experience :imageSrcId=resume.avatar-->
        <!--                             :publicationTime=resume.created_at :employeeFirstName=resume.first_name-->
        <!--                             :employeeLastName=resume.last_name :citizenship=resume.citizenship_name-->
        <!--                             :specialityName=resume.speciality_name :city=resume.city_of_residence-->
        <!--                             :readyToMove=resume.ready_to_move :age=resume.full_years :price=resume.salary_expectations-->
        <!--                             :currencyName=resume.currency_display_symbol-->
        <!--                             :resume="resume"-->
        <!--            ></ResumeNoLoginCard>-->
        <!--        </div>-->
        <div class="pt-[32px] flex flex-col items-center">


          <!--          <div class="flex flex-row justify-center md:h-[25px] md:mb-[20px]">-->


          <!--            <router-link to="/employer/resumes"-->
          <!--                         class="flex flex-row justify-start items-center mb-[1rem] leading-[1.5rem] pl-[1.5rem] md:pl-0 cursor-pointer w-full md:w-[993px]">-->
          <!--              <Icon name="chevron-left"></Icon>-->
          <!--              <div class="text-[0.87rem] md:text-[22px] text-[#000000]  mt-[0.1rem]">Вернуться к-->
          <!--                списку-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </div>-->
          <ResumeFullCard v-if="resume" :id=resumeId :imageSrcId=resume.user_avatar_id :bornDate="resume.born_date"
            :publicationTime=resume.created_at :employeeFirstName=resume.first_name :employeeLastName=resume.last_name
            :contacts="resume.contacts" :citizenship=resume.citizenship_name :specialityName=resume.speciality_name
            :employmentType=resume.employment_type :employmentShedule=resume.employment_schedule
            :city=resume.city_of_residence :readyToMove=resume.ready_to_move :experience=resume.work_experience
            :age=resume.full_years :price=resume.salary_expectations :currencyName=resume.currency_display_symbol
            :experienceUnits=resume.experience :about=resume.about_me :educationUnits=resume.education :resume="resume">
          </ResumeFullCard>
        </div>

        <div class="md:hidden mt-[40px] font-medium text-center underline text-blue-600"
          @click="$router.push('/employer/resumes')">
          Вернуться к поиску резюме
        </div>

      </div>


      <div class="hidden md:flex flex-row justify-center">
        <div class="md:w-[1032px] w-[400px]">
          <div
            class="ml-[1.5rem]  md:ml-[20px] md:mr-[20px]  md:mb-[10px] text-[2.25rem] leading-[2.75rem] text-[#0A5CD5]  cursor-pointer">
            Возможно, вам подойдут
          </div>
        </div>
      </div>

      <div class="w-full mb-[430px] md:mb-[335px] relative hidden md:block">

        <div class="absolute w-full">
          <CardsSlider class="pl-[10px] md:pl-0">
            <div v-for="(item, index) in resumes" :class="[
              index === 0 ? 'md:ml-[calc((100vw-1032px)/2.0)]' : '',
              index === resumes.length - 1 ? 'md:mr-[calc((100vw-1032px)/2.0)]' : '',
            ]">
              <router-link :to="{ path: `/employer/resumes/${item.id}` }">
                <WJResumeCard :resume="item"></WJResumeCard>
              </router-link>
            </div>
          </CardsSlider>
        </div>
      </div>

    </div>
    <!--    <Footer></Footer>-->
  </DefaultLayout>

</template>

<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import SimularResumeCard from './../components/cards/SimularResumeCard.vue'
import ResumeFullCard from './../components/cards/ResumeFullCard.vue'
import Header from './../components/ui/Header.vue'
import { ref, reactive, watch, onMounted, computed } from 'vue';
import CardsSlider from "@/components/ui/CardsSlider";
import Footer from './../components/ui/Footer.vue'
import ResumeNoLoginCard from './../components/cards/ResumeNoLoginCard.vue'
import Icon from './../components/ui/Icon.vue';
import { useFetch } from '@/composables/useFetch';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIPageHeader from "@/components/ui/UIPageHeader";
import WJResumeCard from "@/components/cards/WJResumeCard";
import UIContainer from "@/components/ui/UIContainer";
import { useRouter } from 'vue-router';
const router = useRouter()

const authStore = useAuthStore();

const route = useRoute();
const resumeId = ref(route.params.id);

const { baseURL, anonRequest, authRequest } = useFetch();

const handleSearch = (query) => {
  if (query) {
    router.push(`/employer/resumes?profession=${query}`)
  } else router.push(`/employer/resumes`)

}

const resume = ref(null);
const resumes = ref([]);
const loading = ref(false);
const error = ref(null);
const itemsPerPage = ref(5)
const page = ref(1)
const totalCount = ref(1)

const offset = computed(() => {
  return (page.value - 1) * itemsPerPage.value
})

const totalPages = computed(() => {
  return Math.floor(totalCount.value / itemsPerPage.value) + 1
})

onMounted(() => {
  fetchResumeData({});
  fetchResumesData()
});

const fetchResumeData = async () => {

  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку

  try {
    let response
    if (authStore.isAuthenticated) {
      response = await authRequest(`/resumes/${resumeId.value}/`, {
        method: 'GET',
      });
    } else {
      response = await anonRequest(`/resumes/${resumeId.value}/`, {
        method: 'GET',
      });
    }


    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      resume.value = response; // Предполагаем, что API возвращает массив данных
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const fetchResumesData = async (queryParams = {}) => {

  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку
  queryParams.limit = itemsPerPage.value
  queryParams.offset = offset.value

  try {
    // Создаем строку запроса вручную
    const queryArray = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      // Проверьте, является ли значение массивом
      if (Array.isArray(value)) {
        // Если массив, объедините значения через запятую
        queryArray.push(`${key}=${value.join(',')}`);
      } else {
        queryArray.push(`${key}=${value}`);
      }
    }
    let queryString = queryArray.join('&');
    queryString
    const url = `/resumes/${queryString ? '?' + queryString : ''} `;


    const response = await anonRequest(url, {
      method: 'GET',
    });


    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      resumes.value = response.results; // Предполагаем, что API возвращает массив данных
      totalCount.value = response.count
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

watch(
  () => route.params.id,
  (newId) => {
    resumeId.value = newId;
    fetchResumeData();
    fetchResumesData();
  }
);

const isChecked3 = ref(false)

const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
  { name: "tab1", label: "Новые вакансии" },
  { name: "tab2", label: "Все вакансии" },
];

const activeTab = ref(0);

</script>

<style></style>