<template>
  <div class="marquee ">
    <div class="marquee-text flex gap-[40px] py-[11px] px-[40px] ">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%; /* Ширина контейнера */
}

.marquee-text {
  animation: marquee 60s linear infinite;
  /*animation: marquee 6s linear infinite;*/
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
  /*101% {*/
  /*  transform: translateX(0%);*/
  /*}*/
}

/*@keyframes marquee {*/
/*  0% {*/
/*    transform: translateX(100%);*/
/*  }*/
/*  100% {*/
/*    transform: translateX(-100%);*/
/*  }*/
/*  101% {*/
/*    transform: translateX(100%);*/
/*  }*/
/*}*/
</style>