

// export const formatPrice = (price) => {
//     if (!price) {
//         return ''
//     }
//     else if (price < 1000) {
//         return price.toString();
//     } else {
//         const thousands = Math.floor(price / 1000); // Округление вниз
//         return `${thousands} тыс.`;
//     }
// }
export const formatPrice = (price, label = false) => {
    // Преобразуем число в строку
    if (!price)
        return null
    const priceString = String(price);
    
    // Используем регулярное выражение для добавления пробелов к тысячам
    const formattedPrice = priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return `${formattedPrice}${label ? ' ₽' : ''}`;
}

export const formatEmpty = (value, ifNoEmptyLabelBefore = null, ifNoEmptyLabelAfter = null, ifEmptyLabel= null, emptyValues=[null, undefined, '']) => {
    if (emptyValues.includes(value)) {
        return ifEmptyLabel
    } else {
        return `${ifNoEmptyLabelBefore} ${value} ${ifNoEmptyLabelAfter}`
    }
}



export const daysUntil = (dateString) => {
  const targetDate = new Date(dateString); // Преобразуем строку в объект Date
  const currentDate = new Date(); // Получаем текущую дату
  
  // Устанавливаем время текущей даты на полночь, чтобы сравнение было корректным
  currentDate.setHours(0, 0, 0, 0);
  
  // Вычисляем разницу в миллисекундах
  const timeDifference = targetDate - currentDate;
  
  // Преобразуем миллисекунды в дни
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  
  return daysDifference >= 0 ? daysDifference : 0; // Возвращаем число дней или 0, если дата уже прошла
}

export const formatDateTimeToDate = (dateTimeString) => {
    if (!dateTimeString || typeof dateTimeString !== 'string') {
        throw new Error("Invalid date time string");
    }

    const dateObj = new Date(dateTimeString);
    
    // Проверяем, корректно ли была создана дата
    if (isNaN(dateObj.getTime())) {
        throw new Error("Invalid date format");
    }

    // Форматируем дату в YYYY-MM-DD
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(dateObj.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

// const monthNamedeviation = (monthName) => {
//     if (monthName == 'январь') {
//         return 'января'
//     }
//     else if (monthName == 'февраль') {
//         return 'февраля'
//     }
//     else if (monthName == 'март') {
//         return 'марта'
//     }
//     else if (monthName == 'апрель') {
//         return 'апреля'
//     }
//     else if (monthName == 'май') {
//         return 'мая'
//     }
//     else if (monthName == 'июнь') {
//         return 'июня'
//     }
//     else if (monthName == 'июль') {
//         return 'июля'
//     }
//     else if (monthName == 'август') {
//         return 'августа'
//     }
//     else if (monthName == 'сентябрь') {
//         return 'сентября'
//     }
//     else if (monthName == 'октябрь') {
//         return 'октября'
//     }
//     else if (monthName == 'ноябрь') {
//         return 'ноября'
//     }
//     else if (monthName == 'декабрь') {
//         return 'декабря'
//     }
// }

export const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) {
        return '-'
    }
    const date = new Date(dateTimeString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const currentYear = now.getFullYear();
    const options = { hour: '2-digit', minute: '2-digit', hour12: false };

    let formattedTime = date.toLocaleTimeString('ru-RU', options);

    if (isToday) {
        return `Сегодня в ${formattedTime}`;
    } else if (date.getFullYear() === currentYear) {
        const day = date.getDate();
        const month = date.toLocaleString('ru-RU', { month: 'long' });
        return `${day} ${month} в ${formattedTime}`;
    } else {
        const day = date.getDate();
        const month = date.toLocaleString('ru-RU', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month} ${year} г. в ${formattedTime}`;
    }
}

export const formatDateTimeShort = (utcDateString) => {
    // Создаем объект Date из строки с датой и временем в формате UTC
    const date = new Date(utcDateString);

    // Получаем день, месяц и время
    const day = String(date.getUTCDate()).padStart(2, '0'); // День
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Месяц (нумерация с 0)
    const hours = String(date.getUTCHours()).padStart(2, '0'); // Часы
    const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Минуты

    // Формируем строку в формате "дд.мм в чч:мм"
    return `${day}.${month} в ${hours}:${minutes}`;
}

export const humanizeDate = (dateString) => {
    if (!dateString) {
        return '-';
    }

    const date = new Date(dateString);
    const now = new Date();

    // Проверяем, является ли дата сегодняшней
    const isToday = date.toDateString() === now.toDateString();
    const currentYear = now.getFullYear();

    // Массив названий месяцев в родительном падеже
    const monthsGenitive = [
        'января', 'февраля', 'марта', 'апреля',
        'мая', 'июня', 'июля', 'августа',
        'сентября', 'октября', 'ноября', 'декабря'
    ];

    if (isToday) {
        return 'Сегодня';
    } else if (date.getFullYear() === currentYear) {
        const day = date.getDate();
        const month = monthsGenitive[date.getMonth()];
        return `${day} ${month}`;
    } else {
        const day = date.getDate();
        const month = monthsGenitive[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year} г.`;
    }
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${toUpperCaseFirstLetter(date.toLocaleString('ru-RU', { month: 'long' }))} ${date.getFullYear()}`
    
}

export const formatDateMinimal = (dateString) => {
    const date = new Date(dateString);

    // Получаем день и месяц с добавлением ведущего нуля, если необходимо
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();

    // Форматируем дату в требуемом виде
    return `${day}.${month}.${year}`;
}

export const  toLowerCaseFirstLetter = (str) => {
    if (str.length === 0) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
}

export const  toUpperCaseFirstLetter = (str) => {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatDateTimeResumeCreated = (dateTimeString) => {
    return `${toLowerCaseFirstLetter(formatDateTime(dateTimeString))}`
}

export const formatDateTimeResumeUpdated = (dateTimeString) => {
    return `Изменено ${toLowerCaseFirstLetter(formatDateTime(dateTimeString))}`
}

export const formatDateTimeVacancyCreated = (dateTimeString) => {
    return `Размещено ${toLowerCaseFirstLetter(formatDateTime(dateTimeString))}`
}

export const formatAge = (ageNumber) => {
    if (ageNumber === null) {
        return 'Не указано'
    } else if (ageNumber === 0) {
        return 'Менее 1 года'
    } else if (['1', '21', '31', '41', '51', '61', '71', '81', '91'].includes(String(ageNumber))) {
        return `${ageNumber} год`
    } else if (['2', '3', '4', '22', '23', '24', '32', '33', '34', '42', '43','44', '52', '53', '54', '62', '63', '64', '72', '73', '74', '82', '83', '84', '92', '93', '94'].includes(String(ageNumber) )) {
        return `${ageNumber} года`
    } else {
        return `${ageNumber} лет`
    } 
    
}


export const formatPhone = (phone) => {
    return phone ? phone : 'Не указано'
}

export const formatEmail = (email) => {
    return email ? email : 'Не указано'
}

export const formatexperienceForVacancy = (experienceString) => {
    if (experienceString === 'no_experience') {
        return 'не требуется'
    }else if (experienceString === 'months_3_12') {
        return 'от 1 месяца до 1 года'
    } else if (experienceString === 'years_1_3') {
        return 'от 1 до 3 лет'
    }  else if (experienceString === 'years_3_6') {
        return 'от 3 до 6 лет'
    } else if (experienceString === 'years_more_6') {
        return 'более 6 лет'
    }
}

export const humanizeBanReason = (reasonCode) => {
    const translations = {
        'HARASSMENT': 'Домогательство',
        'HARASSMENT/THREATENING': 'Домогательство/угроза',
        'HATE': 'Ненависть',
        'HATE/THREATENING': 'Ненависть/угроза',
        'ILLICIT': 'Незаконный',
        'ILLICIT/VIOLENCE': 'Незаконный/насилие',
        'SELF-HARM': 'Самоповреждение',
        'SELF-HARM/INTENT': 'Самоповреждение/намерение',
        'SELF-HARM/INSTRUCTIONS': 'Самоповреждение/инструкции',
        'SEXUAL': 'Сексуальный',
        'SEXUAL/MINORS': 'Сексуальный/несовершеннолетние',
        'VIOLENCE': 'Насилие',
        'VIOLENCE/GRAPHIC': 'Насилие/графическое'
    };

    const translation = translations[reasonCode];

    return translation
}

export const formatexperienceForVacancyNew = (experienceString) => {
    if (experienceString === 'no_experience') {
        return 'не требуется'
    }else if (experienceString === 'months_3_12') {
        return '1 мес - 1 год'
    } else if (experienceString === 'years_1_3') {
        return '1-3 лет'
    }  else if (experienceString === 'years_3_6') {
        return '3-6 лет'
    } else if (experienceString === 'years_more_6') {
        return '> 6 лет'
    }
}

export const humanizeEmploymentType = (employmentTypeString) => {
    if (employmentTypeString === 'full_day') {
        return 'Полная занятость'
    } else if (employmentTypeString === 'not_full_day') {
        return 'Неполная занятость'
    }  else if (employmentTypeString === 'project') {
        return 'Проектная работа'
    } else if (employmentTypeString === 'internship') {
        return 'Стажировка'
    } else if (employmentTypeString === 'volunteering') {
        return 'Волонтерство'
    }
}

export const humanizeUserRole = (userRoleString) => {
    if (userRoleString === 'employee') {
        return 'Соискатель'
    } else if (userRoleString === 'employer') {
        return 'Работодатель'
    }
}

export const humanizeEmploymentShedule = (employmentSheduleString) => {
    if (employmentSheduleString === 'full_day') {
        return 'Полный день'
    } else if (employmentSheduleString === 'shift_work') {
        return 'Сменный график'
    }  else if (employmentSheduleString === 'remote') {
        return 'Удаленная работа'
    } else if (employmentSheduleString === 'flexible') {
        return 'Гибкий график'
    } 
}
export const humanizeEducationLevel = (education) => {
    if (education === 'empty') {
        return 'Не указано'
    } else if (education === 'no_education') {
        return 'Без образования'
    }  else if (education === 'secondary_incomplete') {
        return 'Не полное среднее'
    } else if (education === 'secondary') {
        return 'Среднее'
    } else if (education === 'higher') {
        return 'Высшее'
    } else if (education === 'unfinished_higher') {
        return 'Высшее неоконченное'
    } else if (['bachelor', 'master', 'candidate_of_sciences', 'doctor_of_sciences'].includes(education)) {
        return 'Высшее'
    }
}

export const getDateYear = (dateTimeString) => {
    if (dateTimeString === null) {
        return 'по н.в'
    }
    const date = new Date(dateTimeString);
    const year = date.getFullYear();

    return year
}

export const sendMetrick = (id) => {
    window.ym(99704199,'reachGoal', id)
    console.log('send_metrick!!!!')
}

export const transliterate = (text) => {
    const russianToEnglish = {
        А: 'A', а: 'a',
        Б: 'B', б: 'b',
        В: 'V', в: 'v',
        Г: 'G', г: 'g',
        Д: 'D', д: 'd',
        Е: 'E', е: 'e',
        Ё: 'Yo', ё: 'yo',
        Ж: 'Zh', ж: 'zh',
        З: 'Z', з: 'z',
        И: 'I', и: 'i',
        Й: 'Y', й: 'y',
        К: 'K', к: 'k',
        Л: 'L', л: 'l',
        М: 'M', м: 'm',
        Н: 'N', н: 'n',
        О: 'O', о: 'o',
        П: 'P', п: 'p',
        Р: 'R', р: 'r',
        С: 'S', с: 's',
        Т: 'T', т: 't',
        У: 'U', у: 'u',
        Ф: 'F', ф: 'f',
        Х: 'Kh', х: 'kh',
        ц: 'ts', Ц: 'Ts',
        ч: 'ch', Ч: 'Ch',
        ш: 'sh', Ш: 'Sh',
        щ: 'sch', Щ: 'Sch',
        ы: 'y', Ы: 'Y',
        э: 'e', Э: 'E',
        ю: 'yu', Ю: 'Yu',
        я: 'ya', Я: 'Ya',
        ' ': ' ',
        '.': '.',
        ',': ',',
        '—': '-',
        '?': '?',
        '!': '!',
        ь: '`',
        ъ: '`',
        Ъ: '`',
        Ь: '`'
        // Добавьте другие символы по желанию
    };

    return text.split('').map(char => russianToEnglish[char] || char).join('');
}
