<template>
    <div :class="eventClass">
        <div v-if="mobile.isMobile.value" class="flex flex-row justify-end w-full">
            <div v-if="status == 'MODERATION'" class="inline-flex gap-x-2 items-center justify-between mb-[16px]">
                <div class="w-3 h-3 bg-[#F0CE0E] rounded-full"></div>
                <div class="font-light text-[12px] leading-[14.68px]">На модерации</div>
            </div>
            <div v-else-if="status == 'BANNED'" class="inline-flex gap-x-2 items-center justify-between mb-[16px]">
                <div class="w-3 h-3 bg-[#FF3B30] rounded-full"></div>
                <div class="font-light text-[12px] leading-[14.68px]">Заблокированно</div>
            </div>
        </div>
        <img :src="props.imageSrc" class="w-[187px] h-[164px] rounded-[15px] object-cover">
        <div :class="contentClass">
            <div v-if="!mobile.isMobile.value" class="flex flex-row justify-end w-full">
                <div v-if="status == 'MODERATION'" class="inline-flex gap-x-2 items-center justify-between">
                    <div class="w-3 h-3 bg-[#F0CE0E] rounded-full"></div>
                    <div>На модерации</div>
                </div>
                <div v-else-if="status == 'BANNED'" class="inline-flex gap-x-2 items-center justify-between">
                    <div class="w-3 h-3 bg-[#FF3B30] rounded-full"></div>
                    <div>Заблокированно</div>
                </div>
            </div>
            <div
                class="font-medium md:text-[24px] md:leading-[29.4px] md:mb-[12px] mt-[1.5rem] mb-[0.75rem] text-[1.12rem] leading-[1.37rem]">
                {{ props.name }}</div>
            <div class="md:font-bold md:text-[16px] md:leading-[19.6px] mb-[12px] text-[1.12rem] leading-[1.37rem]">{{
                props.date }}</div>
            <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem] leading-[1.17rem]">{{ props.text }}</div>
        </div>
    </div>
</template>
<script setup>
import router from '@/router';
import { defineProps, computed } from 'vue';
import { useMobile } from "@/composables/useMobile";
const mobile = useMobile()


const eventClass = computed(() => {
    let standard = "flex md:flex-row flex-col p-[24px] md:items-center  bg-white md:gap-x-[24px] gap-x-[0.7rem] rounded-[15px] h-full hover:opacity-75 cursor-pointer "
    if (props.fullWigth)
        standard += 'w-full'
    else
        standard += 'md:w-[486px] w-[245px]'
    return standard
})

const contentClass = computed(() => {
    let standard = "flex flex-col items-start "
    if (props.fullWigth)
        standard += 'w-full'
    else
        standard += 'md:w-[277px] w-[197px]'
    return standard
})
const props = defineProps({
    name: {
        type: String,
        default: 'Текст',
    },
    date: {
        type: String,
        default: 'Текст',
    },
    text: {
        type: String,
        default: 'Текст',
    },
    imageSrc: {
        default: null,
    },
    fullWigth: {
        default: false
    },
    status: {
        default: null
    }
});
</script>

<style scopen></style>