<template>
  <div class="textarea-container">
    <textarea ref="element" :value="modelValue" @input="inputHandler" :placeholder="placeholder"
      :valid="(error === null)" :id="props.id" class="textarea" :class='textAreaClass'></textarea>
    <div v-if="maxCharacters" class="character-counter">
      {{ characterCount }}/{{ maxCharacters }}
    </div>
    <!-- <div v-if="error" class="error_message">{{error}}</div> -->
    
  </div>
  <div v-if="error || errorText" class="error_message">{{ errorText || error }}</div>

  <!-- {{ characterCount }} -->
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  maxCharacters: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  },
  textAreaClass: {
    type: String,
    default: ''
  },
  rows: {
    type: Number,
    default: 3
  },
  id: {
    type: String,
    default: ''
  },
  rules: {
    type: Array,
    default: () => []
  },
  errorText: {
    default: null
  },
})

const emit = defineEmits(['update:modelValue', 'valid'])
const element = ref(null)

onMounted(() => {
  validate(props.modelValue);
})

watch(() => props.modelValue, (value) => {
  // console.log('MODELVALUE', value)
  characterCount.value = value.length
  validate(value);
})

const autoResize = () => {
  element.value.style.height = 'auto'; // Сначала сбрасываем высоту
  element.value.style.height = element.value.scrollHeight + 'px'; // Устанавливаем новую высоту
  element.value.scrollTop = element.value.scrollHeight;
}

const characterCount = ref(0);
// const updateCharacterCount = () => {
//   if (!props.modelValue) 
//     characterCount.value = 0;
//   else
//     characterCount.value = modelValue.length;
// };


const inputHandler = (event) => {
  emit('update:modelValue', event.target.value)
  validate(event.target.value);
  autoResize()
}

const error = ref(null)


const validate = (val) => {
  let isValid = true;
  //
  for (let i = 0; i < props.rules.length; i++) {
    const res = props.rules[i](val)
    if (res !== null) {
      error.value = res
      isValid = false;
      break;
    } else {
      error.value = null
    }
  }

  emit('valid', isValid);
}

onMounted(() => {
  //
  validate(props.modelValue)
})
</script>

<style scoped>
@media (max-width: 768px) {
  .textarea {
    padding: 8px 16px;
    padding-bottom: 25px;
    font-size: 12px;
    line-height: 14.7px;
    font-weight: 300;
  }
}

textarea {

  width: 100%;
  resize: vertical;

  box-sizing: border-box;

  padding: 14px 26px;
  padding-bottom: 30px; /* Отступ снизу для счета символов */


  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;



}

.textarea-container {
  position: relative;
}


.textarea[valid="false"] {
  border: 1px solid #DB4545;
}

.textarea[valid="true"] {
  border: 1px solid #ECECEC
}



.error_message {


  font-style: normal;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 0px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #DB4545;
}

.character-counter {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.75rem;
  color: #7B7A7A;
  background-color: white;
  z-index: 10;
  /* margin-top: -25px; */
}
</style>