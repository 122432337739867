<template>
    <div>
      <button @click="toggleEmployeeActive">Toggle Advantages</button>
      <transition-group name="advanatge" tag="div">
        <div 
          v-for="(advantage, index) in advantages" 
          v-if="isEmployeeActive" 
          :key="advantage.id" 
          class="advanatge" 
          :class="'delay-' + index"
        >
          <div class="advanatge-circle"></div>
          {{ advantage.text }}
        </div>
      </transition-group>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      const isEmployeeActive = ref(false);
      const advantages = ref([
        { id: 1, text: 'Полностью бесплатно' },
        { id: 2, text: 'Легкая коммуникация' },
        { id: 3, text: 'Способ заявить о себе' },
      ]);
  
      const toggleEmployeeActive = () => {
        isEmployeeActive.value = !isEmployeeActive.value;
      };
  
      return {
        isEmployeeActive,
        advantages,
        toggleEmployeeActive,
      };
    },
  };
  </script>
  
  <style scoped>
  .advanatge {
    opacity: 0; /* Начальная прозрачность */
    transition: opacity 5s ease; /* Переход */
  }
  
  .delay-0 {
    transition-delay: 1500ms;
  }
  
  .delay-1 {
    transition-delay: 1000ms;
  }
  
  .delay-2 {
    transition-delay: 2000ms;
  }
  
  .advanatge-enter-active {
    transition: opacity 5s ease; /* Плавный вход */
  }
  
  .advanatge-leave-active {
    transition: none; /* Резкий выход */
  }
  
  .advanatge-enter, .advanatge-leave-to {
    opacity: 0; /* Начальная прозрачность для входа и конечная для выхода */
  }
  
  .advanatge-leave {
    opacity: 1; /* Конечная прозрачность при уходе */
  }
  
  .advanatge-circle {
    width: 10px;
    height: 10px;
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  </style>
  