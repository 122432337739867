<template>
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.37 0.58209C14.1355 0.472637 13.8645 0.472637 13.63 0.58209L0.504977 6.70709C0.188849 6.85462 -0.00944132 7.17583 0.00034649 7.52455C0.0101342 7.87327 0.22613 8.18286 0.550035 8.31242L13.675 13.5624C13.8836 13.6459 14.1164 13.6459 14.325 13.5624L24.5 9.4924V19.75C23.5335 19.75 22.75 20.5335 22.75 21.5V25H28V21.5C28 20.5335 27.2165 19.75 26.25 19.75V8.7924L27.45 8.31242C27.7739 8.18286 27.9899 7.87327 27.9997 7.52455C28.0094 7.17583 27.8112 6.85462 27.495 6.70709L14.37 0.58209ZM14 11.8076L3.07653 7.43821L14 2.34059L24.9235 7.43821L14 11.8076Z"
            fill="white" />
        <path
            d="M7.30723 12.8057C7.0796 12.7204 6.82672 12.7335 6.60921 12.8421C6.39169 12.9507 6.22915 13.1449 6.16056 13.3781L5.28556 16.3531C5.15822 16.786 5.38103 17.2448 5.80003 17.4124L13.675 20.5624C13.8836 20.6459 14.1164 20.6459 14.325 20.5624L22.2 17.4124C22.619 17.2448 22.8418 16.786 22.7144 16.3531L21.8394 13.3781C21.7708 13.1449 21.6083 12.9507 21.3908 12.8421C21.1733 12.7335 20.9204 12.7204 20.6928 12.8057L14 15.3155L7.30723 12.8057ZM7.18906 16.0832L7.57391 14.7747L13.6928 17.0693C13.8909 17.1436 14.1091 17.1436 14.3072 17.0693L20.4261 14.7747L20.8109 16.0832L14 18.8076L7.18906 16.0832Z"
            fill="white" />
    </svg>


</template>